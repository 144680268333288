import { Routes } from '@angular/router';
import { LoginComponent } from './component/auth/login/login.component';
import { PasswordComponent } from './component/auth/password/password.component';
import { RegisterComponent } from './component/auth/register/register.component';
import { BillingComponent } from './component/nav/billing/billing.component';
import { BrandingComponent } from './component/nav/dashboard/branding/branding.component';
import { DashboardComponent } from './component/nav/dashboard/dashboard.component';
import { IamComponent } from './component/nav/dashboard/iam/iam.component';
import { LicenseComponent } from './component/nav/dashboard/license/license.component';
import { EnvironmentComponent } from './component/nav/environment/environment.component';
import { NavComponent } from './component/nav/nav.component';
import { PricingComponent } from './component/utils/dialog/pricing/pricing.component';
import { authGuard } from './guard/auth.guard';

export const routes: Routes = [
    { path: 'pricing', component: PricingComponent },
    { path: '', redirectTo: "auth/login", pathMatch: 'full' },
    {
        path: 'auth',
        children: [
            { path: '', redirectTo: 'register', pathMatch: 'full' },
            { path: 'register', component: RegisterComponent, },
            { path: 'login', component: LoginComponent, },
            { path: 'password', component: PasswordComponent, }
        ],
    },
    {
        path: 'dashboard',
        component: NavComponent,
        canActivate: [authGuard],
        children: [
            {
                path: '',
                component: DashboardComponent,
                pathMatch: 'full',
            },
            {
                path: '**',
                redirectTo: `/dashboard`,
                pathMatch: 'full',
            },
            {
                path: 'user',
                component: IamComponent,
                pathMatch: 'full',
            },
            {
                path: 'branding',
                component: BrandingComponent,
                pathMatch: 'full',
            },
            {
                path: 'license',
                component: LicenseComponent,
                pathMatch: 'full',
            },
        ]
        // canActivate: [authGuard],
    },
    {
        path: 'billing',
        component: NavComponent,
        canActivate: [authGuard],
        children: [
            {
                path: '',
                component: BillingComponent,
                pathMatch: 'full',
            },
            {
                path: '**',
                redirectTo: `/billing`,
                pathMatch: 'full',
            },
        ],
    },
    {
        path: 'environments',
        component: NavComponent,
        canActivate: [authGuard],
        children: [
            {
                path: '',
                component: EnvironmentComponent,
                pathMatch: 'full',
            },
            {
                path: '**',
                redirectTo: `/environments`,
                pathMatch: 'full',
            },
        ],
    },
];
