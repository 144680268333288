import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { AuthAction } from '../../../action/auth.action';
import { Status } from '../../../model/enum';
import { AuthState } from '../../../state/auth.state';
import { MaterialModule } from '../../utils/material.module';

@Component({
  selector: 'app-password',
  standalone: true,
  imports: [
    RouterLink,
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
  ],
  templateUrl: './password.component.html',
  styleUrl: './password.component.scss'
})
export class PasswordComponent implements OnInit, OnDestroy {

  private token: string = "";

  mode = 'set';

  Status = Status;
  status?: Status;

  passwordForgotFormGroup: FormGroup = new FormGroup({
    email: new FormControl<string>('', [Validators.email, Validators.required, Validators.max(255),]),
  });

  passwordResetFormGroup: FormGroup = new FormGroup({
    password: new FormControl<string>('', [Validators.required, Validators.maxLength(25), Validators.pattern(/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[@*#!?$%^&+=()-])(?=\S+$).{8,}$/)
    ]),
    confirm: new FormControl<string>('', [Validators.required, Validators.maxLength(25)]),
  });

  @Select(AuthState.getStatus) private status$!: Observable<Status>;
  private destroy: Subject<boolean> = new Subject();

  constructor(
    private store: Store,
    private route: ActivatedRoute,
  ) { }

  get passwordsMatch(): boolean {
    const password = this.passwordResetFormGroup.get('password');
    const confirmPassword = this.passwordResetFormGroup.get('confirm');

    if (password?.value === '' || confirmPassword?.value === '') {
      return true;
    }

    const valid: boolean = (password?.valid && confirmPassword?.valid) ?? false;

    return valid && password?.value === confirmPassword?.value;
  }

  get canSubmit(): boolean {
    return this.passwordResetFormGroup.valid && this.passwordsMatch;
  }


  ngOnInit(): void {
    this.status$.pipe(takeUntil(this.destroy)).subscribe(status => {
      this.status = status;
    });

    this.route.queryParams.subscribe(params => {
      if (params['mode']) {
        this.mode = params['mode'];
      }

      this.token = params['token'];

      if (!this.token) {
        this.store.dispatch(new AuthAction.SetStatus(Status.error));
      } else {
        this.store.dispatch(new AuthAction.VerifyPasswordToken(this.token));
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }

  resetPassword() {

    const password = this.passwordResetFormGroup.get('password')?.value;
    const confirmPassword = this.passwordResetFormGroup.get('confirm')?.value;

    if (this.passwordResetFormGroup.valid && password === confirmPassword) {
      this.store.dispatch(new AuthAction.SetPassword(this.token, password));
    }
  }

  forgotPassword() {
    if (this.passwordForgotFormGroup.valid) {
      this.store.dispatch(
        new AuthAction.ForgotPassword(this.passwordForgotFormGroup.value['email']),
      );
    }
  }
}
