import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';

import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsModule } from '@ngxs/store';
import { routes } from './app.routes';
import { AuthState } from './state/auth.state';

import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { authInterceptor } from './interceptor/auth.interceptor';
import { AdminState } from './state/admin.state';
import { BillingState } from './state/billing.state';
import { EnvironmentState } from './state/environment.state';
import { LicenseState } from './state/license.state';
import { OrgState } from './state/org.state';
import { RequestState } from './state/request.state';
import { ThemeState } from './state/theme.state';
import { UserState } from './state/user.state';

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(
      withInterceptors([authInterceptor]),
    ),
    importProvidersFrom(
      NgxsModule.forRoot(
        [AuthState, ThemeState, LicenseState, UserState, RequestState, AdminState, BillingState, OrgState, EnvironmentState],
        {
          selectorOptions: {
            suppressErrors: false,
            injectContainerState: false,
          }
        }
      ),
      BrowserModule,
      BrowserAnimationsModule,
      NgxsLoggerPluginModule.forRoot(),
      NgxsReduxDevtoolsPluginModule.forRoot()
    ),
    provideRouter(routes),
  ]
};

