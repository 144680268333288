import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { catchError, tap, throwError } from "rxjs";
import { AuthAction } from "../action/auth.action";
import { AuthStateModel } from "../model/auth.model";
import { Status } from "../model/enum";
import { AlertService } from "../service/alert.service";
import { AuthService } from "../service/auth.service";

@State<AuthStateModel>({
    name: 'auth',
    defaults: {
        id: '',
        name: '',
        email: '',
        role: '',
        status: undefined,
        entity: '',
        organizationName: '',
        is_su: false,
    },
})
@Injectable()
export class AuthState {

    constructor(
        private authService: AuthService,
        private alertService: AlertService,
    ) { }

    @Selector()
    static getId(state: AuthStateModel): string {
        return state.id;
    }

    @Selector()
    static getStatus(state: AuthStateModel): Status | undefined {
        return state.status;
    }

    @Selector()
    static getName(state: AuthStateModel): string {
        return state.name;
    }

    @Selector()
    static getEmail(state: AuthStateModel): string {
        return state.email;
    }

    @Selector()
    static getEntity(state: AuthStateModel): string {
        return state.entity ?? "";
    }

    @Selector()
    static isSuperuser(state: AuthStateModel): boolean {
        return state.is_su;
    }

    @Action(AuthAction.Get)
    getAuthState(ctx: StateContext<AuthStateModel>, action: AuthAction.Get) {

    }

    @Action(AuthAction.Set)
    setAuthState(ctx: StateContext<AuthStateModel>, action: AuthAction.Set) {
        ctx.patchState({
            id: action.payload.id,
            email: action.payload.email,
            name: action.payload.name,
            role: action.payload.role,
            entity: action.payload.entity,
            organizationName: action.payload.organization_name,
            is_su: action.payload.is_su,
        })
    }

    @Action(AuthAction.SetStatus)
    setStatus(ctx: StateContext<AuthStateModel>, action: AuthAction.SetStatus) {
        ctx.patchState({
            status: action.status,
        });
    }

    @Action(AuthAction.Register)
    register(ctx: StateContext<AuthStateModel>, action: AuthAction.Register) {
        ctx.patchState({
            status: Status.connecting,
        });

        return this.authService.register(action.payload).pipe(
            tap(_ => {
                ctx.patchState({
                    status: Status.success,
                })
            }),
            catchError(error => {
                if (error.status === 409) {
                    ctx.patchState({
                        status: Status.conflict,
                    })
                } else {
                    ctx.patchState({
                        status: Status.error,
                    })
                }

                throw throwError(() => error);
            })
        );
    }

    @Action(AuthAction.VerifyPasswordToken)
    verifyPasswordToken(ctx: StateContext<AuthStateModel>, action: AuthAction.VerifyPasswordToken) {
        return this.authService.verifyPasswordToken(action.token).pipe(
            tap(_ => {
                console.log("password token is valid");
            }),
            catchError(error => {
                ctx.patchState({
                    status: Status.error,
                })

                throw throwError(() => error);
            }),
        );
    }

    @Action(AuthAction.SetPassword)
    setPassword(ctx: StateContext<AuthStateModel>, action: AuthAction.SetPassword) {
        ctx.patchState({
            status: Status.connecting,
        });

        return this.authService.setPassword(action.token, action.password).pipe(
            tap(_ => {
                ctx.patchState({
                    status: Status.success,
                })
            }),
            catchError(error => {
                ctx.patchState({
                    status: Status.error,
                })

                throw throwError(() => error);
            }),
        );
    }

    @Action(AuthAction.ForgotPassword)
    forgotPassword(ctx: StateContext<AuthStateModel>, action: AuthAction.ForgotPassword) {
        ctx.patchState({
            status: Status.connecting,
        });

        return this.authService.forgotPassword(action.email).pipe(
            tap(_ => {
                ctx.patchState({
                    status: Status.success,
                })
            }),
            catchError(error => {
                ctx.patchState({
                    status: Status.idle,
                })
                this.alertService.error("Something went wrong. Please try again.");
                throw throwError(() => error);
            }),
        );
    }
}