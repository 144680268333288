import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyDialog as MatDialog, MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { BillingAction } from '../../../../action/billing.action';
import { PaymentMethod } from '../../../../model/billing/billing.model';
import { Status } from '../../../../model/enum';
import { License } from '../../../../model/license.model';
import { AuthService } from '../../../../service/auth.service';
import { BillingState } from '../../../../state/billing.state';
import { LicenseState } from '../../../../state/license.state';
import { DialogComponent, RequestDialogComponent } from '../../../utils/dialog/dialog.component';

@Component({
  selector: 'app-settings',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatDialogModule,
  ],
  templateUrl: './settings.component.html',
  styleUrl: './settings.component.scss'
})
export class SettingsComponent implements OnInit, OnDestroy {

  private license?: License;

  private destroy$: Subject<boolean> = new Subject();

  @Select(BillingState.getPaymentMethods) private paymentMethods$!: Observable<PaymentMethod[]>;
  @Select(LicenseState.getActiveLicense) private activeLicense$!: Observable<License>;
  @Select(BillingState.getStatus) private status$!: Observable<Status>;

  paymentMethods: PaymentMethod[] = [];
  paymentMethodsPaginated: PaymentMethod[] = [];

  status: Status = Status.idle;

  Status = Status;

  constructor(
    private store: Store,
    private authService: AuthService,
    private dialog: MatDialog,
  ) { }

  get licenseStatus() {
    return this.license?.status;
  }

  get isCancelled() {
    return this.license?.cancelled ?? false;
  }

  get endDate() {
    return this.license?.end_date;
  }

  ngOnInit(): void {

    this.status$.pipe(takeUntil(this.destroy$)).subscribe(
      response => {
        if (response) {
          this.status = response;
        }
      },
    );

    this.activeLicense$.pipe(takeUntil(this.destroy$)).subscribe(
      response => {
        if (response) {
          this.license = response;
          this.getPaymentMethods()
        }
      }
    );

    this.paymentMethods$.pipe(takeUntil(this.destroy$)).subscribe(
      response => {
        if (response) {
          this.paymentMethods = response;

          if (this.paymentMethods.length > 5) {
            this.paymentMethodsPaginated = this.paymentMethods.slice(0, 5);
          } else {
            this.paymentMethodsPaginated = this.paymentMethods;
          }
        }
      },
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  getPaymentMethods() {
    this.store.dispatch(new BillingAction.GetPaymenMethods(this.license?.client_id ?? '', this.authService.state.id));
  }

  paginate(event: PageEvent) {
    this.paymentMethodsPaginated = this.paymentMethods.slice(
      event.pageIndex * event.pageSize,
      (event.pageIndex * event.pageSize) + event.pageSize,
    );
  }

  cancelSubscription() {
    const dialog = this.dialog.open(
      DialogComponent,
      {
        maxWidth: 650,
        minWidth: 400,
        data: {
          title: 'Confirm Cancellation',
          message: "You are about to cancel your license & subscription. This will also cancel any active deployments you have and delete the deployment environments.\n\nPlease make sure you have backed up the necessary data as this action is destructive and cannot be undone.",
          actionLabel: 'I understand, cancel my license',
          isDestructive: true,
        }
      },
    );

    dialog.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(
      response => {
        if (response === true) {
          this.store.dispatch(new BillingAction.CancelSubscription(this.license?.client_id ?? ''));
        }
      },
    );
  }

  contactUs() {
    this.dialog.open(RequestDialogComponent, {
      minWidth: 450,
      maxWidth: 650,
      data: {
        mode: 'create',
        type: 'support',
        id: '',
        description: `Please describe your problem in detail -\n\n\n\n\n\nThese details help us resolve your request faster, please do not delete the below section. \n\nLicense- '${this.license?.client_id}'`,
      },
    });
  }

  restoreSubscription() {
    this.store.dispatch(new BillingAction.RestoreSubscription(this.license?.client_id ?? ''));
  }
}
