<section class="flex flex-col">
    <div class="ring-1 ring-borderColor rounded px-5 py-3">
        <h6 class="text-base mb-2">Add User</h6>
        <p class="text-sm mb-4">Use the dropdown below to give users access to your envrionment.</p>
        <form [formGroup]="addUserFormGroup" (ngSubmit)="addUser()">
            <div class="flex items-center">
                <ng-select formControlName="id" class="mr-3 min-w-60" placeholder="Select user">
                    @for (orgUser of filteredUsers; track $index) {
                    <ng-option [value]="orgUser.id">{{orgUser.name}}</ng-option>
                    }
                </ng-select>
                <button type="submit" class="btn btn-sm btn-primary px-3">Add</button>
            </div>
        </form>
    </div>
    <table mat-table [dataSource]="userDataSource"
        class="table-auto w-full rounded-none rounded-b border border-borderColor">

        <!-- User Name -->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let row">
                <span class="badge">{{row.name | titlecase }}</span>
            </td>
        </ng-container>

        <!-- User Email -->
        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef>Email</th>
            <td mat-cell *matCellDef="let row">
                <a [href]="'mailto:' + row.email" class="message-sm text-btnPrimary">{{row.email}}</a>
            </td>
        </ng-container>

        <!-- Actions -->
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let row; let i=index">
                <mat-icon class="cursor-pointer text-danger" (click)="removeUser(row.id)"
                    matTooltip="Remove User">delete</mat-icon>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" [attr.colspan]="displayedColumns.length">
                <p class="p-3">No active users found for the organization</p>
            </td>
        </tr>
    </table>

    <mat-paginator class="p-4 text-base" [pageSize]="5" aria-label="Select page of users"></mat-paginator>
</section>