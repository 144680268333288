import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { patch, updateItem } from "@ngxs/store/operators";
import { catchError, tap } from "rxjs";
import { LicenseAction } from "../action/license.action";
import { Status } from "../model/enum";
import { LicenseStateModel } from "../model/license.model";
import { AlertService } from "../service/alert.service";
import { LicenseService } from "../service/license.service";

@State<LicenseStateModel>({
    name: 'license',
    defaults: {
        activeLicenseIndex: -1,
        licenses: [],
        status: Status.idle,
    },
})
@Injectable()
export class LicenseState {

    constructor(
        private licenseService: LicenseService,
        private alertService: AlertService,
    ) { }

    @Selector()
    static getActiveLicense(state: LicenseStateModel) {

        if (state.activeLicenseIndex == -1) {
            return undefined;
        }

        return state.licenses[state.activeLicenseIndex];
    }

    @Selector()
    static getAllLicenses(state: LicenseStateModel) {
        return state.licenses;
    }

    @Action(LicenseAction.GetLicenses)
    getAllLicenses(ctx: StateContext<LicenseStateModel>, action: LicenseAction.GetLicenses) {
        return this.licenseService.getAllLicenses().pipe(
            tap(response => {
                ctx.setState({
                    licenses: response,
                    status: Status.active,
                    activeLicenseIndex: -1,
                });
            }),
            catchError(error => {
                ctx.patchState({
                    status: Status.error,
                    licenses: [],
                });

                return error
            }),
        );
    }

    @Action(LicenseAction.GetLicenseDetails)
    getLicenseDetails(ctx: StateContext<LicenseStateModel>, action: LicenseAction.GetLicenseDetails) {

        const index = ctx.getState().licenses.findIndex(l => l.client_id === action.clientId);

        ctx.patchState({
            activeLicenseIndex: index ?? -1,
        });
    }

    @Action(LicenseAction.ResetActiveLicenseIndex)
    resetActiveLicense(ctx: StateContext<LicenseStateModel>, action: LicenseAction.ResetActiveLicenseIndex) {
        ctx.patchState({
            activeLicenseIndex: -1,
        });
    }

    @Action(LicenseAction.ActivateLicense)
    activateLicense(ctx: StateContext<LicenseStateModel>, action: LicenseAction.ActivateLicense) {
        const state = ctx.getState();

        const license = state.licenses.find(l => l.client_id == action.clientId);

        if (license?.status === 'pending') {
            return this.licenseService.activateCommnunityLicense().pipe(
                tap(response => {
                    ctx.setState(
                        patch({
                            licenses: updateItem(
                                l => l.client_id === license.client_id,
                                patch({
                                    status: 'active',
                                    clientSecret: response.client_secret,
                                }),
                            ),
                        }),
                    );
                }),
            );
        } else {

            this.alertService.error("License already active");

            return;
        }
    }

    @Action(LicenseAction.GenerateSecret)
    generateSecret(ctx: StateContext<LicenseStateModel>, action: LicenseAction.GenerateSecret) {
        const state = ctx.getState();

        const license = state.licenses.find(l => l.client_id == action.clientId);

        if (license?.status === 'active') {
            return this.licenseService.generateSecret().pipe(
                tap(response => {
                    ctx.setState(
                        patch({
                            licenses: updateItem(
                                l => l.client_id === license.client_id,
                                patch({
                                    clientSecret: response.client_secret,
                                }),
                            ),
                        })
                    );
                }),
            );
        } else {
            this.alertService.error("Secrets can only be re-generated for active licenses");
            return;
        }
    }

    @Action(LicenseAction.UpdateRedirect)
    updateRedirect(ctx: StateContext<LicenseStateModel>, action: LicenseAction.UpdateRedirect) {
        this.licenseService.updateRedirect(action.clientId, action.redirectUri).subscribe({
            next: resp => {
                this.alertService.success('Success');
            }, error: err => {
                this.alertService.error("Failed to update Redirect URI");
            }
        })
    }

    @Action(LicenseAction.SetDefaultLicense)
    setDefaultLicense(ctx: StateContext<LicenseStateModel>, action: LicenseAction.SetDefaultLicense) {

        const state = ctx.getState();
        if (state.licenses.length === 0) return;

        if (state.activeLicenseIndex === -1) {
            ctx.patchState({
                activeLicenseIndex: action.index,
            });
        }
    }

    @Action(LicenseAction.SetActiveLicense)
    setActiveLicense(ctx: StateContext<LicenseStateModel>, action: LicenseAction.SetActiveLicense) {

        const state = ctx.getState();
        const index = state.licenses.findIndex(l => l.client_id === action.clientId);

        ctx.patchState({
            activeLicenseIndex: index,
        })
    }
}