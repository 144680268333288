@if (status === Status.loading) {
<div class="flex items-center">
    <mat-spinner [diameter]="15" class="mr-2"></mat-spinner>
    <p class="text-sm">Loading...</p>
</div>
} @else {
<section class="flex flex-col mb-5">
    <p class="text-sm mb-4">
        You can add up to 5 payment methods, but you need to have at least 1 active payment method at all times.
    </p>
    @if(status === Status.active) {
    <!-- <div class="mb-6">
        <button mat-flat-button class="btn btn-sm btn-primary px-3 flex items-center">
            <mat-icon class="mr-1">add</mat-icon>
            <span>Add New</span>
        </button>
    </div> -->
    <div class="flex flex-col gap-2">
        @for (method of paymentMethodsPaginated; track $index) {
        <div class="grid grid-cols-1 md:grid-cols-6 divide-y md:divide-y-0 md:divide-x ring-1 ring-borderColor rounded">
            <div class="flex items-center px-5 py-2 col-span-2">
                <mat-icon class="mr-6">credit_card</mat-icon>
                <div class="flex flex-col">
                    <label for="methodId">Card Number</label>
                    <h6 id="methodId" class="text-lg font-medium">
                        <span>&bull;&bull;&bull;&bull;</span>
                        <span>{{method.card?.last4}}</span>
                    </h6>
                </div>
            </div>
            <div class="flex flex-col px-5 py-2 col-span-4">
                <label for="methodValidity">Valid Till</label>
                <h6 id="methodValidity" class="text-lg font-medium">{{method.card?.exp_month}}/{{method.card?.exp_year}}
                </h6>
            </div>
            <!-- <div class="flex flex-col px-5 py-2">
                            <label for="isDefault">Default</label>
                            <mat-icon id="isDefault" class="text-bgSuccess">check_circle</mat-icon>
                        </div> -->
            <!-- <div class="flex justify-end col-span-4 px-5 py-2">
                    <div class="flex items-center">
                        <button type="button"
                            class="btn btn-secondary text-btnDestructive border border-borderColor px-3">Delete</button>
                    </div>
                </div> -->
        </div>
        } @empty {
        <div class="ring-1 ring-borderColor rounded p-3 flex items-center">
            <mat-icon class="mr-3 text-buttonDangerColor">error</mat-icon>
            <p class="text-sm">No registered payment methods found for user</p>
        </div>
        }
        <mat-paginator (page)="paginate($event)" class="p-4 text-base" [length]="paymentMethods.length" [pageSize]="5"
            aria-label="Select page of payment methods"></mat-paginator>
    </div>
    } @else if (status === Status.error) {
    <div class="ring-1 ring-borderColor rounded p-3 flex items-center">
        <mat-icon class="mr-2 text-buttonDangerColor">error</mat-icon>
        <p class="text-sm">Unable to fetch payment methods, <button (click)="getPaymentMethods()"
                class="btn btn-sm btn-secondary text-btnPrimary px-0 bg-bgDefault">click here</button> to try again.</p>
    </div>
    }
</section>

<mat-divider></mat-divider>

<!-- Cancellation Section -->
@if(licenseStatus === 'active' && !isCancelled) {
<section class="flex flex-col my-8">
    <h6 class="text-base font-medium mb-2">Actions</h6>
    <p class="text-sm mb-4">
        If you no longer wish to continue using the platform, you can cancel your subscription using the option below.
    </p>
    <div class="ring-1 ring-borderColor rounded px-6 py-3 mb-5 flex items-center">
        <mat-icon class="text-bgWarning mr-3">warning</mat-icon>
        <p class="text-sm font-medium">Cancelling your subscription will also remove any deployed application or
            active environments that you might
            have. Please proceed with caution.</p>
    </div>
    <div class="flex">
        <button (click)="cancelSubscription()" type="button"
            class="btn btn-secondary text-btnDestructive px-3 border border-btnDestructive">Cancel
            Subscription</button>
    </div>
</section>
} @else if(isCancelled) {
<div class="ring-1 ring-borderColor rounded px-5 py-3 flex items-center">
    <mat-icon class="mr-5 text-bgWarning min-w-6">warning</mat-icon>
    <div class="flex flex-col">
        <h6 class="text-base mb-2">Scheduled For Cancellation</h6>
        <p class="message-sm mb-4">
            This subscription has been scheduled for cancellation and will be cancelled permanently by <b
                class="font-medium">{{this.endDate| date:'medium'}}</b>. You can restore this subscription anytime before that by using the button below. Please note, restoring your subscription will also resume your billing as per your billing cycle.
        </p>
        <div class="flex">
            <button (click)="restoreSubscription()" class="btn btn-secondary text-btnPrimary border border-btnPrimary px-3">Restore Subscription</button>
        </div>
    </div>
</div>
}
}

<!-- Contact Us Section -->
<section class="flex flex-col my-8">
    <h6 class="text-base font-medium mb-2">Get in touch</h6>
    <p class="text-sm mb-4">
        Have any other query regarding the platform? Use the button below to get in touch with us
    </p>
    <div class="flex">
        <button (click)="contactUs()" type="button"
            class="btn btn-secondary text-btnPrimary border border-btnPrimary px-3 mr-3">Contact
            Us</button>
    </div>
</section>