import { BreakpointObserver } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { Store } from '@ngxs/store';
import { Subject, takeUntil } from 'rxjs';
import { HeaderComponent } from '../../header/header.component';
import { MaterialModule } from '../../utils/material.module';
import { SwitcherComponent } from '../../utils/switcher/switcher.component';
import { OverviewComponent } from './overview/overview.component';
import { SettingsComponent } from './settings/settings.component';

@Component({
  selector: 'app-billing',
  standalone: true,
  imports: [
    RouterModule,
    CommonModule,
    MaterialModule,
    HeaderComponent,
    OverviewComponent,
    SettingsComponent,
    SwitcherComponent,
  ],
  templateUrl: './billing.component.html',
  styleUrl: './billing.component.scss'
})
export class BillingComponent implements OnInit, OnDestroy {

  private destroy$: Subject<boolean> = new Subject();

  // Whether to show or hide the secondary sidenav
  isSidenavVisible: boolean = true;

  activeMenuIndex: number = 0;
  pageTitle: string = 'Billing Overview';

  constructor(
    private observer: BreakpointObserver,
    private route: ActivatedRoute,
    private store: Store,
  ) { }

  ngOnInit(): void {

    this.observer.observe(['(max-width: 850px)']).subscribe((state) => {
      if (state.matches) {
        this.isSidenavVisible = false;
      } else {
        this.isSidenavVisible = true;
      }
    });

    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe(params => {
      const section = (params['section'] ?? 'overview').toLowerCase();

      switch (section) {
        case 'overview':
          this.pageTitle = 'Billing Overview';
          this.activeMenuIndex = 0;
          break;
        case 'settings':
          this.pageTitle = 'Settings';
          this.activeMenuIndex = 1;
          break;
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
