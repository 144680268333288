<div class="flex w-100 h-100 overflow-hidden">
    <div class="flex items-left welcome-wrapper bg-login p-2">
        <img class="logo-mini" src="./assets/logo-mini.png">
        <div class="my-10"></div>
        <ul class="p-0 flex flex-col justify-content-between flex-1 mb-5 section-bullets">
            <li class="flex flex-col mb-10">
                <div class="flex items-start">
                    <div class="bullet mr-2"></div>
                    <div class="flex flex-col">
                        <h6 class="title">PIES AI – AI Assistant</h6>
                        <p class="message">
                            Our platform now features a conversational AI assistant. This tool is not only capable
                            of engaging in dialogue but can also assist in developing everything from new features
                            to complete applications.
                        </p>
                    </div>
                </div>
            </li>
            <li class="flex flex-col mb-10">
                <div class="flex items-start">
                    <div class="bullet mr-2"></div>
                    <div class="flex flex-col">
                        <h6 class="title">Code Export</h6>
                        <p class="message">
                            We understand the importance of intellectual property protection. Our new code export
                            feature allows you to safeguard your IP by exporting fully functional code in your
                            preferred programming language.
                        </p>
                    </div>
                </div>
            </li>
            <li class="flex flex-col mb-10">
                <div class="flex items-start">
                    <div class="bullet mr-2"></div>
                    <div class="flex flex-col">
                        <h6 class="title">Custom Widgets</h6>
                        <p class="message">
                            Enhance your toolkit by creating, sharing, and even monetizing your own widgets. This
                            feature offers unprecedented flexibility and creative freedom within our ecosystem.
                        </p>
                    </div>
                </div>
            </li>
        </ul>
        <div class="flex flex-col mb-3 section-banner justify-end">
            <div class="flex items-center mb-3">
                <h2 class="text-2xl text-light typewriter mr-1"></h2>
                <h2 class="text-2xl text-light">Development Platform</h2>
            </div>
            <p class="message-lg">No-code for speed, low-code for flexibility and code export for independence</p>
        </div>
        <img class="logo-floating mr-2" src="./assets/logo-mini.png" alt="Application Background Logo" />
    </div>
    <div class="login-wrapper flex flex-col justify-center items-center p-3">
        <div class="login-card flex flex-col">
            @if (this.mode === 'forgot') {
            @if(this.status !== Status.success) {
            <div class="p-16">
                <h4 class="text-2xl font-medium mb-0">Forgot your password</h4>
                <div class="flex items-center my-6">
                    <div class="separator mr-2"></div>
                    <div class="separator separator-sm mr-2"></div>
                </div>
                <p class="text-sm mb-3">Enter your registered email address below to receive instructions to set your
                    password</p>
                <form [formGroup]="passwordForgotFormGroup" (ngSubmit)="forgotPassword()"
                    class="space-y-4 flex flex-col">
                    <div>
                        <label for="email" class="block mb-2 text-xs font-medium text-fontPrimary">
                            Email
                            <span class="text-btnDestructive">*</span>
                        </label>
                        <input maxlength="255" formControlName="email" autocomplete="off" id="email" type="email"
                            class="input" required>
                        @if (passwordForgotFormGroup.controls['email'].touched
                        &&passwordForgotFormGroup.controls['email'].errors?.['required']){
                        <span class="text-xs font-medium text-red-500">This field is required</span>
                        } @else if(passwordForgotFormGroup.controls['email'].touched &&
                        passwordForgotFormGroup.controls['email'].errors?.['email']){
                        <span class="text-xs font-medium text-red-500">Email is invalid</span>
                        }
                    </div>
                    <button [disabled]="!passwordForgotFormGroup.valid" type="submit"
                        class="btn btn-primary w-full flex justify-center">
                        @if (status !== Status.connecting) {
                        Submit
                        } @else {
                        <div role="status">
                            <mat-spinner mode="indeterminate" [diameter]="25"></mat-spinner>
                            <span class="sr-only">Please wait...</span>
                        </div>
                        }
                    </button>
                    <p class="text-start text-sm mt-4">
                        Already set your password? <a [routerLink]="['/auth/login']"
                            class="no-underline text-buttonAlternate">Log In</a>
                    </p>
                </form>
            </div>
            } @else if (this.status === Status.success) {
            <div class="p-10 flex flex-col">
                <div class="flex items-center mb-4">
                    <mat-icon class="text-bgSuccess mr-2">check_circle</mat-icon>
                    <h4 class="text-2xl font-medium">Success</h4>
                </div>
                <p class="text-base">
                    If the entered email is registered with us, you should recieve instructions to set your password
                    shortly.
                </p>
                <br /><br />
                <p class="text-base">
                    Already set your password? <a [routerLink]="['/auth/login']"
                        class="no-underline text-buttonAlternate">Log In</a>
                </p>
            </div>
            }
            } @else {
            @if (this.status !== Status.success && this.status !== Status.error) {
            <div class="p-16">
                <h4 class="text-2xl font-medium mb-0">Set your password</h4>
                <div class="flex items-center my-6">
                    <div class="separator mr-2"></div>
                    <div class="separator separator-sm mr-2"></div>
                </div>
                <p class="text-sm mb-3">Make sure you use a strong password, with at least 1
                    uppercase, 1 lowercase, 1 numeric and 1 special character</p>
                <form [formGroup]="passwordResetFormGroup" (ngSubmit)="resetPassword()" class="space-y-4 flex flex-col">
                    <div>
                        <label for="password" class="block mb-2 text-xs font-medium text-fontPrimary">
                            Password
                            <span class="text-btnDestructive">*</span>
                        </label>
                        <input maxlength="25" formControlName="password" autocomplete="off" id="password"
                            type="password" class="input" required>


                        @if(passwordResetFormGroup.controls['password'].touched &&
                        passwordResetFormGroup.controls['password'].errors?.['required']){
                        <span class="text-xs font-medium text-red-500">This field is required</span>
                        }@else if(passwordResetFormGroup.controls['password'].touched &&
                        passwordResetFormGroup.controls['password'].errors?.['pattern']){
                        <span class="text-xs font-medium text-red-500">Password must include uppercase, lowercase,
                            numeric, and special character</span>
                        }



                    </div>
                    <div>
                        <label for="confirm-password" class="block mb-2 text-xs font-medium text-fontPrimary">
                            Confirm Password
                            <span class="text-btnDestructive">*</span>
                        </label>
                        <input maxlength="25" formControlName="confirm" autocomplete="off" id="confirm-password"
                            type="password" class="input" required>

                        @if (passwordResetFormGroup.controls['confirm'].touched
                        && passwordResetFormGroup.controls['confirm'].errors?.['required']){
                        <span class="text-xs font-medium text-buttonDangerColor">This field is required</span>
                        }
                    </div>
                    @if(!passwordsMatch) {
                    <p class="text-sm text-buttonDangerColor">Passwords do not match</p>
                    }
                    <button [disabled]="!canSubmit" type="submit" class="btn btn-primary w-full flex justify-center">
                        @if (status !== Status.connecting) {
                        Submit
                        } @else {
                        <div role="status">
                            <mat-spinner mode="indeterminate" [diameter]="25"></mat-spinner>
                            <span class="sr-only">Loading...</span>
                        </div>
                        }
                    </button>
                    <p class="text-start text-sm mt-4">
                        Already set your password? <a [routerLink]="['/auth/login']"
                            class="no-underline text-buttonAlternate">Log In</a>
                    </p>
                </form>
            </div>
            } @else if(this.status === Status.error) {
            <div class="p-10 flex flex-col">
                <div class="flex items-center mb-4">
                    <mat-icon class="text-buttonDangerColor mr-2">error</mat-icon>
                    <h4 class="text-2xl font-medium">Error</h4>
                </div>
                <p class="text-base mb-10">Something went wrong while setting your password. The password
                    reset link might have expired. Please try again.</p>
                <p class="text-base">
                    Already set up your password? <a [routerLink]="['/auth/login']"
                        class="no-underline text-buttonAlternate">Log In</a>
                </p>
            </div>
            } @else if (this.status === Status.success) {
            <div class="p-10 flex flex-col">
                <div class="flex items-center mb-4">
                    <mat-icon class="text-bgSuccess mr-2">check_circle</mat-icon>
                    <h4 class="text-2xl font-medium">Success</h4>
                </div>
                <p class="text-base">
                    Your password has been set successfully. You can login now with your new credentials.
                    <a [routerLink]="['/auth/login']" class="no-underline text-buttonAlternate">Log In</a>
                </p>
            </div>
            }
            }
        </div>
    </div>
</div>