import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { BillingAction } from '../../../../../action/billing.action';
import { Status } from '../../../../../model/enum';
import { License } from '../../../../../model/license.model';
import { BillingState } from '../../../../../state/billing.state';
import { LicenseState } from '../../../../../state/license.state';

@Component({
  selector: 'app-cycle',
  standalone: true,
  imports: [
    MatIconModule,
    MatProgressSpinnerModule,
  ],
  templateUrl: './cycle.component.html',
  styleUrl: './cycle.component.scss'
})
export class CycleComponent implements OnInit, OnDestroy {

  @Select(BillingState.getStatus) private status$!: Observable<Status>;
  @Select(LicenseState.getActiveLicense) private license$!: Observable<License>;

  private destroy$: Subject<boolean> = new Subject();

  private license!: License;

  public status: Status = Status.idle;
  public Status = Status;

  constructor(
    private store: Store,
    public dialogRef: MatDialogRef<CycleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      clientId: string;
      cycle: 'month' | 'year',
    },
  ) { }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngOnInit(): void {
    this.license$.pipe(takeUntil(this.destroy$)).subscribe(response => {
      this.license = response;
    });

    this.status$.pipe(takeUntil(this.destroy$)).subscribe(response => {
      this.status = response;
    });
  }

  submit() {
    this.store.dispatch(new BillingAction.SwitchBillingCycle(this.license.client_id));
  }

  cancel() {
    this.dialogRef.close();
  }
}
