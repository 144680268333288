import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { NgSelectModule } from '@ng-select/ng-select';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { EnvironmentAction } from '../../../../action/environment.action';
import { OrgAction } from '../../../../action/org.action';
import { Environment } from '../../../../model/envrionment.model';
import { License } from '../../../../model/license.model';
import { User } from '../../../../model/user.model';
import { EnvironmentState } from '../../../../state/environment.state';
import { LicenseState } from '../../../../state/license.state';
import { OrgState } from '../../../../state/org.state';
import { DialogComponent } from '../../../utils/dialog/dialog.component';

@Component({
  selector: 'app-env-iam',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatPaginatorModule,
    MatTableModule,
    ReactiveFormsModule,
    NgSelectModule,
  ],
  templateUrl: './env-iam.component.html',
  styleUrl: './env-iam.component.scss'
})
export class EnvIamComponent implements OnInit, AfterViewInit, OnDestroy {

  private clientId: string = '';
  private activeEnvironment!: Environment;
  private orgUsers: User[] = [];

  private destroy$: Subject<boolean> = new Subject();

  @Select(EnvironmentState.getActiveEnvironment) private activeEnvironment$!: Observable<Environment>;
  @Select(OrgState.getUsers) private orgUsers$!: Observable<User[]>;
  @Select(LicenseState.getActiveLicense) private license$!: Observable<License | undefined>;

  displayedColumns = ["name", "email", "actions"];
  userDataSource!: MatTableDataSource<User>;

  addUserFormGroup = new FormGroup({
    id: new FormControl<string | undefined>(undefined, Validators.required),
  });

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(
    private store: Store,
    private dialog: MatDialog,
  ) { }

  get filteredUsers() {
    return this.orgUsers.filter(orgUser => {
      const exists = this.activeEnvironment?.users?.find(u => u.id === orgUser.id);

      return exists === undefined;
    });
  }

  ngOnInit(): void {

    this.license$.pipe(takeUntil(this.destroy$)).subscribe(
      response => {
        if (response) {
          this.clientId = response.client_id;

          this.store.dispatch(new OrgAction.GetUsers(this.clientId));
        }
      }
    );

    this.orgUsers$.pipe(takeUntil(this.destroy$)).subscribe(
      response => {
        if (response) {
          this.orgUsers = response;
        }
      }
    )

    this.activeEnvironment$.pipe(takeUntil(this.destroy$)).subscribe(
      response => {
        if (response) {
          this.activeEnvironment = response;

          this.userDataSource = new MatTableDataSource(response.users);
          this.userDataSource.paginator = this.paginator;
        }
      }
    );
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.userDataSource) this.userDataSource.paginator = this.paginator;
    }, 2000);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  addUser() {
    if (this.addUserFormGroup.valid) {
      const value = this.addUserFormGroup.value;

      this.store.dispatch(
        new EnvironmentAction.AddEnvironmentAccess(this.clientId, this.activeEnvironment.id!, value.id!),
      );
    }
  }

  removeUser(id: string) {
    const dialog = this.dialog.open(
      DialogComponent,
      {
        maxWidth: 650,
        data: {
          title: 'Confirm Removal',
          message: "You are about to remove the user's access to this environment. This will stop the user from deploying any new applications to the environment. Any applications already deployed will remain unaffected.\n\n Are you sure you wish to continue?",
          actionLabel: 'Confirm',
        }
      }
    );

    dialog.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(
      response => {
        if (response === true) {
          this.store.dispatch(new EnvironmentAction.DeleteEnvironmentAccess(this.clientId, this.activeEnvironment.id!, id));
        }
      },
    );
  }
}
