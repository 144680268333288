<mat-drawer-container autosize class="w-full h-full bg-bgDefault">
    <mat-drawer #drawer
        class="menu-toolbar sidenav-secondary bg-bgDefault overflow-visible border-none shadow-none flex" mode="side"
        [opened]="isSidenavVisible">
        <div [matTooltip]="isSidenavVisible ? 'Hide menu': 'Show menu'" class="sidenav-toggle"
            (click)="isSidenavVisible = !isSidenavVisible">
            <mat-icon>{{isSidenavVisible ? 'chevron_left': 'chevron_right'}}</mat-icon>
        </div>
        <section class="flex-1 w-full">
            <app-switcher></app-switcher>
            <mat-divider></mat-divider>
            <ul class="no-select menu mt-5">
                <li>
                    <a class="flex justify-start items-center cursor-pointer menu-item" routerLinkActive="active"
                        [routerLink]="['.']" [queryParams]="{ section: 'overview' }">
                        <mat-icon class="me-2" inline>account_balance</mat-icon>
                        <h6 class="text-[0.8rem]">Overview</h6>
                    </a>
                </li>
                <li>
                    <a class="flex justify-start items-center cursor-pointer menu-item" routerLinkActive="active"
                        [routerLink]="['.']" [queryParams]="{ section: 'settings' }">
                        <mat-icon class="me-2" inline>settings</mat-icon>
                        <h6 class="text-[0.8rem]">Settings</h6>
                    </a>
                </li>
            </ul>
        </section>
    </mat-drawer>
    <mat-drawer-content class="flex flex-col flex-nowrap bg-bgDefault">
        <app-header [title]="pageTitle"></app-header>
        <section class="px-6">
            @switch (this.activeMenuIndex) {
            @case (0) {
            <app-overview></app-overview>
            }
            @case (1) {
            <app-settings></app-settings>
            }
            }
        </section>
    </mat-drawer-content>
</mat-drawer-container>