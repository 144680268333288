import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { catchError, tap } from "rxjs/operators";
import { ThemeAction } from "../action/theme.action";
import { Status } from "../model/enum";
import { ThemeData, ThemeMode, ThemeStateModel } from "../model/theme.model";
import { ThemeService } from "../service/theme.service";

@State<ThemeStateModel>({
    name: 'theme',
    defaults: {
        mode: ThemeMode.light,
        status: Status.idle,
        theme: undefined,
    },
})
@Injectable()
export class ThemeState {

    constructor(
        private themeService: ThemeService,
    ) { }

    @Selector()
    static getTheme(state: ThemeStateModel): ThemeData {
        const theme = state.theme;
        const themeData = new ThemeData();

        switch (state.mode) {
            case ThemeMode.dark:
                themeData.primaryColor = theme?.primaryColor.dark!;
                themeData.secondaryColor = theme?.secondaryColor.dark!;
                themeData.buttonColor = {
                    primaryColor: theme?.buttonColor!.primaryColor.dark!,
                    secondaryColor: theme?.buttonColor.secondaryColor.dark!,
                    destructiveColor: theme?.buttonColor.destructiveColor.dark!,
                };
                themeData.buttonFontColor = {
                    primaryColor: theme?.buttonFontColor.primaryColor.dark!,
                    secondaryColor: theme?.buttonFontColor.secondaryColor.dark!,
                    destructiveColor: theme?.buttonFontColor.destructiveColor.dark!,
                };
                themeData.fontColor = {
                    primaryColor: theme?.fontColor.primaryColor.dark!,
                    secondaryColor: theme?.fontColor.secondaryColor.dark!,
                };
                themeData.iconBytes = theme?.iconBytes!;
                themeData.imageBytes = theme?.imageBytes!;
                break;
            case ThemeMode.light:
                themeData.primaryColor = theme?.primaryColor.light!;
                themeData.secondaryColor = theme?.secondaryColor.light!;
                themeData.buttonColor = {
                    primaryColor: theme?.buttonColor!.primaryColor.light!,
                    secondaryColor: theme?.buttonColor.secondaryColor.light!,
                    destructiveColor: theme?.buttonColor.destructiveColor.light!,
                };
                themeData.buttonFontColor = {
                    primaryColor: theme?.buttonFontColor.primaryColor.light!,
                    secondaryColor: theme?.buttonFontColor.secondaryColor.light!,
                    destructiveColor: theme?.buttonFontColor.destructiveColor.light!,
                };
                themeData.fontColor = {
                    primaryColor: theme?.fontColor.primaryColor.light!,
                    secondaryColor: theme?.fontColor.secondaryColor.light!
                };
                themeData.iconBytes = theme?.iconBytes!;
                themeData.imageBytes = theme?.imageBytes!;
                break;
        }

        return themeData;
    }

    @Selector()
    static getThemeMode(state: ThemeStateModel) {
        return state.mode;
    }

    @Action(ThemeAction.FetchTheme)
    fetchTheme(ctx: StateContext<ThemeStateModel>, _: ThemeAction.FetchTheme) {
        const state = ctx.getState();

        if (state.theme) {
            ctx.patchState({
                status: Status.success,
            });

            return;
        } else {
            return this.themeService.fetchTheme().pipe(
                tap(response => {
                    ctx.patchState({
                        theme: response,
                        status: Status.success,
                    });
                }),
                catchError(err => {
                    ctx.patchState({
                        theme: undefined,
                        status: Status.error,
                    });

                    return err;
                }),
            );
        }
    }

    @Action(ThemeAction.SwitchTheme)
    switchTheme(ctx: StateContext<ThemeStateModel>, _: ThemeAction.SwitchTheme) {
        const state = ctx.getState();
        let mode = state.mode;

        if (mode == ThemeMode.dark) {
            mode = ThemeMode.light;
        } else {
            mode = ThemeMode.dark;
        }

        ctx.patchState({
            mode: mode,
        })
    }
}