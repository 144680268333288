import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { url } from "../../environments/url";
import { Organization } from "../model/organization.model";
import { User } from "../model/user.model";


@Injectable({
    providedIn: 'root'
})
export class OrgService {

    constructor(
        private http: HttpClient,
    ) { }

    getOrganization(id: string) {
        return this.http.get<Organization>(
            `${environment.backend}${url.organization.get}`,
            {
                params: {
                    id: id,
                }
            }
        );
    }

    getUsersByOrg(clientId: string) {
        return this.http.get<User[]>(
            `${environment.backend}${url.organization.users}`,
            {
                params: {
                    client_id: clientId,
                },
            }
        );
    }

    addUserToOrg(clientId: string, userId: string, role: string, hasPurchaseAccess: boolean, mode: string, invitees: string[],) {
        return this.http.post<{}>(
            `${environment.backend}${url.organization.users}`,
            {
                'user_id': userId,
                'role': role,
                'purchase_access': hasPurchaseAccess,
                'mode': mode,
                'invitees': invitees,
            },
            {
                params: {
                    client_id: clientId,
                }
            }
        )
    }

    updateUserForOrg(clientId: string, userId: string, role: string, hasPurchaseAccess: boolean) {
        return this.http.put<{}>(
            `${environment.backend}${url.organization.users}`,
            {
                'user_id': userId,
                'role': role,
                'purchase_access': hasPurchaseAccess,
            },
            {
                params: {
                    client_id: clientId,
                }
            }
        )
    }

    removeUserFromOrg(clientId: string, userId: string) {
        return this.http.post<{}>(
            `${environment.backend}${url.organization.deleteUser}`,
            {},
            {
                params: {
                    client_id: clientId,
                    user_id: userId,
                }
            }
        )
    }
}