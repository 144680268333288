import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { url } from '../../environments/url';
import { CheckoutPayload, PaymentMethod, Subscription, Transaction } from '../model/billing/billing.model';

@Injectable({
  providedIn: 'root'
})
export class BillingService {

  constructor(
    private http: HttpClient,
  ) { }

  getPlans(cycle: string) {
    return this.http.get<{
      id: string,
      name: string,
      description: string,
      currency: string,
      included: string[],
      highlight: boolean,
      pricing: string,
    }[]>(
      environment.backend + url.billing.plan.all,
      {
        params: {
          'cycle': cycle,
        }
      },
    );
  }

  getTransactions(clientId: string) {
    return this.http.get<Transaction[]>(
      environment.backend + url.billing.transaction.all,
      {
        params: {
          'client_id': clientId,
        },
      }
    );
  }

  getSubscription(clientId: string) {
    return this.http.get<Subscription>(
      environment.backend + url.billing.subscription.get,
      {
        params: {
          'client_id': clientId,
        },
      }
    );
  }

  getCosts(clientId: string) {
    return this.http.get<{ 'subscription': number, 'export': number, 'environment': number }>(
      environment.backend + url.billing.subscription.costs,
      {
        params: {
          'client_id': clientId,
        },
      }
    );
  }

  getPaymentMethods(clientId: string, userId: string) {
    return this.http.get<PaymentMethod[]>(
      environment.backend + url.billing.subscription.methods,
      {
        params: {
          'client_id': clientId,
          'user_id': userId,
        },
      }
    );
  }

  cancelSubscription(clientId: string) {
    return this.http.post<{}>(
      environment.backend + url.billing.subscription.cancel,
      {},
      {
        params: {
          'client_id': clientId,
        },
      }
    );
  }

  restoreSubscription(clientId: string) {
    return this.http.post<{}>(
      environment.backend + url.billing.subscription.restore,
      {},
      {
        params: {
          'client_id': clientId,
        },
      }
    );
  }

  updateSubscription(clientId: string, units: number) {
    return this.http.post<{}>(
      environment.backend + url.billing.subscription.update,
      {
        units: units,
      },
      {
        params: {
          client_id: clientId,
        }
      },
    );
  }

  switchBillingCycle(clientId: string) {
    return this.http.post<{}>(
      environment.backend + url.billing.subscription.cycle,
      {},
      {
        params: {
          client_id: clientId,
        }
      },
    );
  }

  checkout(payload: CheckoutPayload) {
    return this.http.post<{ url: string }>(
      environment.backend + url.billing.checkout,
      payload,
    )
  }

  checkDomain(domain: string) {
    return this.http.get<{ count: number }>(
      `${environment.backend}/api/organization/domain`,
      {
        params: {
          domain: domain,
        }
      },
    );
  }
}
