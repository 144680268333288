<!-- @if (!isLoading && !hasError) { -->
<mat-drawer-container [autosize]="true" class="w-full h-full bg-bgDefault">
  <mat-drawer #sidenavSecondary
    class="menu-toolbar sidenav-secondary bg-bgDefault overflow-visible visible border-none shadow-none flex"
    mode="side" [opened]="isSidenavVisible">
    <div [matTooltip]="isSidenavVisible ? 'Hide menu': 'Show menu'" class="sidenav-toggle"
      (click)="isSidenavVisible = !isSidenavVisible">
      <mat-icon>{{isSidenavVisible ? 'chevron_left': 'chevron_right'}}</mat-icon>
    </div>
    <section class="flex-1 w-full mt-2">
      <h6 class="mt-2 mb-2 text-base mx-2">{{ name }}</h6>
      <p class="mb-3 mx-2 text-sm overflow-hidden text-ellipsis text-buttonAlternate">{{ email }}</p>
      <mat-divider></mat-divider>
      <ul class="no-select menu mt-5">
        @if(!isSuperuser) {
        <!-- <li>
          <a mat-list-item class="flex justify-start items-center cursor-pointer menu-item" routerLinkActive="active"
            [routerLink]="['.']" [queryParams]="{ section: 'users' }">
             <mat-icon class="me-2" inline>supervisor_account</mat-icon>
        <h6 class="text-[0.8rem]">Users</h6>
        </a>
        </li>
        <li>
          <a mat-list-item class="flex justify-start items-center cursor-pointer menu-item" routerLinkActive="active"
            [routerLink]="['.']" [queryParams]="{ section: 'branding' }">
            <mat-icon class="me-2" inline>supervisor_account</mat-icon>
            <h6>Branding</h6>
          </a>
        </li> -->
        }
        <li>
          <a mat-list-item class="flex justify-start items-center cursor-pointer menu-item" routerLinkActive="active"
            [routerLink]="['.']" [queryParams]="{ section: this.isSuperuser ? 'admin' : 'license' }"
            (click)="resetOrgState()">
            <h6>Organizations</h6>
          </a>
        </li>
        <li>
          <a mat-list-item class="flex justify-start items-center cursor-pointer menu-item" routerLinkActive="active"
            [routerLink]="['.']" [queryParams]="{ section: 'requests' }">
            <!-- <mat-icon class="me-2" inline>supervisor_account</mat-icon> -->
            <h6>Requests</h6>
          </a>
        </li>
        @if (isSuperuser) {
        <li>
          <a mat-list-item class="flex justify-start items-center cursor-pointer menu-item" routerLinkActive="active"
            [routerLink]="['.']" [queryParams]="{ section: 'registrations' }">
            <!-- <mat-icon class="me-2" inline>supervisor_account</mat-icon> -->
            <h6>Registrations</h6>
          </a>
        </li>
        }
      </ul>
    </section>
  </mat-drawer>
  <mat-drawer-content class="flex flex-col flex-nowrap bg-white">
    @if(this.showHeader) {
    <app-header [title]="pageTitle"></app-header>
    }

    <div class="app-details-section px-6 py-2">
      <section class="flex-1 flex items-center justify-center" *ngIf="activeMenuItem === -1">
        <mat-spinner [diameter]="25"></mat-spinner>
      </section>
      <!-- Manage Users -->

      @if (!isSuperuser) {

      <!-- Users Section -->
      <!-- <section class="flex-1" *ngIf="activeMenuItem === 1">
        <p class="message-sm mb-3">Manage all the users and control their access to the PIES Studio portal</p>
        <button color="primary"
          class="outline-none mb-4 me-2 relative text-btnFontColor text-sm py-2 leading-none min-h-9 px-4 cursor-pointer bg-buttonAlternate btn btn-primary rounded-md"
          (click)="showUserCreateMenu()">
          <span>Add Users</span>
        </button>
        <mat-divider></mat-divider>
        <h6 class="my-3">Regsitered Users</h6>
        <div class="items-center" *ngIf="isLoading">
          <mat-spinner diameter="15"></mat-spinner><small>&nbsp;Loading </small>
        </div>
        <form class="mt-2" [formGroup]="userDataFilterForm" *ngIf="!isLoading">
          <div class="mt-3 grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
            <div class="sm:col-span-2 mb-2">
              <label for="search" class="block text-sm font-medium leading-6 text-gray-900">Search</label>
              <div>
                <input type="text" formControlName="name"
                  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 focus:border-none ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                  placeholder="Filter" />
              </div>
            </div>
            <div class="sm:col-span-1">
              <label class="block text-sm font-medium leading-6 text-gray-900">Status</label>
              <ng-select dropdownPosition="bottom" [clearable]="true" [items]="status" formControlName="status"
                bindValue="value" bindLabel="text" class="rounded-md placeholder:text-gray-400 text-sm"
                placeholder="Select status">
              </ng-select>
            </div>
            <div class="sm:col-span-1">
              <label class="block text-sm font-medium leading-6 text-gray-900">Role</label>
              <ng-select dropdownPosition="bottom" [clearable]="true" [items]="userRoles" formControlName="role"
                bindValue="name" bindLabel="name" class="rounded-md placeholder:text-gray-400 text-sm"
                placeholder="Select role">
              </ng-select>
            </div>
          </div>
        </form>
        @if (!isLoading && userDataSource.filteredData.length > 0) {
        <div class="mt-5">
          <table mat-table [dataSource]="userDataSource" *ngIf="!isLoading"
            class="table-auto w-full shadow-none border-buttonAlternate border-solid border">
            
            <ng-container matColumnDef="name" class="text-btnFontColor bg-secondary">
              <th mat-header-cell *matHeaderCellDef [ngClass]="{
                    'mat-mdc-header-cell': false,
                    'mdc-data-table__header-cell': false
                  }" class="text-left bg-backgroundColor font-medium py-0.5 px-3">
                Name
              </th>
              <td mat-cell *matCellDef="let element">{{ element.name }}</td>
            </ng-container>

            
            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef [ngClass]="{
                    'mat-mdc-header-cell': false,
                    'mdc-data-table__header-cell': false
                  }" class="text-left bg-backgroundColor font-medium py-0.5 px-3">
                Email
              </th>
              <td mat-cell *matCellDef="let element">{{ element.email }}</td>
            </ng-container>

            
            <ng-container matColumnDef="role">
              <th mat-header-cell *matHeaderCellDef [ngClass]="{
                    'mat-mdc-header-cell': false,
                    'mdc-data-table__header-cell': false
                  }" class="text-left bg-backgroundColor font-medium py-0.5 px-3">
                Role
              </th>
              <td mat-cell *matCellDef="let element">{{ element.role }}</td>
            </ng-container>

            
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef [ngClass]="{
                    'mat-mdc-header-cell': false,
                    'mdc-data-table__header-cell': false
                  }" class="text-left bg-backgroundColor font-medium py-0.5 px-3">
                Status
              </th>
              <td mat-cell *matCellDef="let element">
                <div class="flex items-center">
                  <ng-container *ngIf="element.is_active">
                    <mat-icon class="me-2 text-bgSuccess">check_circle</mat-icon>
                    Active
                  </ng-container>
                  <ng-container *ngIf="!element.is_active">
                    <mat-icon class="me-2 text-buttonDangerColor">error</mat-icon> Inactive
                  </ng-container>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef [ngClass]="{
                    'mat-mdc-header-cell': false,
                    'mdc-data-table__header-cell': false
                  }" class="text-left bg-backgroundColor font-medium py-0.5 px-3">
                Actions
              </th>
              <td mat-cell *matCellDef="let element">
                <a mat-icon-button color="primary" inline (click)="showUserCreateMenu(element)">
                  <mat-icon class="text-iconsTableColor">edit</mat-icon>
                </a>
                <a mat-icon-button color="primary" inline (click)="inviteUser(element.email)">
                  <mat-icon class="text-iconsTableColor">email</mat-icon>
                </a>
                <a mat-icon-button color="warn" inline (click)="deleteUsers(element)"
                  *ngIf="element.applications === 0 && element.workspaces === 0">
                  <mat-icon class="text-iconsTableColor">delete</mat-icon>
                </a>

                <a mat-icon-button color="warn" inline disabled *ngIf="
                      !(element.applications === 0 && element.workspaces === 0)
                    ">
                  <mat-icon
                    matTooltip="User has one or more applications or workspaces assigned to him and cannot be deleted">
                    delete</mat-icon>
                </a>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns" [ngClass]="{ 'mat-mdc-header-row': false }"
              class="h-[40px] text-sm border-solid border-2 border-b-btnFontSecondary"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" class="h-[40px] py-0.5 px-3 text-sm">
            </tr>
          </table>
          @if (!isLoading) {
          <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons #userPaginator
            class="bg-backgroundColor text-fontColor text-sm/[0.9rem]">
          </mat-paginator>
          }
        </div>
        } @else if (!isLoading && userDataSource.filteredData.length === 0) {
        <p class="message-sm mb-3">No registered users available</p>
        }
      </section> -->


      <!-- Branding Section -->
      <!-- <section class="flex-1" *ngIf="activeMenuItem === 2 && !isLoading">
        <p class="text-sm text-fontColor mb-4">
          View and manage the color palettes and logos for dynamic branding
        </p>
        <form #myForm="ngForm">
          <div class="flex flex-row columns-1 gap-16 mb-0">
            <div>
              <h4 class="text-lg text-fontColor font-medium mb-2">Mini Logo</h4>
              <div class="flex items-start mb-3">
                <div
                  class="me-3 w-[70px] max-w-[70px] max-h-[70px] bg-secondaryFontColorLight overflow-hidden flex justify-center items-center rounded-md h-[70px]">
                  <img *ngIf="iconPreview1 !== undefined" [src]="iconPreview1" class="w-4/5" />
                </div>
                <div class="flex flex-col">
                  <div class="text-sm mb-1">
                    Upload JPEG, PNG image. Max size 2 MB
                  </div>
                  <a type="button" class="text-primary font-medium cursor-pointer text-sm outline-none border-0"
                    (click)="fileInput1.click()">Browse</a>
                  <input (change)="onFileSelected($event, 'image1')" accept=".jpg,.png" hidden
                    class="d-none form-control" id="file-upload" type="file" #fileInput1 />
                  <small *ngIf="invalidIconSize" class="text-danger">File size should not be more than 2 MB</small>
                </div>
              </div>
            </div>
            <div>
              <h4 class="text-lg text-fontColor font-medium mb-2">Full Logo</h4>
              <div class="flex items-start mb-3">
                <div
                  class="me-3 w-[70px] max-w-[70px] h-[70px] max-h-[70px] bg-secondaryFontColorLight overflow-hidden justify-center items-center rounded-md flex">
                  <img *ngIf="iconPreview2 !== undefined" [src]="iconPreview2" class="w-4/5" />
                </div>
                <div class="flex flex-col">
                  <div class="text-sm mb-1">
                    Upload JPEG, PNG image. Max size 2 MB
                  </div>
                  <a type="button" class="text-primary font-medium cursor-pointer text-sm outline-none border-0"
                    (click)="fileInput.click()">Browse</a>
                  <input (change)="onFileSelected($event, 'image2')" accept=".jpg,.png" hidden
                    class="d-none form-control" id="file-upload" type="file" #fileInput />
                  <small *ngIf="invalidIconSize" class="text-danger">File size should not be more than 2 MB</small>
                </div>
              </div>
            </div>
          </div>

          <div class="flex flex-col divide-y">
            <section class="flex flex-col py-5">
              <h5 class="mb-2 leading-tight text-lg">Color Swatches</h5>
              <p class="text-sm mb-3">Configure the various color swatches shown below to set the color theme for your
                PIES
                Studio portal</p>
              <div class="flex flex-row columns-1 gap-10">
                <div
                  class="flex flex-col rounded-md border-0 p-3 text-primary shadow-sm ring-1 ring-gray-200 focus:border-none ring-inset">
                  <h6 class="mb-2 text-lg">Primary Color</h6>
                  <div class="flex flex-row">
                    <div class="flex flex-col flex-1">
                      <label for="primary_color_dark" class="text-sm text-fontColor font-medium">Light</label>
                      <input class="block p-0 outline-none w-full mb-0 mr-1" name="primaryColorlight" value="blue"
                        type="color" [(ngModel)]="dynamicBrandingPallet.primaryColor.light" />
                    </div>
                    <div class="flex flex-col flex-1">
                      <label for="primary_color_dark" class="text-sm text-fontColor font-medium">Dark</label>
                      <input class="block p-0 outline-none w-full mb-0" value="lightblack" name="primaryColorDark"
                        type="color" [(ngModel)]="dynamicBrandingPallet.primaryColor.dark" />
                    </div>
                  </div>
                </div>
                <div
                  class="flex flex-col rounded-md border-0 p-3 text-primary shadow-sm ring-1 ring-gray-200 focus:border-none ring-inset">
                  <h6 class="mb-2 text-lg">Secondary Color</h6>
                  <div class="flex flex-row">
                    <div class="flex flex-col">
                      <label for="primary_color_dark" class="text-sm text-fontColor font-medium">Light</label>
                      <input class="block p-0 outline-none w-full mb-0 mr-1" name="secondaryColorlight" type="color"
                        [(ngModel)]="dynamicBrandingPallet.secondaryColor.light" />
                    </div>
                    <div class="flex flex-col">
                      <label for="primary_color_dark" class="text-sm text-fontColor font-medium">Dark</label>
                      <input class="block p-0 outline-none mb-0" name="secondaryColorlight" type="color"
                        [(ngModel)]="dynamicBrandingPallet.secondaryColor.dark" />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section class="flex items-start py-5">
              <div class="flex flex-col">
                <h6 class="mb-2 leading-tight text-lg">Button Color</h6>
                <p class="mb-3 text-sm">Sets the background color for the portal buttons</p>
                <div class="flex flex-row columns-1 gap-10">
                  <div
                    class="flex flex-col rounded-md border-0 p-3 text-primary shadow-sm ring-1 ring-gray-200 focus:border-none ring-inset">
                    <h6 class="mb-2">Primary Color</h6>
                    <div class="flex flex-row">
                      <div class="flex flex-col">
                        <label for="primary_color_dark" class="text-sm text-fontColor font-medium">Light</label>
                        <input class="block p-0 outline-none mb-0 mr-1" value="blue" name="buttonColorprimaryColorlight"
                          type="color" [(ngModel)]="
                        dynamicBrandingPallet.buttonColor.primaryColor.light
                      " />
                      </div>
                      <div class="flex flex-col">
                        <label for="primary_color_dark" class="text-sm text-fontColor font-medium">Dark</label>
                        <input class="block p-0 outline-none mb-0" value="lightblack" name="buttonColorprimaryColordark"
                          type="color" [(ngModel)]="
                        dynamicBrandingPallet.buttonColor.primaryColor.dark
                      " />
                      </div>
                    </div>
                  </div>
                  <div
                    class="flex flex-col rounded-md border-0 p-3 text-primary shadow-sm ring-1 ring-gray-200 focus:border-none ring-inset">
                    <div class="form-group">
                      <h6 class="mb-2">Secondary Color</h6>
                      <div class="flex flex-row">
                        <div class="flex flex-col">
                          <label for="primary_color_dark" class="text-sm text-fontColor font-medium">Light</label>
                          <input class="block p-0 outline-none mb-0 mr-1" type="color"
                            name="buttonColorsecondaryColorlight" [(ngModel)]="
                          dynamicBrandingPallet.buttonColor.secondaryColor.light
                        " />
                        </div>
                        <div class="flex flex-col">
                          <label for="primary_color_dark" class="text-sm text-fontColor font-medium">Dark</label>
                          <input class="block p-0 outline-none mb-0" type="color" name="buttonColorsecondaryColordark"
                            [(ngModel)]="
                          dynamicBrandingPallet.buttonColor.secondaryColor.dark
                        " />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="flex flex-col rounded-md border-0 p-3 text-primary shadow-sm ring-1 ring-gray-200 focus:border-none ring-inset">
                    <h6 class="mb-2">Destructive Color</h6>
                    <div class="flex flex-row">
                      <div class="flex flex-col">
                        <label for="primary_color_dark" class="text-sm text-fontColor font-medium">Light</label>
                        <input class="block p-0 outline-none mb-0 mr-1" value="blue" type="color"
                          name="buttonColordestructiveColorlight" [(ngModel)]="
                        dynamicBrandingPallet.buttonColor.destructiveColor.light
                      " />
                      </div>
                      <div class="flex flex-col">
                        <label for="primary_color_dark" class="text-sm text-fontColor font-medium">Dark</label>
                        <input class="block p-0 outline-none mb-0" value="lightblack" type="color"
                          name="buttonColordestructiveColordark" [(ngModel)]="
                        dynamicBrandingPallet.buttonColor.destructiveColor.dark
                      " />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mx-6"></div>
              <div class="flex flex-col">
                <h6 class="mb-2 leading-tight text-lg">Button Font Color</h6>
                <p class="text-sm mb-3">Sets the color for the button labels and icons</p>
                <div class="flex flex-row columns-1 gap-10">
                  <div
                    class="flex flex-col rounded-md border-0 p-3 text-primary shadow-sm ring-1 ring-gray-200 focus:border-none ring-inset">
                    <h6 class="mb-2">Primary Color</h6>
                    <div class="flex flex-row">
                      <div class="flex flex-col">
                        <label for="primary_color_dark" class="text-sm text-fontColor font-medium">Light</label>
                        <input class="block p-0 outline-none mb-0 mr-1" value="blue" name="buttonFontprimaryColorlight"
                          type="color" [(ngModel)]="
                        dynamicBrandingPallet.buttonFontColor.primaryColor.light
                      " />
                      </div>
                      <div class="flex flex-col">
                        <label for="primary_color_dark" class="text-sm text-fontColor font-medium">Dark</label>
                        <input class="block p-0 outline-none mb-0" value="lightblack" name="buttonFontprimaryColordark"
                          type="color" [(ngModel)]="
                        dynamicBrandingPallet.buttonFontColor.primaryColor.dark
                      " />
                      </div>
                    </div>
                  </div>
                  <div
                    class="flex flex-col rounded-md border-0 p-3 text-primary shadow-sm ring-1 ring-gray-200 focus:border-none ring-inset">
                    <h6 class="mb-2">Secondary Color</h6>
                    <div class="flex flex-row">
                      <div class="flex flex-col">
                        <label for="primary_color_dark" class="text-sm text-fontColor font-medium">Light</label>
                        <input class="block p-0 outline-none mb-0 mr-1" type="color"
                          name="buttonFontsecondaryColorlight" [(ngModel)]="
                        dynamicBrandingPallet.buttonFontColor.secondaryColor.light
                      " />
                      </div>
                      <div class="flex flex-col">
                        <label for="primary_color_dark" class="text-sm text-fontColor font-medium">Dark</label>
                        <input class="block p-0 outline-none mb-0" type="color" name="buttonFontsecondaryColordark"
                          [(ngModel)]="
                        dynamicBrandingPallet.buttonFontColor.secondaryColor.dark
                      " />
                      </div>
                    </div>
                  </div>
                  <div
                    class="flex flex-col rounded-md border-0 p-3 text-primary shadow-sm ring-1 ring-gray-200 focus:border-none ring-inset">
                    <h6 class="mb-2">Destructive Color</h6>
                    <div class="flex flex-row">
                      <div class="flex flex-col">
                        <label for="primary_color_dark" class="text-sm text-fontColor font-medium">Light</label>
                        <input class="block p-0 outline-none mb-0 mr-1" value="blue" type="color"
                          name="buttonFontdestructiveColorlight" [(ngModel)]="
                        dynamicBrandingPallet.buttonFontColor.destructiveColor
                          .light
                      " />
                      </div>
                      <div class="flex flex-col">
                        <label for="primary_color_dark" class="text-sm text-fontColor font-medium">Dark</label>
                        <input class="block p-0 outline-none mb-0" value="lightblack" type="color"
                          name="buttonFontdestructiveColordark" [(ngModel)]="
                        dynamicBrandingPallet.buttonFontColor.destructiveColor
                          .dark
                      " />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section class="flex flex-col py-5">
              <h6 class="mb-2 leading-tight text-lg">Font Color</h6>
              <p class="text-sm mb-3">Sets the primary and secondary text colors across the portal</p>
              <div class="flex flex-row columns-1 gap-10">
                <div
                  class="flex flex-col rounded-md border-0 p-3 text-primary shadow-sm ring-1 ring-gray-200 focus:border-none ring-inset">
                  <h6 class="mb-2">Primary Color</h6>
                  <div class="flex flex-row">
                    <div class="flex flex-col">
                      <label for="primary_color_dark">Light</label>
                      <input class="block p-0 outline-none mb-0 mr-1" value="blue" type="color"
                        name="textColorprimaryColorlight" [(ngModel)]="
                        dynamicBrandingPallet.textColor.primaryColor.light
                      " />
                    </div>
                    <div class="flex flex-col">
                      <label for="primary_color_dark">Dark</label>
                      <input class="block p-0 outline-none mb-0" value="lightblack" name="textColorprimaryColordark"
                        type="color" [(ngModel)]="
                        dynamicBrandingPallet.textColor.primaryColor.dark
                      " />
                    </div>
                  </div>
                </div>
                <div
                  class="flex flex-col rounded-md border-0 p-3 text-primary shadow-sm ring-1 ring-gray-200 focus:border-none ring-inset">
                  <h6 class="mb-2">Secondary Color</h6>
                  <div class="flex flex-row">
                    <div class="flex flex-col">
                      <label for="primary_color_dark" class="text-sm text-fontColor font-medium">Light</label>
                      <input class="block p-0 outline-none mb-0 mr-1" value="blue" name="textColorsecondaryColorlight"
                        type="color" [(ngModel)]="
                        dynamicBrandingPallet.textColor.secondaryColor.light
                      " />
                    </div>
                    <div class="flex flex-col">
                      <label for="primary_color_dark" class="text-sm text-fontColor font-medium">Dark</label>
                      <input class="block p-0 outline-none mb-0" value="lightblack" name="textColorsecondaryColordark"
                        type="color" [(ngModel)]="
                        dynamicBrandingPallet.textColor.secondaryColor.dark
                      " />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </form>
        <button
          class="cursor-pointer min-w-[64px] overflow-visible mt-2 mr-2 mb-6 px-4 bg-primary text-btnFontColor text-sm min-h-9 rounded"
          (click)="updateBranding()">
          <span>Save Changes</span>
        </button>
      </section> -->

      <!-- Loader -->
      <ng-container *ngIf="activeMenuItem === 2 && isLoading">
        <section class="w-100 h-100 flex items-center justify-center">
          <mat-spinner [diameter]="25"></mat-spinner>
        </section>
      </ng-container>

      <section class="flex-1" *ngIf="activeMenuItem === 3">
        <app-license (showHeader)="toggleHeader($event)"></app-license>
      </section>
      }
      <section class="flex-1" *ngIf="activeMenuItem === 4">
        <app-request></app-request>
      </section>
      @if (isSuperuser) {
      <section class="flex-1" *ngIf="activeMenuItem === 5">
        <app-admin [section]="'licenses'"></app-admin>
      </section>
      <section class="flex-1" *ngIf="activeMenuItem === 6">
        <app-admin [section]="'registrations'"></app-admin>
      </section>
      }
    </div>
  </mat-drawer-content>
</mat-drawer-container>
<!-- } -->