<h6 class="text-base mb-2">Users</h6>
<p class="text-sm mb-3">Below listed are the users added under the organization. Use the add button to add new users to
    the organization.</p>

<section class="flex flex-col">
    <div class="flex mb-4">
        <button *ngIf="this.consumedQuota < 100" (click)="addUser()"
            class="btn btn-sm btn-primary px-3 flex items-center">
            <mat-icon class="mr-2">add</mat-icon>
            <span>Add User</span>
        </button>
    </div>
    <div class="ring-1 ring-borderColor rounded-t grid grid-cols-1 divide-y"
        [ngClass]="{'md:divide-y-0': this.consumedQuota < 80}">
        <div class="grid grid-col-1 md:grid-cols-2 divide-x">
            <div class="p-4 flex flex-col">
                <span class="text-xs mb-2">Team Size</span>
                <h6 class="text-lg mb-0">{{totalSeats}}</h6>
            </div>
            <div class="p-4 flex flex-col">
                <span class="text-xs mb-2">Consumed Seats</span>
                <h6 class="text-lg mb-0">{{consumedSeats}}</h6>
            </div>
        </div>
        @if (this.consumedQuota >= 80 && this.consumedQuota < 100 && !this.cancelled) { <div class="flex items-center py-4 px-5">
            <mat-icon class="quota-icon text-bgWarning mr-5">warning</mat-icon>
            <div class="flex flex-col items-start">
                <h6 class="text-base mb-2">Warning</h6>
                <p class="text-sm mb-3">You have consumed more than 80% of your available seats in your license. You
                    might
                    want to think about upgrading your team size soon.
                </p>
                @if (this.tier !== 'creator') {
                    <button (click)="upgradeSeats()" class="btn btn-sm btn-primary px-3 flex items-center">
                        <mat-icon class="mr-2">add</mat-icon>
                        <span>Upgrade Team Size</span>
                    </button>
                }
            </div>
    </div>
    } @else if(this.consumedQuota === 100 && !cancelled) {
    <div class="flex items-center py-4 px-5">
        <mat-icon class="quota-icon text-btnDestructive mr-5">warning</mat-icon>
        <div class="flex flex-col items-start">
            <h6 class="text-base mb-2">Alert</h6>
            <p class="text-sm mb-3">
                You have consumed all of your available seats in your license. You will have to upgrade
                before you can add more users.
            </p>
            @if (this.tier !== 'creator') {
                <button (click)="upgradeSeats()" class="btn btn-sm btn-primary px-3 flex items-center">
                    <mat-icon class="mr-2">add</mat-icon>
                    <span>Upgrade Team Size</span>
                </button>
            }
        </div>
    </div>
    }
    </div>
    <table mat-table [dataSource]="userDataSource"
        class="table-auto w-full rounded-none rounded-b border border-borderColor">

        <!-- User Name -->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let row">
                <span class="badge">{{row.name | titlecase }}</span>
            </td>
        </ng-container>

        <!-- User Email -->
        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef>Email</th>
            <td mat-cell *matCellDef="let row">
                <a [href]="'mailto:' + row.email" class="message-sm text-btnPrimary">{{row.email}}</a>
            </td>
        </ng-container>

        <!-- User Phone -->
        <ng-container matColumnDef="phone">
            <th mat-header-cell *matHeaderCellDef>Phone</th>
            <td mat-cell *matCellDef="let row">
                <p class="message-sm">{{row.phone}}</p>
            </td>
        </ng-container>

        <!-- User Status -->
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let row">
                <div class="flex items-center">
                    @if (row.status === 'pending' || row.status === 'open') {
                    <mat-icon class="text-yellow-500 mr-2">info</mat-icon>
                    } @else if (row.status === 'active') {
                    <mat-icon class="text-bgSuccess mr-2">check_circle</mat-icon>
                    } @else if (row.status === 'deleted') {
                    <mat-icon class="text-buttonDangerColor mr-2">error</mat-icon>
                    }
                    <span class="message-sm">{{row.status | titlecase}}</span>
                </div>
            </td>
        </ng-container>

        <!-- User Timestamp -->
        <ng-container matColumnDef="timestamp">
            <th mat-header-cell *matHeaderCellDef>Timestamp</th>
            <td mat-cell *matCellDef="let row">
                <span class="message-sm">{{row.timestamp | date:'medium'}}</span>
            </td>
        </ng-container>

        <!-- Actions -->
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let row; let i=index">
                @if (userDataSource.length > 1) {
                    <mat-icon class="cursor-pointer text-primary mr-3" (click)="editUser(i)"
                    matTooltip="Edit User">edit</mat-icon>
                    <mat-icon class="cursor-pointer text-danger" (click)="removeUser(i)"
                        matTooltip="Remove User">delete</mat-icon>
                }
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" [attr.colspan]="displayedColumns.length">
                <p class="p-3">No active users found for the organization</p>
            </td>
        </tr>
    </table>

    <mat-paginator class="p-4 text-base" [length]="userDataSource.length" [pageSize]="15"
        aria-label="Select page of users"></mat-paginator>
</section>