import { CommonModule } from "@angular/common";
import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { NgSelectModule } from "@ng-select/ng-select";
import { Select, Store } from "@ngxs/store";
import { Observable, Subject, takeUntil } from "rxjs";
import { BillingAction } from "../../../../../action/billing.action";
import { Plan, PlanPricing } from "../../../../../model/billing/plan.model";
import { Status } from "../../../../../model/enum";
import { License } from "../../../../../model/license.model";
import { BillingState } from "../../../../../state/billing.state";
import { LicenseState } from "../../../../../state/license.state";
import { DialogComponent } from "../../../../utils/dialog/dialog.component";

@Component({
    selector: 'app-upgrade',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
        NgSelectModule,
        MatProgressSpinnerModule,
    ],
    templateUrl: './upgrade.dialog.html',
    styleUrl: './upgrade.dialog.scss'
})
export class UpgradeDialogComponent implements OnInit, OnDestroy {

    @Select(BillingState.getStatus) private status$!: Observable<Status>;
    @Select(LicenseState.getActiveLicense) private license$!: Observable<License>;
    @Select(BillingState.getPlans) private plans$!: Observable<Plan[]>;

    private destroy$: Subject<boolean> = new Subject();

    private license!: License;
    private plans: Plan[] = [];
    public status!: Status;

    public Status = Status;

    public priceMonth: number = 0;
    public priceYear: number = 0;

    public newLimit: number = 0;
    public newPricing!: PlanPricing;
    public showAdditionalSeats: boolean = false;

    public additionalSeatsControl = new FormControl<number>(0, [Validators.required, Validators.min(0), Validators.pattern('[0-9]+')])

    constructor(
        private store: Store,
        public dialogRef: MatDialogRef<DialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            clientId: string;
            cycle: 'month' | 'year',
            units: number,
            consumed: number,
            mode: 'upgrade' | 'any',
        },
    ) { }

    get plan() {
        return this.plans.find(p => p.name === 'pro')!;
    }

    get pricing() {
        if (this.data.mode === 'upgrade') {
            return this.plan.pricing;
        } else {
            return this.plan?.pricing;
        }
    }

    ngOnInit(): void {
        if (!this.data.mode) this.data.mode = 'any';

        this.store.dispatch(new BillingAction.GetPlans(this.data.cycle));

        this.plans$.pipe(takeUntil(this.destroy$)).subscribe(response => {
            if (response) {
                this.plans = response;
                if (this.plans.length === 0) return;

                this.newPricing = this.pricing[0];
                this.newLimit = this.newPricing.max_seats!;

                this.priceMonth = this.pricing[0].price_month;
                this.priceYear = this.pricing[0].price_year;
            }
        });

        this.license$.pipe(takeUntil(this.destroy$)).subscribe(response => {
            this.license = response;
        });

        this.status$.pipe(takeUntil(this.destroy$)).subscribe(response => {
            this.status = response;
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    onTierChange(value: PlanPricing) {
        if (!value?.max_seats) {
            const pricing = this.pricing[this.pricing.length - 2];

            this.newLimit = pricing.max_seats!;

            this.showAdditionalSeats = true;
            this.additionalSeatsControl.reset(0);
        } else {

            this.newLimit = value.max_seats;
            this.additionalSeatsControl.reset(0);

            this.showAdditionalSeats = false;
        }

        this.priceMonth = value.price_month;
        this.priceYear = value.price_year;
    }

    onAdditionalSeatsInput(event: Event) {
        const target = event.target as HTMLInputElement;

        if (!this.additionalSeatsControl.valid) {
            this.newLimit = this.pricing[this.pricing.length - 2].max_seats!;
        } else {
            const seats = parseInt(target.value);

            this.newLimit = this.pricing[this.pricing.length - 2].max_seats! + seats;
        }
    }

    submit() {

        this.store.dispatch(new BillingAction.UpdateSubscription(this.license.client_id, this.newLimit));
    }

    cancel() {
        this.dialogRef.close();
    }
}