@if (status === Status.loading) {
    <div class="p-5 flex items-center">
        <mat-spinner [diameter]="15" class="mr-2"></mat-spinner>
        <p class="text-sm">Please wait...</p>
    </div>
} @else if (status === Status.success) {
    <div class="p-5">
        <div class="flex items-center mb-3">
            <mat-icon class="mr-2 text-bgSuccess">check_circle</mat-icon>
            <h6 class="text-base">Success</h6>
        </div>
        <p class="text-sm mb-5">You're all set! Your changes were successful. Please wait a few minutes and then reload the page to view the changes</p>
        <div class="w-full flex justify-end">
            <button class="btn btn-sm border text-btnPrimary" (click)="cancel()">Close</button>
        </div>
    </div>
} @else if (status === Status.error) {
    <div class="p-5">
        <div class="flex items-center mb-3">
            <mat-icon class="mr-2 text-buttonDangerColor">error</mat-icon>
            <h6 class="text-base">Error</h6>
        </div>
        <p class="text-sm mb-5">Something went wrong. Please try again in some time. If your money was deducted, please raise a support request through the
            <span class="font-medium">Requests</span> section so that we can help you further.
        </p>
        <div class="w-full flex justify-end">
            <button class="btn btn-sm border px-5 text-btnPrimary" (click)="cancel()">Close</button>
        </div>
    </div>
} @else {
    <div class="p-5">
        <h6 class="text-base mb-1">{{this.data.mode === 'upgrade' ? 'Upgrade' : 'Modify'}} Subscription</h6>
        <p class="text-sm mb-3">You can select your desired team size below to {{this.data.mode === 'upgrade' ? 'upgrade' :
            'modify'}} your subscription.</p>

        <div class="flex flex-col mb-3">
            <h6 class="text-sm mb-2">Team Size</h6>
            <ng-select [clearable]="false" [(ngModel)]="this.newPricing" (change)="onTierChange($event)">
                @for (p of pricing; track p.max_seats; let index = $index;) {
                @if (p.max_seats === null) {
                <ng-option [disabled]="this.data.units === p.max_seats" [value]="p">{{pricing[index - 1].max_seats}}+ Seats</ng-option>
                } @else {
                <ng-option [disabled]="this.data.units === p.max_seats" [value]="p">{{p.max_seats}} {{p.max_seats === 1 ? 'Seat'
                    : 'Seats'}}</ng-option>
                }
                }
            </ng-select>
        </div>

        @if (showAdditionalSeats) {
            <div class="flex flex-col mb-3">
                <label for="additional_seats">Additional Seats</label>
                <input (input)="onAdditionalSeatsInput($event)" [formControl]="additionalSeatsControl" class="input" id="additional_seats" value="1" min="0" type="number" placeholder="Enter the number of seats needed above 30"/>
            </div>
        }

        <div class="grid grid-cols-2 ring-1 ring-borderColor rounded divide-x mb-5">
            <div class="p-3">
                <label class="mb-2">Current Team Size</label>
                <h6 class="text-lg">{{this.data.units}} {{this.data.units === 1 ? 'Seat' : 'Seats'}}</h6>
            </div>
            <div class="p-3">
                <label class="mb-2">New Team Size</label>
                <h6 class="text-lg">{{this.newLimit}} Seats</h6>
            </div>
        </div>
        
        @if (this.newLimit < this.data.consumed) {
            <div class="ring-1 ring-borderColor rounded p-3 flex items-center mb-5">
                <mat-icon class="text-btnDestructive me-2">error</mat-icon>
                <p class="text-sm mb-0">The selected team size cannot be less than the number of users in the organization.</p>
            </div>
        } @else if (this.newLimit < this.data.units) {
            <!-- Downgrade mode -->
            <h6 class="text-base mb-1">Subscription Changes</h6>
            <p class="text-sm mb-3">
                The following changes will be made to your subscription pricing from the next billing cycle
            </p>
            <div class="ring-1 ring-borderColor grid grid-cols-1 mb-5 divide-y">
                <div class="p-3">
                    <label class="mb-2">New Price</label>
                    <div class="flex items-center">
                        <h6 class="text-lg">{{this.data.cycle == 'month' ? this.priceMonth * this.newLimit : this.priceYear * newLimit | currency:'USD':'symbol':'.0-0' }}</h6>
                        @if (this.data.cycle === 'year') {
                            <p class="ms-1">/ month, billed yearly</p>
                        } @else {
                            <p class="ms-1">/ month, billed monthly</p>
                        }
                    </div>
                </div>
                <div class="p-3">
                    <label class="mb-2">Billing Cycle</label>
                        <h6 class="text-lg">{{this.data.cycle | titlecase}}ly</h6>
                </div>
            </div>
            <div class="ring-1 ring-borderColor rounded p-3 flex items-center mb-5">
                <mat-icon class="text-bgWarning me-2 min-w-8">warning</mat-icon>
                <p class="text-sm mb-0">You are switching to a lower subscription team size. Any changes made will apply only from the next billing cycle.</p>
            </div>
        } @else {
            <!-- Upgrade mode -->
            <h6 class="text-base mb-1">Subscription Changes</h6>
            <p class="text-sm mb-3">The following changes will apply to your subscription costs. Once you confirm,
                your subscription cost will be modified, and you will be charged for any extra amount that is due.
                <br/><br/>
                Your invoice will be sent on your registered email address.
            </p>

            <div class="ring-1 ring-borderColor grid grid-cols-1 mb-5 divide-y">
                <div class="p-3">
                    <label class="mb-2">New Price</label>
                    <div class="flex items-center">
                        <h6 class="text-lg">{{this.data.cycle == 'month' ? this.priceMonth * this.newLimit : this.priceYear * newLimit | currency:'USD':'symbol':'.0-0' }}</h6>
                        @if (this.data.cycle === 'year') {
                            <p class="ms-1">/ month, billed yearly</p>
                        } @else {
                            <p class="ms-1">/ month, billed monthly</p>
                        }
                    </div>
                </div>
                <div class="p-3">
                    <label class="mb-2">Billing Cycle</label>
                        <h6 class="text-lg">{{this.data.cycle | titlecase}}ly</h6>
                </div>
            </div>
        }

        <div class="flex flex-col w-full">
            @if (this.newLimit > this.data.consumed) {
                <p class="text-sm mb-5">By continuing, you agree to the price changes mentioned above, and allow us to charge any extra amount that may be due from you after the changes are applied.</p>
                <button type="button" class="btn btn-primary px-3 mb-3" (click)="submit()">Submit</button>
            }
            <button type="button" class="btn btn-secondary px-3 mr-2" (click)="cancel()">Cancel</button>
        </div>
    </div>
}