export namespace AdminAction {
    export class GetAllLicenses {
        static readonly type = "[Admin] Get All Licenses";
        constructor(public status: string) { }
    }

    export class GetLicense {
        static readonly type = "[Admin] Get One Licenses";
        constructor() { }
    }

    export class GetOrganization {
        static readonly type = "[Admin] Get Organization";
        constructor() { }
    }

    export class GetLicenseUsers {
        static readonly type = "[Admin] Get License Users";
        constructor() { }
    }
}