import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { url } from '../../environments/url';
import { License } from '../model/license.model';

@Injectable({
  providedIn: 'root'
})
export class LicenseService {

  constructor(
    private http: HttpClient,
  ) { }

  // Gets all licenses purchased by the logged-in user, returns all issued licenses if the user is a superuser
  getAllLicenses() {
    return this.http.get<License[]>(
      `${environment.backend}${url.license.all}`,
    );
  }

  activateCommnunityLicense() {
    return this.http.post<{ client_secret: string }>(
      environment.backend + url.license.activateCommnunity,
      {},
    );
  }

  generateSecret() {
    return this.http.post<{ client_secret: string }>(
      environment.backend + url.license.generateSecret,
      {}
    );
  }

  downloadDockerCompose(clientId: string) {
    const body = {
      "clientId": clientId,
      "clientSecret": "<CLIENT_SECRET_GOES_HERE>",
    }

    return this.http.post(environment.backend + url.license.downloadDockerCompose, body, {
      responseType: "blob",
    });
  }

  updateRedirect(clientId: string, redirectURI: string) {
    return this.http.post<string>(environment.backend + url.license.updateRedirect, {
      client_id: clientId,
      redirect: redirectURI,
    })
  }

  getLicenseStatus(clientId: string) {
    return this.http.get<License>(
      `${environment.backend}${url.license.status}`,
      {
        params: {
          client_id: clientId,
        }
      }
    );
  }
}
