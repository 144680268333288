import { Theme } from "../model/theme.model";

export namespace ThemeAction {
    export class FetchTheme {
        static readonly type = "[Theme] Fetch theme";
        constructor() { }
    }

    export class UpdateTheme {
        static readonly type = "[Theme] Update theme";
        constructor(public theme: Theme, public clientId: string) { }
    }

    export class SwitchTheme {
        static readonly type = "[Theme] Switch Theme";
        constructor() { }
    }
    
    export class ToogleTheme{
        static readonly type = "[Theme] Toggle Theme"
        constructor(
            // mode can be 'dark' or 'light'
            public mode:string
        ){}
    }

    export class GetBranding {
        static readonly type = "[Theme] Get Branding"
        constructor() {}
    }
}