import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { tap } from "rxjs";
import { UserAction } from "../action/user.action";
import { Status } from "../model/enum";
import { User, UserStateModel } from "../model/user.model";
import { UserService } from "../service/user.service";

@State<UserStateModel>({
    name: 'users',
    defaults: {
        users: [],
        activeUsers: [],
        status: Status.idle
    }
})

@Injectable()
export class UserState {

    constructor(private userService: UserService) { }

    @Selector()
    static getUsers(state: UserStateModel) {
        return state.users;
    }

    @Selector()
    static getActiveUsers(state: UserStateModel) {
        return state.activeUsers;
    }

    @Action(UserAction.GetAllUsers)
    fetchAllUsers(ctx: StateContext<UserStateModel>, action: UserAction.GetAllUsers) {
        ctx.patchState({
            status: Status.loading,
        });

        return this.userService.getAllUsers(action.clienId).pipe(
            tap(response => {
                ctx.patchState({
                    users: response as User[],
                    status: Status.active,
                });
            })
        );
    }

    @Action(UserAction.GetUser)
    fetchUser(ctx: StateContext<UserStateModel>, action: UserAction.GetUser) {
        ctx.patchState({
            status: Status.loading,
        });
        const state = ctx.getState();
        const user = state.activeUsers.find(f => f.email === action.email);
        if (!user) {
            return this.userService.getUser(action.email).pipe(
                tap(response => {
                    const state = ctx.getState();
                    ctx.setState({
                        ...state,
                        activeUsers: [...state.activeUsers, response as User],
                        status: Status.active,
                    })
                })
            );
        } else {
            return;
        }
    }
}