import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { first, Subject, takeUntil } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AuthAction } from '../../../action/auth.action';
import { AuthService } from '../../../service/auth.service';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit, OnDestroy {

  private destroy: Subject<boolean> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private store: Store,
  ) { }

  ngOnInit(): void {

    const loginUri = `${environment.auth.url}?client_id=${environment.auth.client_id}&redirect_uri=${environment.auth.redirectUri}`;

    if (this.authService.loggedIn) {
      this.router.navigate(['dashboard'], {
        queryParams: {
          'section': 'license',
        },
      });
      return;
    }

    this.route.queryParams.pipe(takeUntil(this.destroy)).subscribe(params => {
      const code: string = params['code'] ?? "";
      const state: string = params['state'] ?? "";

      if (!code.trim() || !state.trim()) {
        window.location.href = loginUri;
      } else {
        this.authService.getToken(code, state).pipe(first()).subscribe(
          response => {
            let section = 'license';
            if (response.is_su) section = 'admin';

            this.authService.state = response;

            this.store.dispatch(new AuthAction.Set(response));

            this.router.navigate(['dashboard'], {
              queryParams: {
                section: section,
              },
            });
          },
          _ => {
            window.location.href = loginUri;
          }
        );
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }
}
