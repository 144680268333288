export namespace UserAction {

    export class GetAllUsers {
        static readonly type = "[User] Get All Users";
        constructor(public clienId: string = "") { }
    }

    export class GetUser {
        static readonly type = "[User] Get Single User";
        constructor(public email: string) { }
    }

    export class AddUser {
        static readonly type = "[User] Add User";
        constructor(public payload: any) { }
    }

    export class UpdateUser {
        static readonly type = "[User] Update User";
        constructor(public payload: any) { }
    }

    export class ActivateUser {
        static readonly type = "[User] Activate User";
        constructor(public email: string) { }
    }

    export class DeactivateUser {
        static readonly type = "[User] Deactivate User";
        constructor(public email: string) { }
    }

    export class DeleteUser {
        static readonly type = "[User] Delete User";
        constructor(public payload: any) { }
    }

    export class InviteUser {
        static readonly type = "[User] Invite User";
        constructor(public payload: any) { }
    }

    export class UpdateUserProfile {
        static readonly type = "[User] Update User Profile";
        constructor(public payload: any) { }
    }

    export class ChangePassword {
        static readonly type = "[User] Change Password";
        constructor(public payload: any) { }
    }
}