import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { url } from '../../environments/url';
import { License } from '../model/license.model';
import { Organization } from '../model/organization.model';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(
    private http: HttpClient,
  ) { }

  getAllLicenses(status: string): Observable<License[]> {
    return this.http.get<License[]>(
      `${environment.backend}${url.admin.licenses}`,
      {
        params: {
          status: status,
        }
      }
    );
  }

  getOrganization(): Observable<Organization> {
    return this.http.get<Organization>(`${environment.backend}${url.admin.org}`);
  }

  getUsers(): Observable<{ name: string; email: string; status: string; }> {
    return this.http.get<{ name: string; email: string; status: string; }>(`${environment.backend}${url.admin.users}`);
  }
}
