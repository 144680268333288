<div class="flex flex-col p-3">
    <label class="mb-1">Organization</label>
    <h6 class="text-base mb-1">{{activeLicense?.name}}</h6>
    <button (click)="this.showMenu = !this.showMenu"
        class="btn btn-sm btn-secondary text-btnPrimary py-1 px-0 flex items-center">
        <span>Switch Organization</span>
    </button>
</div>
@if (showMenu) {
<div
    class="grid grid-cols-1 gap-2 ring-1 ring-borderColor ml-2 rounded shadow-md transition-all min-w-72 absolute bg-bgDefault py-3">
    <h6 class="px-3 text-sm mb-0">Organizations</h6>
    @for (license of licenses; track $index) {
    <div [ngClass]="{'bg-bgHover': license.clientId === this.activeLicense?.clientId}"
        class="flex items-center px-3 py-2 transition-all cursor-pointer hover:px-5 hover:bg-bgHover"
        (click)="switchLicense(license.clientId)">
        <mat-icon class="mr-1 hover:text-buttonAlternate">corporate_fare</mat-icon>
        <p class="text-sm hover:font-medium">
            {{license.org}}
        </p>
    </div>
    }
</div>
}