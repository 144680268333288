import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { url } from '../../environments/url';



@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private httpClient: HttpClient) { }

  getAllUsers(clientId?: string) {
    return this.httpClient.get(
      environment.backend + url.adminUser.getAllUser,
      {
        params: {
          client_id: clientId ?? "",
        }
      }
    );
  }

  getUser(email: string) {
    return this.httpClient.get(
      environment.backend + url.adminUser.getUser + email,
    );
  }

  addUsers(payload: any) {
    return this.httpClient.post(
      environment.backend + url.adminUser.addUser,
      payload,
      {
        responseType: 'text',
      }
    );
  }

  updateUsers(payload: any) {
    return this.httpClient.post(
      environment.backend + url.adminUser.updateUser,
      payload,
      {
        responseType: 'text',
      }
    );
  }

  activateUser(email: string) {
    return this.httpClient.post(
      environment.backend + url.adminUser.activateUser,
      email, { responseType: 'text', }
    );
  }
  deactivateUser(email: string) {
    return this.httpClient.post(
      environment.backend + url.adminUser.deactivateUser,
      email, { responseType: 'text', }
    );
  }

  deleteUsers(body: any) {
    return this.httpClient.delete(
      environment.backend + url.adminUser.deleteUser,
      {
        body: body,
        responseType: 'text',

      }
    );
  }

  inviteUser(payload: any) {
    return this.httpClient.post(
      environment.backend + url.adminUser.inviteUser,
      payload,
      {
        responseType: 'text',
      }
    );
  }

  updateUserProfile(payload: any) {
    return this.httpClient.post(
      environment.backend + url.adminUser.updateUserProfile,
      payload,
      {
        responseType: 'text',
      }
    );
  }

  changePassword(payload: any) {
    return this.httpClient.post(
      environment.backend + url.adminUser.changePassword,
      payload,
      {
        responseType: 'text',
      }
    );
  }

  getRoles() {
    return this.httpClient.get<{ id: string; name: string; is_admin: boolean; }[]>(
      environment.backend + url.adminUser.getAllRole,
    );
  }
}
