import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest, HttpStatusCode } from "@angular/common/http";
import { inject } from "@angular/core";
import { catchError, Observable } from "rxjs";
import { AuthService } from "../service/auth.service";

export function authInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {

    const authService = inject(AuthService);
    const state = authService.state;

    if (state.access_token) {
        const cloned = req.clone({
            headers: req.headers.append(
                'Authorization', `Bearer ${state.access_token}`,
            ).append('X-Request-Source', 'license'),
        });

        return next(cloned).pipe(
            catchError((err: any) => {
                if (err instanceof HttpErrorResponse && (err.status == HttpStatusCode.Unauthorized || err.status == HttpStatusCode.Forbidden)) {
                    authService.logOut();
                }

                throw err;
            }),
        );
    } else {
        const cloned = req.clone({
            headers: req.headers.append('X-Request-Source', 'license'),
        });

        return next(cloned).pipe(
            catchError((err: any) => {
                if (err instanceof HttpErrorResponse && (err.status == HttpStatusCode.Unauthorized || err.status == HttpStatusCode.Forbidden)) {
                    authService.logOut();
                }

                throw err;
            }),
        );
    }
}