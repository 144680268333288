import { AuthResponse, Register as RegisterModel } from '../model/auth.model';
import { Status } from '../model/enum';

export namespace AuthAction {

    export class Get {
        static readonly type = "[Auth] Get Auth State";
        constructor() { }
    }

    export class Set {
        static readonly type = "[Auth] Set Auth State";
        constructor(public payload: AuthResponse) { }
    }

    export class Register {
        static readonly type = "[Auth] Register";
        constructor(public payload: RegisterModel) { }
    }

    export class Invalidate {
        static readonly type = "[Auth] Invalidate Token";
        constructor(public token: string) { }
    }

    export class VerifyPasswordToken {
        static readonly type = "[Auth] Verify Password Token";
        constructor(public token: string) { }
    }

    export class SetPassword {
        static readonly type = "[Auth] Set Password";
        constructor(public token: string, public password: string) { }
    }

    export class ForgotPassword {
        static readonly type = "[Auth] Forgot Password";
        constructor(public email: string) { }
    }

    export class SetStatus {
        static readonly type = "[Auth] Set Status";
        constructor(public status: Status) { }
    }
}