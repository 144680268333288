import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState
} from '@angular/cdk/layout';
import { CommonModule, TitleCasePipe, UpperCasePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyListModule as MatListModule } from "@angular/material/legacy-list";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { MatLegacyTooltipModule as MatTooltipModule } from "@angular/material/legacy-tooltip";
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import {
  ActivatedRoute,
  Router,
  RouterModule
} from '@angular/router';
import { Store } from '@ngxs/store';
import { LicenseAction } from '../../action/license.action';
import { AuthResponse } from '../../model/auth.model';
import { AuthService } from '../../service/auth.service';
import { ThemeService } from '../../service/theme.service';
import { UserService } from '../../service/user.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  standalone: true,
  imports: [
    MatSidenavModule,
    RouterModule,
    MatIconModule,
    TitleCasePipe,
    MatProgressBarModule,
    UpperCasePipe,
    MatMenuModule,
    MatTooltipModule,
    MatListModule,
    CommonModule
  ]
})

export class NavComponent implements OnInit, OnDestroy {
  showNotificationSidebar = false;
  pageTitle!: string;
  sidebarMode: string = 'side';
  workspaceId: string = "";

  organizationName!: string;
  totalQuota!: number;
  consumedQuota!: number;
  backToAppBuilder = false;
  backToGroups = false;
  appType!: string;
  isDarkTheme: boolean = false;
  appIcon!: void;

  activePage: string = 'dashboard';

  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    public userService: UserService,
    private themeService: ThemeService,
    private store: Store,
  ) { }

  get user(): AuthResponse {
    return this.authService.state;
  }

  ngOnDestroy(): void {

  }

  ngOnInit(): void {
    this.pageTitle = 'Apps Dashboard';
    this.appIcon = this.themeService.appIcon;
    this.isDarkTheme = localStorage.getItem('theme') == 'dark' ? true : false;

    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge,
    ]).subscribe((state: BreakpointState) => {
      if (
        state.breakpoints[Breakpoints.Large] ||
        state.breakpoints[Breakpoints.XLarge]
      ) {
        this.sidebarMode = 'side';
      } else if (state.breakpoints[Breakpoints.Medium]) {
        this.sidebarMode = 'side';
      } else {
        this.sidebarMode = 'side';
      }
    });

    const url = this.router.url;

    if (!url.endsWith('/dashboard') && !url.includes('/dashboard?')) {
      this.activePage = 'dashboard';
    }

    this.router.events.subscribe(() => {
      this.activePage = this.route.firstChild!.routeConfig!.path === '' ? 'dashboard' : '';
    });

  }

  toggleDarkTheme(event: any) {
    if (event == true) {
      this.isDarkTheme = true;
      this.themeService.isDarkMode = true;
      this.themeService.toggleTheme('dark');
      this.authService.updateSettings({
        email: this.user.email,
        theme: 'dark'
      }).subscribe(data => { })
    } else {
      this.isDarkTheme = false;
      this.themeService.isDarkMode = false;
      this.themeService.toggleTheme('light');
      this.authService.updateSettings({
        email: this.user.email,
        theme: 'light'
      }).subscribe(data => { })
    }
  }

  logout() {
    this.authService.logOut();
  }

  round(value: number): number {
    return Math.round(value);
  }

  resetLicenseState() {
    this.store.dispatch(new LicenseAction.ResetActiveLicenseIndex());
  }
}
