
export namespace EnvironmentAction {
    export class GetEnvironments {
        static readonly type = "[Environment] Get Environments";
        constructor(public clientId: string) { }
    }

    export class GetEnvironmentDetails {
        static readonly type = "[Environment] Get Environment Details";
        constructor(public clientId: string, public id: string) { }
    }

    export class CreateEnvironment {
        static readonly type = "[Environment] Create Environment";
        constructor(public clientId: string, public env: { name: string, description: string, location: string, type: string }) { }
    }

    export class PurchaseEnvironment {
        static readonly type = "[Environment] Purchase Environment";
        constructor(public clientId: string, public id: string) { }
    }

    export class ShutdownEnvironment {
        static readonly type = "[Environment] Shutdown Environment";
        constructor(public clientId: string, public id: string) { }
    }

    export class RestartEnvironment {
        static readonly type = "[Environment] Restart Environment";
        constructor(public clientId: string, public id: string) { }
    }

    export class DeleteEnvironment {
        static readonly type = "[Environment] Delete Environment";
        constructor(public clientId: string, public id: string) { }
    }

    export class RestoreEnvironment {
        static readonly type = "[Environment] Restore Environment";
        constructor(public clientId: string, public id: string) { }
    }

    export class ActivateEnvironment {
        public static readonly type = "[Environment] Activate Environment";
        constructor(public clientId: string, public id: string) { }
    }

    export class GetLocations {
        static readonly type = "[Environment] Get Locations";
        constructor() { }
    }

    export class AddEnvironmentAccess {
        static readonly type = "[Environment] Add Environment Access";
        constructor(public clientId: string, public envId: string, public userId: string) { }
    }

    export class DeleteEnvironmentAccess {
        static readonly type = "[Environment] Delete Environment Access";
        constructor(public clientId: string, public envId: string, public userId: string) { }
    }
}