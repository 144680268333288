import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { NgSelectModule } from '@ng-select/ng-select';
import { Select, Store } from '@ngxs/store';
import { UiSwitchModule } from 'ngx-ui-switch';
import { Observable, Subject, takeUntil } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { BillingAction } from '../../../../action/billing.action';
import { LicenseAction } from '../../../../action/license.action';
import { Plan, PlanPricing } from '../../../../model/billing/plan.model';
import { Status } from '../../../../model/enum';
import { BillingState } from '../../../../state/billing.state';
import { OrgState } from '../../../../state/org.state';
import { PaymentDialogComponent } from '../../../nav/billing/dialog-payment/payment.dialog';
import { MaterialModule } from '../../material.module';
import { DialogComponent, RequestDialogComponent } from '../dialog.component';

@Component({
  selector: 'app-pricing',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    UiSwitchModule,
    NgSelectModule,
  ],
  templateUrl: './pricing.component.html',
  styleUrl: './pricing.component.scss',
  host: {
    '[class.mx-6]': 'public',
  }
})
export class PricingComponent implements OnInit {

  private destroy: Subject<boolean> = new Subject();

  @Input() clientId: string = '';
  @Input() showFree: boolean = false;
  @Input() showCreator: boolean = true;

  @Input() public: boolean = true;

  @Select(BillingState.getPlans) private plans$!: Observable<Plan[]>;
  @Select(BillingState.getStatus) private plansStatus$!: Observable<Status>;

  private billingPlans: Plan[] = [];

  cycle: string = 'year';

  price: number = 0;
  totalPrice: number = 0;
  trialDays: number = 0;
  seats: number = 1;

  planStatus: Status = Status.idle;

  Status = Status;

  registerUrl: string = `${environment.host}/auth/register`

  constructor(
    private store: Store,
    private dialog: MatDialog,
  ) { }

  get communityPlan() {
    return this.billingPlans.find(p => p.name === 'community');
  }

  get plans() {

    if (!this.showCreator) return this.billingPlans.filter(p => p.name !== 'creator');

    return this.billingPlans;
  }

  ngOnInit(): void {

    this.store.dispatch(new BillingAction.GetPlans('month'));

    this.plans$.pipe(takeUntil(this.destroy)).subscribe(
      response => {

        const plan = response.find(plan => plan.name === 'pro');

        if (this.cycle === 'month') {
          this.price = plan?.pricing[0].price_month ?? 0;
        } else {
          this.price = plan?.pricing[0].price_year ?? 0;
        }

        this.trialDays = plan?.pricing[0].trial_days ?? 0;
        this.seats = plan?.pricing[0].max_seats ?? 1;

        this.billingPlans = response;
        this.totalPrice = this.price * this.seats;
      },
    );

    this.plansStatus$.pipe(takeUntil(this.destroy)).subscribe(response => {
      this.planStatus = response;
    });
  }

  getPlans() {
    this.store.dispatch(new BillingAction.GetPlans(this.cycle));
  }

  changeCycle(annual: boolean) {

    const plan = this.billingPlans.find(plan => plan.name === 'pro');

    if (annual) {
      this.cycle = 'year';
      this.price = plan?.pricing[0].price_year ?? 0;
    } else {
      this.cycle = 'month';
      this.price = plan?.pricing[0].price_month ?? 0;
    }

    this.trialDays = plan?.pricing[0].trial_days ?? 0;
    this.seats = plan?.pricing[0].max_seats ?? 1;

    this.totalPrice = this.price * this.seats;
  }

  activate() {

    const dialog = this.dialog.open(DialogComponent, {
      maxWidth: 650,
      minWidth: 400,
      data: {
        title: 'Confirm Activation',
        message: "You are about to activate your license. After succesful activation you will be able to view the 'Client ID' and 'Client Secret'.\n\nThe generated client secret will only be visible for a limited time and will be lost upon page refresh, so make sure you note it down.",
        actionLabel: 'I understand, activate',
      }
    });

    dialog.afterClosed().subscribe(response => {
      if (response === true) {
        this.store.dispatch(new LicenseAction.ActivateLicense(this.clientId));
      }
    });
  }

  subscribe(planId: string, edition: string, currency: string, pricing: PlanPricing[]): void {
    const data = {
      plan: planId,
      clientId: this.clientId,
      edition: edition,
      currency: currency,
      cycle: this.cycle,
      pricing: pricing,
      seats: 0,
      totalPrice: this.totalPrice,
      organization_id: '',
      organization: '',
      domain: '',
      mode: 'pay',
      custom: false,
    };

    if (this.seats === 0) {
      data.seats = 30;
      data.custom = true;
    } else {
      data.seats = parseInt(this.seats.toString(), 10);
    }

    if (this.clientId !== '') {
      const org = this.store.selectSnapshot(OrgState.getName);
      const orgId = this.store.selectSnapshot(OrgState.getId);
      const domain = this.store.selectSnapshot(OrgState.getDomain);

      data.domain = domain ?? '';
      data.organization = org;
      data.organization_id = orgId;
    }

    this.dialog.open(
      PaymentDialogComponent,
      {
        minWidth: '60vw',
        maxWidth: '70vw',
        data: data,
      }
    );
  }

  updatePrice(value: string, pricing: PlanPricing[]) {

    const parsed: number = parseInt(value);

    for (let p of pricing) {
      if ((parsed === 0 && p.max_seats === null) || parsed === p.max_seats) {
        this.price = this.cycle === 'month' ? p.price_month : p.price_year;

        if (p.max_seats === null) {

        } else {
          this.totalPrice = this.price * p.max_seats;
          this.trialDays = p.trial_days;
        }
      }
    }
  }

  createRequest(type: string) {
    this.dialog.open(RequestDialogComponent, {
      minWidth: 450,
      maxWidth: 650,
      data: {
        mode: 'create',
        type: type,
        id: '',
      },
    });
  }
}
