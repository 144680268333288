import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Store } from '@ngxs/store';
import { RequestActions } from '../../../action/request.action';
import { MaterialModule } from '../material.module';

@Component({
  selector: 'app-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MaterialModule,
  ],
  templateUrl: './dialog.component.html',
  styleUrl: './dialog.component.scss'
})
export class DialogComponent {

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      title: string,
      message: string,
      actionLabel?: string,
      isDestructive: boolean,
    },
  ) { }

  confirmed() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }
}

@Component({
  selector: 'app-request-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
  ],
  templateUrl: './request/request.dialog.html',
  styleUrl: './request/request.dialog.scss'
})
export class RequestDialogComponent implements OnInit {

  requestFormGroup = new FormGroup({
    type: new FormControl<string>('', Validators.required),
    description: new FormControl<string>('', Validators.required),
  });

  types: { title: string, value: string }[] = [
    { title: 'Upgrade', value: 'upgrade' },
    { title: 'Deactivate', value: 'deactivate' },
    { title: 'Support', value: 'support' },
  ];

  constructor(
    private store: Store,
    private dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      mode: string,
      type: string,
      description: string,
      id: string,
    },
  ) { }

  ngOnInit(): void {
    if (this.data.type) {
      this.requestFormGroup.get('type')?.setValue(this.data.type);
    }

    if (this.data.description) {
      this.requestFormGroup.get('description')?.setValue(this.data.description);
    }
  }

  submit() {
    if (this.requestFormGroup.valid) {
      const payload = this.requestFormGroup.value;

      if (this.data.mode === 'create') {
        this.store.dispatch(
          new RequestActions.CreateRequest(payload.type ?? '', payload.description ?? ''),
        );
      } else if (this.data.mode === 'edit' && this.data.id !== '') {
        this.store.dispatch(
          new RequestActions.UpdateRequest(this.data.id, payload.type ?? '', payload.description ?? ''),
        );
      }

      this.dialogRef.close();
    }
  }

  cancel() {
    this.dialogRef.close();
  }
}