export enum Status {
    active = 'active',
    inactive = 'inactive',
    deleted = 'deleted',
    connecting = 'connecting',
    success = 'success',
    error = 'error',
    loading = 'loading',
    idle = 'idle',
    cancelled = 'cancelled',
    conflict  = 'conflict'
}