import { CheckoutPayload } from "../model/billing/billing.model";

export namespace BillingAction {

    // Plan Actions
    export class GetPlans {
        static readonly type = "[Billing] Get Billing Plans";
        constructor(public cycle: string) { }
    }

    // Checkout Actions
    export class CreateCheckoutSession {
        static readonly type = "[Billing] Create Checkout Session";
        constructor(public payload: CheckoutPayload) { }
    }

    // Transaction Actions
    export class GetTransactions {
        static readonly type = "[Billing] Get Transactions";
        constructor(public clientId: string) { }
    }

    // Subscription Actions
    export class GetSubscription {
        static readonly type = "[Billing] Get Subscription";
        constructor(public clientId: string) { }
    }

    export class GetCosts {
        static readonly type = "[Billing] Get Costs";
        constructor(public clientId: string) { }
    }

    export class GetPaymenMethods {
        static readonly type = "[Billing] Get Payment Methods";
        constructor(public clientId: string, public userId: string) { }
    }

    export class CancelSubscription {
        static readonly type = "[Billing] Cancel Subscription";
        constructor(public clientId: string) { }
    }

    export class RestoreSubscription {
        static readonly type = "[Billing] Restore Subscription";
        constructor(public clientId: string) { }
    }

    export class UpdateSubscription {
        static readonly type = "[Billing] Update Subscription";
        constructor(public clientId: string, public units: number) { }
    }

    export class SwitchBillingCycle {
        static readonly type = "[Billing] Switch Billing Cycle";
        constructor(public clientId: string) { }
    }
}