import { BreakpointObserver } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { Select, Store } from '@ngxs/store';
import { first, Observable, Subject, switchMap, take, takeUntil, timer } from 'rxjs';
import { EnvironmentAction } from '../../../action/environment.action';
import { Status } from '../../../model/enum';
import { Environment, EnvListItem, Location } from '../../../model/envrionment.model';
import { License } from '../../../model/license.model';
import { EnvironmentService } from '../../../service/environment.service';
import { EnvironmentState } from '../../../state/environment.state';
import { LicenseState } from '../../../state/license.state';
import { HeaderComponent } from '../../header/header.component';
import { DialogComponent, RequestDialogComponent } from '../../utils/dialog/dialog.component';
import { MaterialModule } from '../../utils/material.module';
import { SwitcherComponent } from '../../utils/switcher/switcher.component';
import { EnvIamComponent } from './env-iam/env-iam.component';

@Component({
  selector: 'app-environment',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    MaterialModule,
    NgSelectModule,
    HeaderComponent,
    SwitcherComponent,
    EnvIamComponent,
  ],
  templateUrl: './environment.component.html',
  styleUrl: './environment.component.scss'
})
export class EnvironmentComponent {

  // Whether to show or hide the secondary sidenav
  isSidenavVisible: boolean = true;

  activeMenuIndex: number = 0;

  pageTitle: string = 'Environments';

  mode: 'list' | 'view' | 'create' = 'list';

  displayedColumns: string[] = ['name', 'type', 'location', 'status', 'created_at', 'actions'];
  envDataSource: EnvListItem[] = [];

  activeEnvironment?: Environment;

  tiers = [
    { key: 'xs', label: 'Environment XS', price: 25, currency: 'USD', selected: true, },
    { key: 's', label: 'Environment S', price: 50, currency: 'USD', selected: false, },
    { key: 'm', label: 'Environment M', price: 75, currency: 'USD', selected: false, },
    { key: 'l', label: 'Environment L', price: 100, currency: 'USD', selected: false, },
    { key: 'xl', label: 'Environment XL', price: 200, currency: 'USD', selected: false, },
  ];

  locations: Location[] = [];

  status: Status = Status.idle;

  Status = Status;

  environmentCreationForm = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.maxLength(255)]),
    description: new FormControl('', Validators.maxLength(500)),
    location: new FormControl('', [Validators.required, Validators.maxLength(25)]),
    type: new FormControl('xs', [Validators.required, Validators.maxLength(2)]),
    consent: new FormControl(false, [Validators.required]),
  });

  environmentSpecs: {
    'cpu'?: string,
    'memory'?: string,
    'storage'?: string,
    'status': 'idle' | 'loading' | 'active' | 'error',
  } = {
      status: 'loading',
    };

  private clientId: string = '';
  private destroy$: Subject<boolean> = new Subject();

  @Select(EnvironmentState.getStatus) private status$!: Observable<Status>;
  @Select(EnvironmentState.getEnvironments) private environments$!: Observable<EnvListItem[]>;
  @Select(EnvironmentState.getActiveEnvironment) private activeEnvironment$!: Observable<Environment>;
  @Select(EnvironmentState.getLocations) private locations$!: Observable<Location[]>;

  @Select(LicenseState.getActiveLicense) private activeLicense$!: Observable<License>;

  constructor(
    private observer: BreakpointObserver,
    private route: ActivatedRoute,
    private store: Store,
    private dialog: MatDialog,
    private environmentService: EnvironmentService,
  ) { }

  get selected() {
    return this.tiers.find(t => t.selected === true);
  }

  get location() {
    return this.environmentCreationForm.get('location')?.value;
  }

  ngOnInit(): void {

    this.store.dispatch(new EnvironmentAction.GetLocations());

    this.observer.observe(['(max-width: 850px)']).subscribe((state) => {
      if (state.matches) {
        this.isSidenavVisible = false;
      } else {
        this.isSidenavVisible = true;
      }
    });

    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe(params => {
      const section = (params['section'] ?? 'overview').toLowerCase();

      switch (section) {
        case 'overview':
          this.pageTitle = 'Environments';
          this.activeMenuIndex = 0;
          break;
        case 'settings':
          this.pageTitle = 'Settings';
          this.activeMenuIndex = 1;
          break;
      }

      if (params['session_id']) {
        const clientId = params['client_id'];
        const env_id = params['env_id'];

        const dialogRef = this.dialog.open(
          EnvironmentStatusComponent,
          {
            minWidth: '40vw',
            maxWidth: '40vw',
            data: {
              envId: env_id,
              clientId: clientId,
            },
            disableClose: true,
            position: {
              top: '10vh',
            },
          }
        );

        dialogRef.afterClosed().subscribe((response: { checkout: boolean }) => {
          if (response && response.checkout) {
            this.store.dispatch(new EnvironmentAction.GetEnvironments(clientId));
          }
        });
      }
    });

    this.activeLicense$.pipe(takeUntil(this.destroy$)).subscribe(
      response => {
        this.clientId = response?.client_id;

        if (this.clientId) {
          this.store.dispatch(new EnvironmentAction.GetEnvironments(this.clientId));
        } else {
          this.status = Status.error;
        }
      },
    );

    this.status$.pipe(takeUntil(this.destroy$)).subscribe(response => {
      if (response !== Status.idle) {
        this.status = response;
      }
    });

    this.environments$.pipe(takeUntil(this.destroy$)).subscribe(response => {
      this.envDataSource = response;
    });

    this.activeEnvironment$.pipe(takeUntil(this.destroy$)).subscribe(response => {
      this.activeEnvironment = response;

      if (this.activeEnvironment?.status === 'active') {
        this.getEnvironmentResources(this.activeEnvironment.type, this.activeEnvironment.location);
      }
    });

    this.locations$.pipe(takeUntil(this.destroy$)).subscribe(
      response => {
        this.locations = response;
      },
    )
  }

  viewEnvironment(id: string) {
    const clientId = this.store.selectSnapshot(LicenseState.getActiveLicense)?.client_id;

    if (clientId) {
      this.store.dispatch(new EnvironmentAction.GetEnvironmentDetails(clientId, id));
      this.mode = 'view';
      this.pageTitle = 'Environment Details';
    } else {
      this.status = Status.error;
    }
  }

  setEnvironmentTier(index: number) {
    for (let i = 0; i < this.tiers.length; i++) {
      this.tiers[i].selected = false;
    }

    this.tiers[index].selected = true;
    this.environmentCreationForm.get('type')?.setValue(
      this.tiers[index].key,
    );

    this.getEnvSpecs();
  }

  createEnvironment() {
    if (this.environmentCreationForm.valid) {

      const payload = this.environmentCreationForm.value;

      this.environmentCreationForm.disable();
      this.store.dispatch(new EnvironmentAction.CreateEnvironment(
        this.clientId,
        {
          name: payload.name!,
          description: payload.description ?? '',
          location: payload.location!,
          type: payload.type!,
        },
      )).subscribe({
        error: () => {
          this.environmentCreationForm.enable();
        },
      });
    }
  }

  purchaseEnvironment() {
    const dialog = this.dialog.open(
      DialogComponent,
      {
        maxWidth: 650,
        disableClose: true,
        data: {
          title: 'Confirm Purchase',
          message: "You will now be redirected to the payments page to complete the purchase. Please do not close or refresh your browser until the purchase is complete.",
          actionLabel: 'I understand, continue',
        }
      }
    );

    dialog.afterClosed().subscribe(response => {
      if (response === true) {
        this.store.dispatch(new EnvironmentAction.PurchaseEnvironment(this.clientId, this.activeEnvironment?.id ?? ''));
      }
    });
  }

  deleteEnvironment() {
    const dialog = this.dialog.open(
      DialogComponent,
      {
        maxWidth: 650,
        disableClose: true,
        data: {
          title: 'Confirm Deletion',
          message: "Deleting this environment will schedule it for deletion. You will still be able to use the environment till your subscription ends, after which your environment will be deleted permanently. \n\n Do you wish to continue?",
          actionLabel: 'I understand, continue',
          isDestructive: true,
        }
      }
    );

    dialog.afterClosed().subscribe(response => {
      if (response === true) {
        this.store.dispatch(new EnvironmentAction.DeleteEnvironment(this.clientId, this.activeEnvironment?.id ?? ''));
      }
    });
  }

  restoreEnvironment() {
    const dialog = this.dialog.open(
      DialogComponent,
      {
        maxWidth: 650,
        disableClose: true,
        data: {
          title: 'Confirm Restore',
          message: "This will restore your environment to it's previous state and resume it's billing. \n\n Do you wish to continue?",
          actionLabel: 'I understand, continue',
        }
      }
    );

    dialog.afterClosed().subscribe(response => {
      if (response === true) {
        this.store.dispatch(new EnvironmentAction.RestoreEnvironment(this.clientId, this.activeEnvironment?.id ?? ''));
      }
    });
  }

  activateEnvironment() {
    const dialog = this.dialog.open(
      DialogComponent,
      {
        maxWidth: 650,
        disableClose: true,
        data: {
          title: 'Confirm Request',
          message: "You are about to retry creating and activating this environment, this will not have an impact on your billing. \n\n Do you wish to continue?",
          actionLabel: 'I understand, continue',
        }
      }
    );

    dialog.afterClosed().pipe(first()).subscribe(response => {
      if (response === true) {
        this.store.dispatch(new EnvironmentAction.ActivateEnvironment(this.clientId, this.activeEnvironment?.id ?? ''));
      }
    });
  }

  getEnvSpecs() {
    const type = this.environmentCreationForm.get('type')?.value;
    const location = this.environmentCreationForm.get('location')?.value;

    if (!type || !location) return;

    this.getEnvironmentResources(type, location);
  }

  getEnvironmentResources(type: string, location: string) {


    if (!type || !location) return;

    this.environmentSpecs.status = 'loading';

    this.environmentService.getEnvironmentSpecs(type, location).pipe(takeUntil(this.destroy$)).subscribe(
      response => {
        this.environmentSpecs = {
          status: 'active',
          cpu: response.cpu,
          memory: response.memory,
          storage: response.storage,
        };
      },
      _ => {
        this.environmentSpecs.status = 'error';
      },
    );
  }

  contactUs() {
    this.dialog.open(RequestDialogComponent, {
      minWidth: 450,
      maxWidth: 650,
      data: {
        mode: 'create',
        type: 'support',
        id: '',
        description: `Please describe your problem in detail -\n\n\n\n\n\nThese details help us resolve your request faster, please do not delete the below section. \n\nLicense- '${this.clientId}\n\nEnvironment- '${this.activeEnvironment?.id}''`,
      },
    });
  }

  back() {
    this.mode = 'list';
    this.pageTitle = 'Environments';

    this.store.dispatch(new EnvironmentAction.GetEnvironments(this.clientId));
  }
}



@Component({
  selector: 'app-env-status',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatProgressSpinnerModule,
  ],
  template: `
  @if(!this.envStatus || this.envStatus === 'pending') {
    <div class="p-6 flex items-center">
        <mat-spinner [diameter]="15" class="mr-2"></mat-spinner>
        <p class="text-sm">Please wait...</p>
    </div>
  }  @else if(this.envStatus === 'deleted') {
      <div class="p-6 flex flex-col items-start">
          <div class="flex items-center mb-3">
              <mat-icon class="mr-2 text-btnDestructive">error</mat-icon>
              <h6 class="text-base">Error</h6>
          </div>
          <p class="text-sm mb-5">This environment has already been deleted</p>
          <div class="w-full flex justify-end">
              <button class="btn btn-sm border text-btnPrimary" (click)="success()">Close</button>
          </div>
      </div>
    } @else if(this.envStatus === 'activating') {
      <div class="p-6 flex flex-col items-start">
          <div class="flex items-center mb-3">
              <mat-icon class="mr-2 text-bgSuccess">check_circle</mat-icon>
              <h6 class="text-base">Success</h6>
          </div>
          <p class="text-sm mb-5">You're all set! Your purchase was successful</p>
          <div class="w-full flex justify-end">
              <button class="btn btn-sm border text-btnPrimary" (click)="success()">Close</button>
          </div>
      </div>
    } @else if(this.envStatus === 'error') {
      <div class="p-6 flex flex-col items-start">
          <div class="flex items-center mb-3">
              <mat-icon class="mr-2 text-buttonDangerColor">error</mat-icon>
              <h6 class="text-base">Error</h6>
          </div>
          <p class="text-sm mb-5">Something went wrong. Please try again in some time, or raise a support request through the
              <span class="font-medium">Requests</span> section
          </p>
          <div class="w-full flex justify-end">
              <button class="btn btn-sm border px-5 text-btnPrimary" (click)="cancel()">Close</button>
          </div>
      </div>
    }
  `,
  styleUrl: './environment.component.scss'
})
export class EnvironmentStatusComponent implements OnInit {

  envStatus: string = '';

  constructor(
    public dialogRef: MatDialogRef<EnvironmentStatusComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      clientId: string,
      envId: string,
    },
    private environmentService: EnvironmentService,
  ) { }

  ngOnInit(): void {
    const interval = 3000;
    const clostTimer$ = new Subject<boolean>();

    let complete = false;

    timer(0, interval).pipe(
      switchMap(() => this.environmentService.getEnvironment(this.data.clientId, this.data.envId)),
      takeUntil(clostTimer$),
      take(5),
    ).subscribe({
      next: response => {
        this.envStatus = response.status;

        if (this.envStatus !== 'pending') {
          complete = true;
          clostTimer$.next(true);
          clostTimer$.unsubscribe();
        }
      },
      error: _ => {
        this.envStatus = 'error';
      },
      complete: () => {
        if (!complete) {
          this.envStatus = 'error';
        }
      }
    });

    return;
  }

  cancel() {
    this.dialogRef.close({
      checkout: false,
    });
  }

  success() {
    this.dialogRef.close({
      checkout: true,
    });
  }
}