import { HttpClientModule } from '@angular/common/http';
import { Component, InjectionToken } from '@angular/core';
import { MatIconRegistry } from "@angular/material/icon";
import { RouterOutlet } from '@angular/router';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { AlertComponent } from './component/utils/alert/alert.component';

const MSAL_CONFIG_URL_TOKEN = new InjectionToken<string>('MSAL_CONFIG_URL');

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, HttpClientModule, NgxsLoggerPluginModule, NgxsReduxDevtoolsPluginModule, AlertComponent,],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})

export class AppComponent {
  constructor(
    private library: FaIconLibrary,
    private readonly iconRegistry: MatIconRegistry,
  ) {
    library.addIconPacks(fas, far);

    const defaultFontSetClasses = iconRegistry.getDefaultFontSetClass();
    const outlinedFontSetClasses = defaultFontSetClasses
      .filter((fontSetClass) => fontSetClass !== 'material-icons')
      .concat(['material-symbols-outlined']);

    iconRegistry.setDefaultFontSetClass(...outlinedFontSetClasses);
  }

}


