import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { catchError, tap } from "rxjs";
import { EnvironmentAction } from "../action/environment.action";
import { Status } from "../model/enum";
import { EnvironmentStateModel } from "../model/envrionment.model";
import { AlertService } from "../service/alert.service";
import { EnvironmentService } from "../service/environment.service";

@State<EnvironmentStateModel>({
    name: 'environment',
    defaults: {
        environments: [],
        locations: [],
        status: Status.idle,
    },
})
@Injectable()
export class EnvironmentState {

    constructor(
        private envService: EnvironmentService,
        private alertSerivce: AlertService,
    ) { }

    @Selector()
    static getEnvironments(state: EnvironmentStateModel) {
        return state.environments ?? [];
    }

    @Selector()
    static getStatus(state: EnvironmentStateModel) {
        return state.status;
    }

    @Selector()
    static getActiveEnvironment(state: EnvironmentStateModel) {
        return state.activeEnvironment;
    }

    @Selector()
    static getLocations(state: EnvironmentStateModel) {
        return state.locations ?? [];
    }

    @Action(EnvironmentAction.GetEnvironments)
    getAllEnvironments(ctx: StateContext<EnvironmentStateModel>, action: EnvironmentAction.GetEnvironments) {
        ctx.patchState({
            status: Status.loading,
        });

        return this.envService.getEnvironments(action.clientId).pipe(
            tap(response => {
                ctx.patchState({
                    status: Status.active,
                    environments: response,
                });
            }),
            catchError(error => {
                ctx.patchState({
                    environments: [],
                    status: Status.error,
                })

                return error;
            }),
        )
    }

    @Action(EnvironmentAction.GetEnvironmentDetails)
    getEnvironmentDetails(ctx: StateContext<EnvironmentStateModel>, action: EnvironmentAction.GetEnvironmentDetails) {
        ctx.patchState({
            status: Status.loading,
        });

        return this.envService.getEnvironment(action.clientId, action.id).pipe(
            tap(response => {
                ctx.patchState({
                    status: Status.active,
                    activeEnvironment: response,
                });
            }),
            catchError(error => {
                ctx.patchState({
                    status: Status.error,
                    activeEnvironment: undefined,
                })

                return error;
            }),
        );
    }

    @Action(EnvironmentAction.CreateEnvironment)
    createEnvironment(ctx: StateContext<EnvironmentStateModel>, action: EnvironmentAction.CreateEnvironment) {
        ctx.patchState({
            status: Status.loading,
        });

        return this.envService.createEnvironment(action.clientId, action.env).pipe(
            tap(response => {
                window.location.href = response.url;
            }),
            catchError(error => {
                this.alertSerivce.error('Something went wrong. Your environment might have still been created. Please reload this page and try again.');

                ctx.patchState({
                    status: Status.error,
                });

                throw error;
            })
        );
    }

    @Action(EnvironmentAction.ActivateEnvironment)
    activateEnvironment(ctx: StateContext<EnvironmentStateModel>, action: EnvironmentAction.ActivateEnvironment) {
        ctx.patchState({
            status: Status.loading,
        });

        return this.envService.activateEnvironment(action.id).pipe(
            tap(_ => {
                ctx.dispatch(new EnvironmentAction.GetEnvironmentDetails(action.clientId, action.id));
            }),
            catchError(error => {
                this.alertSerivce.error("Unable to activate the environment. Please try again after some time.");
                ctx.patchState({
                    status: Status.error,
                });
                return error;
            }),
        );
    }

    @Action(EnvironmentAction.PurchaseEnvironment)
    purchaseEnvironment(ctx: StateContext<EnvironmentStateModel>, action: EnvironmentAction.PurchaseEnvironment) {
        ctx.patchState({
            status: Status.loading,
        });

        return this.envService.purchaseEnvironment(action.clientId, action.id).pipe(
            tap(response => {
                window.location.href = response.url;
            }),
            catchError(error => {
                this.alertSerivce.error('Something went wrong. Please reload this page and try again.');

                ctx.patchState({
                    status: Status.error,
                });

                throw error;
            })
        );
    }

    @Action(EnvironmentAction.DeleteEnvironment)
    deleteEnvironment(ctx: StateContext<EnvironmentStateModel>, action: EnvironmentAction.DeleteEnvironment) {
        ctx.patchState({
            status: Status.loading,
        });

        return this.envService.deleteEnvironment(action.clientId, action.id).pipe(
            tap(_ => {
                ctx.dispatch(new EnvironmentAction.GetEnvironmentDetails(action.clientId, action.id));
            }),
            catchError(error => {

                this.alertSerivce.error("Unable to delete the environment. Please try again after some time.");

                return error;
            }),
        );
    }

    @Action(EnvironmentAction.RestoreEnvironment)
    restoreEnvironment(ctx: StateContext<EnvironmentStateModel>, action: EnvironmentAction.RestoreEnvironment) {
        ctx.patchState({
            status: Status.loading,
        });

        return this.envService.restoreEnvironment(action.clientId, action.id).pipe(
            tap(_ => {
                ctx.dispatch(new EnvironmentAction.GetEnvironmentDetails(action.clientId, action.id));
            }),
            catchError(error => {

                this.alertSerivce.error("Unable to restore the environment. Please try again after some time.");

                return error;
            }),
        );
    }

    @Action(EnvironmentAction.ShutdownEnvironment)
    shutdownEnvironment(ctx: StateContext<EnvironmentStateModel>, action: EnvironmentAction.ShutdownEnvironment) {
        ctx.patchState({
            status: Status.loading,
        });

        return this.envService.shutdownEnvironment(action.clientId, action.id).pipe(
            tap(_ => {
                ctx.dispatch(new EnvironmentAction.GetEnvironmentDetails(action.clientId, action.id));
            }),
        );
    }

    @Action(EnvironmentAction.RestartEnvironment)
    restartEnvironment(ctx: StateContext<EnvironmentStateModel>, action: EnvironmentAction.RestartEnvironment) {
        ctx.patchState({
            status: Status.loading,
        });

        return this.envService.restartEnvironment(action.clientId, action.id).pipe(
            tap(_ => {
                ctx.dispatch(new EnvironmentAction.GetEnvironmentDetails(action.clientId, action.id));
            }),
        );
    }

    @Action(EnvironmentAction.GetLocations)
    getLocations(ctx: StateContext<EnvironmentStateModel>, _: EnvironmentAction.GetLocations) {
        return this.envService.getLocations().pipe(tap(
            response => {
                ctx.patchState({
                    locations: response,
                });
            },
        ));
    }

    @Action(EnvironmentAction.AddEnvironmentAccess)
    addEnvironmentAccess(ctx: StateContext<EnvironmentStateModel>, action: EnvironmentAction.AddEnvironmentAccess) {
        return this.envService.addEnvironmentAccess(action.clientId, action.envId, action.userId).pipe(
            tap(_ => {
                this.alertSerivce.success('Success');
                ctx.dispatch(new EnvironmentAction.GetEnvironmentDetails(action.clientId, action.envId));
            }),
            catchError(error => {
                this.alertSerivce.error("Unable to add user to environment. Please try again later.");

                throw error;
            }),
        );
    }


    @Action(EnvironmentAction.DeleteEnvironmentAccess)
    deleteEnvironmentAccess(ctx: StateContext<EnvironmentStateModel>, action: EnvironmentAction.DeleteEnvironmentAccess) {
        return this.envService.removeEnvironmentAccess(action.clientId, action.envId, action.userId).pipe(
            tap(_ => {
                this.alertSerivce.success('Success');
                ctx.dispatch(new EnvironmentAction.GetEnvironmentDetails(action.clientId, action.envId));
            }),
            catchError(error => {
                this.alertSerivce.error("Unable to remove user from environment. Please try again later.");

                throw error;
            }),
        );
    }
}