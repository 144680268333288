@if (this.data.mode === 'check') {
@if (!this.licenseStatus || this.licenseStatus == 'pending') {
<div class="p-6 flex items-center">
    <mat-spinner [diameter]="15" class="mr-2"></mat-spinner>
    <p class="text-sm">Please wait...</p>
</div>
} @else if(this.licenseStatus === 'active') {
<div class="p-6 flex flex-col items-start">
    <div class="flex items-center mb-3">
        <mat-icon class="mr-2 text-bgSuccess">check_circle</mat-icon>
        <h6 class="text-base">Success</h6>
    </div>
    <p class="text-sm mb-5">You're all set! Your purchase was successful</p>
    <div class="w-full flex justify-end">
        <button class="btn btn-sm border text-btnPrimary" (click)="success()">Close</button>
    </div>
</div>
} @else if(this.licenseStatus === 'error') {
<div class="p-6 flex flex-col items-start">
    <div class="flex items-center mb-3">
        <mat-icon class="mr-2 text-buttonDangerColor">error</mat-icon>
        <h6 class="text-base">Error</h6>
    </div>
    <p class="text-sm mb-5">Something went wrong. Please try again in some time, or raise a support request through the
        <span class="font-medium">Requests</span> section
    </p>
    <div class="w-full flex justify-end">
        <button class="btn btn-sm border px-5 text-btnPrimary" (click)="cancel()">Close</button>
    </div>
</div>
}
} @else {
<form class="mb-3" [formGroup]="detailsForm" (ngSubmit)="proceed()" #ngForm="ngForm">
    <section class="grid grid-cols-5">
        <div class="col-span-3 px-8 py-10 border-r">
            <h4 class="text-2xl font-semibold mb-2">We need a few details from you</h4>
            <p class="text-sm mb-8">Please confirm and fill out the details below and click 'Proceed' to continue</p>

            <h6 class="text-lg font-medium mb-3">Personal Details</h6>
            <div class="grid grid-cols-1 md:grid-cols-2 ring-1 rounded-md ring-gray-200 divide-x mb-8">
                <div class="flex flex-col px-5 py-3">
                    <label class="text-xs font-medium mb-2">Name</label>
                    <p>{{this.name}}</p>
                </div>
                <div class="flex flex-col px-5 py-3">
                    <label class="text-xs font-medium mb-2">Email</label>
                    <a class="text-btnPrimary" [href]="'mailto:' + this.email">{{this.email}}</a>
                </div>
            </div>

            <h6 class="text-lg font-medium mb-2">Organization Details</h6>
            <p class="text-sm mb-3">Each pro license is linked to an organization. Please provide the details below</p>
            <div class="mb-2">
                <label for="">Name <span class="text-btnDestructive">*</span></label>
                <input required (input)="this.submitted = false" class="input" type="text" min="1" max="255"
                    placeholder="Enter your organization name" tabindex="1" formControlName="organization" />
                <p *ngIf="this.detailsForm.get('name')?.hasError('required') && ngForm.submitted && this.submitted"
                    class="text-sm text-btnDestructive">Organization name is required</p>
                <div *ngIf="this.orgStatus !== Status.idle" class="flex items-center mb-6">
                    @if (this.orgStatus === Status.active) {
                    <mat-icon inline class="text-bgSuccess mr-1">check_circle</mat-icon>
                    <p class="text-sm">Name is available</p>
                    } @else if(this.orgStatus === Status.inactive) {
                    <mat-icon inline class="text-btnDestructive mr-1">error</mat-icon>
                    <p class="text-sm">Name is already taken</p>
                    } @else if(this.orgStatus === Status.loading) {
                    <mat-spinner [diameter]="15" class="mr-2"></mat-spinner>
                    <p class="text-sm">Searching</p>
                    }
                </div>
            </div>
            <div class="mb-4">
                <label for="">Domain <span class="text-btnDestructive">*</span></label>
                <div class="flex items-center mb-1">
                    <input (input)="this.submitted = false;" (keyup)="searchhDomain($event)" required class="input mr-2"
                        type="text" min="1" max="255" formControlName="domain"
                        placeholder="Choose your personalised PIES Studio domain" tabindex="2" />
                    <p class="text-base font-medium">.pies.studio</p>
                </div>
                <p *ngIf="this.detailsForm.get('domain')?.hasError('required') && ngForm.submitted && this.submitted"
                    class="text-sm text-btnDestructive">Organization domain is required</p>
                <div class="flex items-center mb-6">
                    @if (this.domainStatus === Status.active) {
                    <mat-icon inline class="text-bgSuccess mr-1">check_circle</mat-icon>
                    <p class="text-sm">Domain is available</p>
                    } @else if(this.domainStatus === Status.inactive) {
                    <mat-icon inline class="text-btnDestructive mr-1">error</mat-icon>
                    <p class="text-sm">Domain is already taken</p>
                    } @else if(this.domainStatus === Status.loading) {
                    <mat-spinner [diameter]="15" class="mr-2"></mat-spinner>
                    <p class="text-sm">Searching</p>
                    }
                </div>
            </div>
            <h6 class="text-lg font-medium mb-2">Billing Details</h6>
            <p class="text-sm mb-3">Enter the number of seats that will be in your organization, don't worry this can be
                changed later</p>
            @if (!this.data.custom) {
                <div class="mb-5">
                    <label for="">Team Size<span class="text-btnDestructive">*</span></label>
                    <p class="text-lg font-medium">{{this.seats}}</p>
                </div>
            } @else {
                <div class="flex flex-col mb-5 gap-y-4">
                    <div class="flex flex-col">
                        <label for="">Base Team Size</label>
                        <p class="text-lg font-medium">30</p>
                    </div>
                    <div class="flex flex-col">
                        <label for="">Additional Seats<span class="text-btnDestructive">*</span></label>
                        <input value="0" (input)="addExtraSeats($event)" class="input mb-0" type="number" min="1" placeholder="Enter the number of additional seats required"/>
                    </div>
                </div>
            }
            <div class="mb-4 flex items-start">
                <input (input)="this.submitted = false" type="checkbox" formControlName="consent" required
                    class="mr-2 w-6 h-6 text-btnPrimary bg-bgDefault border-borderColor rounded-sm cursor-pointer mb-0" />
                <label class="text-sm">By proceeding, I agree to the <a href="" class="text-btnPrimary">Terms of
                        Service</a> and
                    <a class="text-btnPrimary">Privacy Policy</a>. I hereby authorize PIES Studio to charge my payment
                    method as per the billing cycle till cancelled.</label>
            </div>
            <p *ngIf="this.detailsForm.get('consent')?.hasError('required') && ngForm.submitted && this.submitted"
                class="text-sm text-btnDestructive">You need to accept
                the terms of use and privacy policy before
                proceeding</p>
        </div>
        <div class="col-span-2 px-8 py-10">
            <h6 class="text-lg font-medium mb-2">Plan Details</h6>
            <p class="text-sm mb-5">Your plan details and pricing are mentioned below.</p>
            <div class="grid grid-cols-1 md:grid-cols-1 ring-1 rounded-md ring-borderColor divide-y mb-4">
                <div class="flex flex-col px-5 py-3">
                    <label class="text-xs font-medium mb-2">Plan</label>
                    <p class="font-medium">PIES Studio {{this.data.edition | titlecase}} Edition</p>
                </div>
                <div class="flex flex-col px-5 py-3">
                    <label class="text-xs font-medium mb-2">Billing Cycle</label>
                    <p class="font-medium">{{data.cycle | titlecase}}ly</p>
                </div>
            </div>
            <div class="mt-8 mb-3 border-b"></div>
            <div class="flex items-center justify-between">
                <h6 class="text-base font-medium">Total</h6>
                <div class="flex items-center">
                    <h6 class="text-2xl font-semibold">{{data.cycle === 'year' ? (this.totalPrice * 12 | currency:
                        data.currency:'symbol':'.0-0' ) : this.totalPrice |
                        currency: data.currency:'symbol':'.0-0'}}</h6>
                    <p class="text-base">/{{data.cycle}}*</p>
                </div>
            </div>
            <div class="my-3 border-b"></div>
            <p class="text-sm mb-2">*All mentioned prices are exclusive of any taxes that may apply.</p>
            <button type="submit"
                class="btn bg-btnPrimary disabled:bg-gray hover:scale-105 text-btnSecondary transition-all ease-in-out duration-300 px-3 my-5 w-full">
                Proceed
            </button>
            <p class="text-sm">
                Need help? Get in touch with us at
                <a class="text-btnPrimary"
                    href="mailto:support@pies.io?subject=Licenese%20Upgrade%20Information">support&#64;pies.io</a>
            </p>
        </div>
    </section>
</form>
}