export namespace LicenseAction {
    export class GetLicenses {
        static readonly type = "[License] Get User Licenses";
        constructor() { }
    }

    export class GetLicenseDetails {
        static readonly type = "[License] Get License Details";
        constructor(public clientId: string) { }
    }

    export class ResetActiveLicenseIndex {
        static readonly type = "[License] Reset Active License";
        constructor() { }
    }

    export class ActivateLicense {
        static readonly type = "[License] Activate License";
        constructor(public clientId: string) { }
    }

    export class GenerateSecret {
        static readonly type = "[License] Generate Secret";
        constructor(public clientId: string) { }
    }

    export class UpdateRedirect {
        static readonly type = "[License] Update Redirect URI";
        constructor(public clientId: string, public redirectUri: string) { }
    }

    export class SetDefaultLicense {
        static readonly type = "[License] Set Default License";
        constructor(public index: number) { }
    }

    export class SetActiveLicense {
        static readonly type = "[License] Set Active License";
        constructor(public clientId: string) { }
    }
}