import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { url } from '../../environments/url';
import { ThemeAction } from '../action/theme.action';
import { Theme } from '../model/theme.model';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  appIcon: any;
  orgLogo: any;
  isDarkMode = false;
  themePallete = {
    primaryColor: {
      dark: "#FFFFFF",
      light: "#FFFFFF",
    },
    secondaryColor: {
      dark: "#FFFFFF",
      light: "#FFFFFF",
    },
    buttonColor: {
      primaryColor: {
        dark: "#000000",
        light: "#000000",
      },
      secondaryColor: {
        dark: "#000000",
        light: "#000000",
      },
      destructiveColor: {
        dark: "#000000",
        light: "#000000",
      },
    },
    buttonFontColor: {
      primaryColor: {
        dark: "#000000",
        light: "#000000",
      },
      secondaryColor: {
        dark: "#000000",
        light: "#000000",
      },
      destructiveColor: {
        dark: "#000000",
        light: "#000000",
      },
    },
    fontColor: {
      primaryColor: {
        dark: "#000000",
        light: "#000000",
      },
      secondaryColor: {
        dark: "#003400",
        light: "#000450",
      },
    }
  }

  constructor(
    private http: HttpClient,
    private store: Store,
    private authService: AuthService,
  ) { }

  hexToRgbA(hex: string, alpha: string) {
    var c: any;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('');
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = '0x' + c.join('');
      return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + alpha + ')';
    }
    return '';
  }

  fetchTheme(): Observable<Theme> {
    return this.http.get<Theme>(
      `${environment.backend}/organization/theme`, {
      params: {
        client_id: environment.auth.client_id,
      }
    });
  }

  toggleTheme(mode: string) {
    if (mode) {
      localStorage.setItem('theme', mode);
      document.documentElement.style.setProperty('--fontColor', this.themePallete['fontColor']['primaryColor'][mode == 'dark' ? 'dark' : 'light']);
      document.documentElement.style.setProperty('--secondaryFontColor', this.themePallete['fontColor']['secondaryColor'][mode == 'dark' ? 'dark' : 'light']);
      //bgcolor
      document.documentElement.style.setProperty('--white', this.themePallete['secondaryColor'][mode == 'dark' ? 'dark' : 'light']);
      //nvacolor
      document.documentElement.style.setProperty('--primaryColor', this.themePallete['primaryColor'][mode == 'dark' ? 'dark' : 'light']);
      //buttonprimary
      document.documentElement.style.setProperty('--secondaryColor', this.themePallete['buttonColor']['primaryColor'][mode == 'dark' ? 'dark' : 'light']);
      document.documentElement.style.setProperty('--buttonFontColor', this.themePallete['buttonFontColor']['primaryColor'][mode == 'dark' ? 'dark' : 'light']);
      //buttonSecondary
      document.documentElement.style.setProperty('--buttonSecondary', this.themePallete['buttonColor']['secondaryColor'][mode == 'dark' ? 'dark' : 'light']);
      document.documentElement.style.setProperty('--buttonSecondaryFontColor', this.themePallete['buttonFontColor']['secondaryColor'][mode == 'dark' ? 'dark' : 'light']);
      //buttonDanger
      document.documentElement.style.setProperty('--buttonDangerColor', this.themePallete['buttonColor']['destructiveColor'][mode == 'dark' ? 'dark' : 'light']);
      document.documentElement.style.setProperty('--buttonDangerFontColor', this.themePallete['buttonFontColor']['destructiveColor'][mode == 'dark' ? 'dark' : 'light']);

      document.documentElement.style.setProperty('--borderColor', this.hexToRgbA(this.themePallete['fontColor']['primaryColor'][mode == 'dark' ? 'dark' : 'light'], '0.1')!);
      document.documentElement.style.setProperty('--navItemActiveColor', this.hexToRgbA(this.themePallete['fontColor']['primaryColor'][mode == 'dark' ? 'dark' : 'light'], '0.5')!);
      document.documentElement.style.setProperty('--activeClassColor', this.hexToRgbA(this.themePallete['buttonColor']['primaryColor'][mode == 'dark' ? 'dark' : 'light'], '0.1')!);
      document.documentElement.style.setProperty('--lowOpacityBackground', this.hexToRgbA(this.themePallete['buttonColor']['primaryColor'][mode == 'dark' ? 'dark' : 'light'], '0.25')!);

      this.store.dispatch(new ThemeAction.ToogleTheme(mode));
    }
  }

  getBranding() {
    return this.http.get(environment.backend + url.organization.getBranding + "?client_id=" + this.authService.state.client_id)
      .subscribe((value: any) => {
        if (localStorage.getItem('theme') != null) {
          this.isDarkMode = localStorage.getItem('theme') == 'dark' ? true : false;
        } else {
          localStorage.setItem('theme', 'light')
        }

        this.themePallete.primaryColor = value["primary_color"]
        this.themePallete.secondaryColor = value["secondary_color"]
        this.themePallete.buttonColor = value["button_color"]
        this.themePallete.buttonFontColor = value["button_font_color"]

        this.themePallete.fontColor = value["font_color"]
        this.orgLogo = value['image_bytes'] ? 'data:image/jpeg;base64,' + value['image_bytes'] : '././assets/images/logo-full.png'
        this.appIcon = value['icon_bytes'] ? 'data:image/jpeg;base64,' + value['icon_bytes'] : '././assets/images/logo-mini.png';
        document.documentElement.style.setProperty('--fontColor', value['font_color']['primaryColor'][this.isDarkMode ? 'dark' : 'light']);
        document.documentElement.style.setProperty('--secondaryFontColor', this.themePallete['fontColor']['secondaryColor'][this.isDarkMode ? 'dark' : 'light']);
        //bgcolor
        document.documentElement.style.setProperty('--white', value['secondary_color'][this.isDarkMode ? 'dark' : 'light']);
        //nvacolor
        document.documentElement.style.setProperty('--primaryColor', value['primary_color'][this.isDarkMode ? 'dark' : 'light']);
        //buttonprimary
        document.documentElement.style.setProperty('--secondaryColor', value['button_color']['primaryColor'][this.isDarkMode ? 'dark' : 'light']);
        document.documentElement.style.setProperty('--buttonFontColor', value['button_font_color']['primaryColor'][this.isDarkMode ? 'dark' : 'light']);
        //buttonSecondary
        document.documentElement.style.setProperty('--buttonSecondary', value['button_color']['secondaryColor'][this.isDarkMode ? 'dark' : 'light']);
        document.documentElement.style.setProperty('--buttonSecondaryFontColor', value['button_font_color']['secondaryColor'][this.isDarkMode ? 'dark' : 'light']);
        //buttonDanger
        document.documentElement.style.setProperty('--buttonDangerColor', value['button_color']['destructiveColor'][this.isDarkMode ? 'dark' : 'light']);
        document.documentElement.style.setProperty('--buttonDangerFontColor', value['button_font_color']['destructiveColor'][this.isDarkMode ? 'dark' : 'light']);

        document.documentElement.style.setProperty('--borderColor', this.hexToRgbA(value['font_color']['primaryColor'][this.isDarkMode ? 'dark' : 'light'], '0.1'));
        document.documentElement.style.setProperty('--navItemActiveColor', this.hexToRgbA(value['font_color']['primaryColor'][this.isDarkMode ? 'dark' : 'light'], '0.5'));
        document.documentElement.style.setProperty('--activeClassColor', this.hexToRgbA(value['button_color']['primaryColor'][this.isDarkMode ? 'dark' : 'light'], '0.1'));
        document.documentElement.style.setProperty('--lowOpacityBackground', this.hexToRgbA(value['button_color']['primaryColor'][this.isDarkMode ? 'dark' : 'light'], '0.15'));

        this.store.dispatch(new ThemeAction.ToogleTheme(this.isDarkMode ? 'dark' : 'light'));
      });
  }
}
