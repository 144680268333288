import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { catchError, tap } from "rxjs";
import { AdminAction } from "../action/admin.action";
import { Status } from "../model/enum";
import { AdminStateModel } from "../model/license.model";
import { AdminService } from "../service/admin.service";


@State<AdminStateModel>({
    name: 'admin',
    defaults: {
        licenses: [],
        activeLicenseIndex: -1,
        status: Status.idle,
    },
})
@Injectable()
export class AdminState {

    constructor(
        private adminService: AdminService,
    ) { }

    @Selector()
    static getStatus(state: AdminStateModel) {
        return state.status;
    }

    @Selector()
    static getLicenses(state: AdminStateModel) {
        return state.licenses;
    }

    @Action(AdminAction.GetAllLicenses)
    getAllLicenses(ctx: StateContext<AdminStateModel>, action: AdminAction.GetAllLicenses) {

        ctx.patchState({
            licenses: [],
            status: Status.loading,
        })

        return this.adminService.getAllLicenses(action.status).pipe(
            tap(response => {
                if (response) {
                    ctx.patchState({
                        licenses: response,
                        status: Status.success,
                    });
                }
            }),
            catchError(error => {

                ctx.patchState({
                    licenses: [],
                    status: Status.error,
                });

                return error;
            }),
        );
    }
}