<p class="text-sm mb-4">A comprehensive overview of your subscription, billing details and payment history</p>
<div class="ring-1 ring-borderColor rounded grid grid-cols-1 divide-y mb-5">
    <div class="grid grid-cols-1 divide-y md:divide-y-0 md:grid-cols-6 md:divide-x">
        <div class="px-6 py-4">
            <label for="subscriptionId">Subscription ID</label>
            <h6 id="subscriptionId" class="text-lg font-medium">{{subscription?.id?.split("-")?.pop() | uppercase }}
            </h6>
        </div>
        <div class="px-6 py-4 col-span-2">
            <label for="subscriptionId">Edition</label>
            <h6 id="subscriptionId" class="text-lg font-medium">{{subscription?.edition}}</h6>
        </div>
        <div class="px-6 py-4">
            <label for="subscriptionId">Next Billing Date</label>
            <h6 id="subscriptionId" class="text-lg font-medium">{{subscription?.billing_date| date}}</h6>
        </div>
        <div class="px-6 py-4">
            <label for="subscriptionId">Billing Cycle</label>
            <h6 id="subscriptionId" class="text-lg font-medium">{{subscription?.cycle === 'year' ? 'Annual' :
                'Monthly'}}</h6>
        </div>
        <div class="px-6 py-4">
            <label for="subscriptionId">Seats</label>
            <h6 id="subscriptionId" class="text-lg font-medium">{{subscription?.units}}</h6>
        </div>
    </div>
    <div class="flex flex-col px-6 py-4">
        <label class="mb-2 text-sm text-fontPrimary">Status</label>
        @switch (subscription?.status) {
        @case ('active') {
        <div class="flex items-center">
            <mat-icon class="mr-2 text-bgSuccess">check_circle</mat-icon>
            <h3 class="text-lg font-medium text-fontPrimary">Your subscription is active</h3>
        </div>
        }
        @case ('pending') {
        <div class="flex items-center">
            <mat-icon class="mr-2 text-bgWarning">error</mat-icon>
            <h3 class="text-lg font-medium text-fontPrimary">Your subscription is pending</h3>
        </div>
        }
        @case ('deleted') {
        <div class="flex items-center">
            <mat-icon class="mr-2 text-btnDestructive">error</mat-icon>
            <h3 class="text-lg font-medium text-fontPrimary">Your subscription is cancelled</h3>
        </div>
        }
        }
    </div>
</div>

<!-- Billing Details Section -->
<!-- <h5 class="text-lg mb-2">Billing Details</h5>
<p class="text-sm mb-4">Details about all your active billable items are mentioned below</p>
<div class="ring-1 ring-borderColor rounded grid grid-cols-1 md:grid-cols-3 divide-y md:divide-y-0 md:divide-x mb-5">
    <div class="flex items-center px-6 py-4">
        <mat-icon class="mr-6 text-bgSuccess">restart_alt</mat-icon>
        <div class="flex flex-col">
            <label for="subscriptionCost" class="mb-2">Base Subscription</label>
            <h6 id="subscriptionCost" class="text-2xl font-medium">{{95 * 5 | currency:'USD':'symbol':'.0-0'}}</h6>
        </div>
    </div>
    <div class="flex items-center px-6 py-4">
        <mat-icon class="mr-6 text-btnPrimary">dns</mat-icon>
        <div class="flex flex-col">
            <label for="environmentCost" class="mb-2">Active Environments</label>
            <h6 id="environmentCost" class="text-2xl font-medium">{{31 * 5 | currency:'USD':'symbol':'.0-0'}}</h6>
        </div>
    </div>
    <div class="flex items-center px-6 py-4">
        <mat-icon class="mr-6">code</mat-icon>
        <div class="flex flex-col">
            <label for="environmentCost" class="mb-2">Code Exports</label>
            <h6 id="environmentCost" class="text-2xl font-medium">{{949 * 5 | currency:'USD':'symbol':'.0-0'}}</h6>
        </div>
    </div>
</div> -->

<!-- Transaction History Section -->
<h5 class="text-lg mb-2">Transaction History</h5>
<p class="text-sm mb-4">Information regarding all your previous transactions can be found below</p>

<section class="flex flex-col">
    <table mat-table [dataSource]="transactionsDataSource" class="table-auto w-full border-borderColor border">

        <!-- Transaction ID -->
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>ID</th>
            <td mat-cell *matCellDef="let row">
                <span class="badge">{{row.id?.split("-").pop() | uppercase }}</span>
            </td>
        </ng-container>

        <!-- Transaction Type -->
        <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef>Type</th>
            <td mat-cell *matCellDef="let row">
                <p class="message-sm">{{row.type | titlecase}}</p>
            </td>
        </ng-container>

        <!-- Transaction Amount -->
        <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef>Amount</th>
            <td mat-cell *matCellDef="let row">
                <p class="message-sm">{{row.amount / 100 | currency:(row.currency ?? 'USD' |
                    uppercase):'symbol':'.0-0'}}</p>
            </td>
        </ng-container>

        <!-- Transaction Status -->
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let row">
                <div class="flex items-center">
                    @if (row.status === 'pending' || row.status === 'open') {
                    <mat-icon class="text-yellow-500 mr-2">info</mat-icon>
                    } @else if (row.status === 'complete') {
                    <mat-icon class="text-bgSuccess mr-2">check_circle</mat-icon>
                    } @else if (row.status === 'closed') {
                    <mat-icon class="text-buttonDangerColor mr-2">error</mat-icon>
                    }
                    <span class="message-sm">{{row.status | titlecase}}</span>
                </div>
            </td>
        </ng-container>

        <!-- Transaction Timestamp -->
        <ng-container matColumnDef="timestamp">
            <th mat-header-cell *matHeaderCellDef>Timestamp</th>
            <td mat-cell *matCellDef="let row">
                <span class="message-sm">{{row.timestamp | date:'medium'}}</span>
            </td>
        </ng-container>

        <!-- Actions Timestamp -->
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let row">
                <button type="button" (click)="raiseRequest(row.id)" class="btn btn-secondary text-btnPrimary">Raise
                    request</button>
            </td>
        </ng-container>

        <!-- Actions -->
        <!-- <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let row">
                <mat-icon class="cursor-pointer text-primary" (click)="editRequest(row.id, row.type, row.description)" matTooltip="Edit Request">edit</mat-icon>
            </td>
        </ng-container> -->

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" [attr.colspan]="displayedColumns.length">
                <p class="p-3">No active transactions found</p>
            </td>
        </tr>
    </table>

    <mat-paginator class="p-4 text-base" [pageSize]="10" aria-label="Select page of requests"></mat-paginator>
</section>