import { Status } from "./enum";

export class Theme {
    primaryColor!: Color;
    secondaryColor!: Color;
    buttonColor!: {
        primaryColor: Color;
        secondaryColor: Color;
        destructiveColor: Color;
    };
    buttonFontColor!: {
        primaryColor: Color;
        secondaryColor: Color;
        destructiveColor: Color;
    };
    fontColor!: {
        primaryColor: Color;
        secondaryColor: Color;
    };
    iconBytes: string = '';
    imageBytes: string = '';
  static ToogleTheme: any;
}

export class ThemeStateModel {
    mode!: ThemeMode;
    theme?: Theme;
    status!: Status;
}

/* --- HELPER CLASSES --- */

export class ThemeData {
    primaryColor!: string;
    secondaryColor!: string;
    buttonColor!: {
        primaryColor: string;
        secondaryColor: string;
        destructiveColor: string;
    };
    buttonFontColor!: {
        primaryColor: string;
        secondaryColor: string;
        destructiveColor: string;
    };
    fontColor!: {
        primaryColor: string;
        secondaryColor: string;
    };
    iconBytes: string = '';
    imageBytes: string = '';
}

export enum ThemeMode {
    dark = 'dark',
    light = 'light',
}

class Color {
    dark: string;
    light: string;

    constructor(dark: string, light: string) {
        this.dark = dark;
        this.light = light;
    }
}