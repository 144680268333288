<div class="p-5">
    @if(!isLoading) {
    <h6 class="text-base mb-3">Add User</h6>
    <p class="message-sm mb-4">Use the form below to add a new user to your organization</p>
    <form [formGroup]="addUserFormGroup" (ngSubmit)="submit()">
        <div class="relative">
            @if (this.searchMode === 'invite') {
            <label for="user" class="mb-1">User/s</label>
            <div class="flex flex-col">
                @if (this.invitedUsers.length > 0) {
                <div class="flex input rounded-b-none border-b-0 gap-x-2">
                    @for (user of invitedUsers; let index=$index; track index) {
                    <div class="flex chip">
                        <p class="me-1">{{user}}</p>
                        <button (click)="removeUser(index)" class="border-l flex items-center mx-2">
                            <mat-icon inline>close</mat-icon>
                        </button>
                    </div>
                    }
                </div>
                }
                <input autocomplete="off" [ngClass]="{'rounded-t-none': this.invitedUsers.length > 0}" #searchInput
                    (input)="searchUsers($event)" id="user" class="input"
                    [placeholder]="'Add email addresses to invite users to your organization'" />
            </div>
            @if (showResults) {
            <div class="ring-1 ring-borderColor bg-bgDefault rounded-b bottom-0 max-h-60">
                <div (click)="inviteUser(searchInput)" class="px-3 py-2 cursor-pointer hover:bg-bgHover">
                    <p class="text-sm">
                        <span>Invite "</span>
                        <span class="font-medium">{{searchInput.value}}</span>
                        <span>"</span>
                    </p>
                </div>
            </div>
            }
            } @else if(this.searchMode === 'view') {
            <label class="mb-1">User</label>
            <div class="p-0">
                <p class="text-base font-medium">{{selectedUser}}</p>
            </div>
            }
        </div>
        <div class="mb-4 mt-2">
            <label for="role" class="mb-1">Role</label>
            <ng-select id="role" formControlName="role" [items]="roles" bindLabel="name" bindValue="id"
                placeholder="Select the role"></ng-select>
        </div>
        @if (this.isNonAdmin) {
        <div class="mb-4">
            <h6 class="text-base mb-1">Purchases</h6>
            <p class="text-sm mb-2">Choose whether the user will have access to make purchases</p>
            <mat-radio-group formControlName="purchase" aria-label="Select if the user has purchase access">
                <mat-radio-button [value]="true">Allowed</mat-radio-button>
                <mat-radio-button [value]="false">Restricted</mat-radio-button>
            </mat-radio-group>
        </div>
        }
        <div class="flex flex-col w-full mt-4">
            <button [disabled]="!this.addUserFormGroup.valid" class="btn btn-primary px-3 mb-3">Submit</button>
            <button type="button" class="btn btn-secondary px-3 mr-2" (click)="cancel()">Cancel</button>
        </div>
    </form>
    } @else {
    <div class="flex items-center" role="status">
        <mat-spinner [diameter]="20" class="mr-2"></mat-spinner>
        <p class="text-sm">Please wait...</p>
    </div>
    }
</div>