import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { Select, Store } from '@ngxs/store';
import { map, Observable, Subject, takeUntil } from 'rxjs';
import { LicenseAction } from '../../../action/license.action';
import { License } from '../../../model/license.model';
import { LicenseState } from '../../../state/license.state';

@Component({
  selector: 'app-switcher',
  standalone: true,
  imports: [
    CommonModule,
    MatMenuModule,
    MatIconModule,
  ],
  templateUrl: './switcher.component.html',
  styleUrl: './switcher.component.scss'
})
export class SwitcherComponent implements OnInit {

  showMenu = false;

  licenses: {
    clientId: string;
    name: string;
    tier: string;
    org: string;
  }[] = [];

  activeLicense?: {
    clientId: string;
    name: string;
    tier: string;
  };

  private destroy$: Subject<boolean> = new Subject();

  @Select(LicenseState.getAllLicenses) private licenses$!: Observable<License[]>;
  @Select(LicenseState.getActiveLicense) private activeLicense$!: Observable<License>;

  constructor(
    private store: Store,
  ) { }

  ngOnInit(): void {
    this.store.dispatch(new LicenseAction.GetLicenses()).pipe(takeUntil(this.destroy$)).subscribe(
      _ => {
        this.store.dispatch(new LicenseAction.SetDefaultLicense(0));
      }
    );

    this.licenses$.pipe(
      takeUntil(this.destroy$),
      map(licenses => {
        return licenses.map(l => ({ clientId: l.client_id, name: l.organization, tier: l.tier, org: l.organization, }));
      }),
    ).subscribe(response => {
      this.licenses = response;
    });

    this.activeLicense$.pipe(
      takeUntil(this.destroy$),
      map(response => {
        if (response) {
          return { clientId: response.client_id, name: response.organization, tier: response.tier, };
        }

        return undefined;
      }),
    ).subscribe(response => {
      this.activeLicense = response;
    });
  }

  switchLicense(clientId: string) {
    this.store.dispatch(new LicenseAction.SetActiveLicense(clientId));
    this.showMenu = false;
  }
}
