@if (title) {
<div
  class="flex items-center justify-between py-[0.65rem] px-6 shadow-none bg-white"
>
  <div class="flex flex-col">
    <h4 class="mb-0 font-medium text-fontColor text-base">{{ title }}</h4>
  </div>
  <!-- <button *ngIf="allowBuild()" class="btn btn-sm build-btn d-flex align-items-center px-2" (click)="build()">
          <mat-icon class="me-1">build_circle</mat-icon>
          <span class="ms-1">Build</span>
        </button> -->
</div>
}
