import { BreakpointObserver, Breakpoints, BreakpointState } from "@angular/cdk/layout";
import { CommonModule } from "@angular/common";
import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";
import { MatDrawer } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { provideAnimations } from '@angular/platform-browser/animations';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { Select, Store } from "@ngxs/store";
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';
import { AuthAction } from "../../../action/auth.action";
import { LicenseAction } from "../../../action/license.action";
import { UserStateModel } from "../../../model/user.model";
import { AlertService } from '../../../service/alert.service';
import { AuthService } from '../../../service/auth.service';
import { UserService } from '../../../service/user.service';
import { AuthState } from "../../../state/auth.state";
import { UserState } from "../../../state/user.state";
import { HeaderComponent } from '../../header/header.component';
import { MaterialModule } from '../../utils/material.module';
import { RequestComponent } from "../request/request.component";
import { AdminComponent } from "./admin/admin.component";
import { LicenseComponent } from './license/license.component';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    RouterModule,
    CommonModule,
    HeaderComponent,
    ReactiveFormsModule,
    NgSelectModule,
    FormsModule,
    MaterialModule,
    LicenseComponent,
    RequestComponent,
    MatSlideToggleModule,
    MatDialogModule,
    AdminComponent,
  ],
  providers: [
    provideAnimations()
  ],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {

  // Whether to show or hide the secondary sidenav
  isSidenavVisible: boolean = true;

  private menuSubject: BehaviorSubject<number> = new BehaviorSubject(1);
  private destroy: Subject<boolean> = new Subject();

  public pageTitle: string = 'Users';
  public activeMenuItem: number = 3;
  public isLoading: boolean = false;
  public hasError: boolean = false;

  public url: string = '';
  @Select(UserState.getUsers) private allUsers$!: Observable<UserStateModel>;

  displayedColumns: string[] = [
    'name',
    'email',
    'role',
    'status',
    'actions',
  ];

  userDataSource: MatTableDataSource<Object> = new MatTableDataSource();

  userRoles: { name: string }[] = [
    { name: 'Administrator' },
    { name: 'Developer' },
  ];
  userRoleDataSource: MatTableDataSource<Object> | undefined;

  totalQuota: number | undefined;
  consumedQuota: number | undefined;

  @ViewChild("userPaginator") userPaginator!: MatPaginator;
  @ViewChild("rolePaginator") rolePaginator!: MatPaginator;

  @ViewChild('appMenu') public appMenuDrawer!: MatDrawer;

  @ViewChild('fileInput1') fileInput1!: ElementRef;
  @ViewChild('fileInput') fileInput!: ElementRef;

  status: any[] = [
    { value: false, text: 'Inactive' },
    { value: true, text: 'Active' },
  ];

  userDataFilterForm: FormGroup = new FormGroup({
    name: new FormControl(''),
    status: new FormControl(),
    role: new FormControl(),
  });

  invalidIconSize = false;

  image1!: File;
  image2!: File;
  iconPreview1: any;
  iconPreview2: any;

  dynamicBrandingPallet = {
    primaryColor: {
      dark: "#FFFFFF",
      light: "#FFFFFF",
    },
    secondaryColor: {
      dark: "#FFFFFF",
      light: "#FFFFFF",
    },
    buttonColor: {
      primaryColor: {
        dark: "#000000",
        light: "#000000",
      },
      secondaryColor: {
        dark: "#000000",
        light: "#000000",
      },
      destructiveColor: {
        dark: "#000000",
        light: "#000000",
      },
    },
    buttonFontColor: {
      primaryColor: {
        dark: "#000000",
        light: "#000000",
      },
      secondaryColor: {
        dark: "#000000",
        light: "#000000",
      },
      destructiveColor: {
        dark: "#000000",
        light: "#000000",
      },
    },
    textColor: {
      primaryColor: {
        dark: "#000000",
        light: "#000000",
      },
      secondaryColor: {
        dark: "#003400",
        light: "#000450",
      },
    }
  }
  userApplicationDataSource!: MatTableDataSource<Object>;
  userStatus: string = '';
  statusEdit = new UntypedFormControl();

  showHeader: boolean = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    private authService: AuthService,
    private store: Store,
  ) { }

  get name(): string {
    return this.authService.state.name;
  }

  get email(): string {
    return this.authService.state.email;
  }

  get isSuperuser(): boolean {
    return this.store.selectSnapshot(AuthState.isSuperuser);
  }

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.unsubscribe();
    this.menuSubject.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.userDataFilterForm.valueChanges.pipe(takeUntil(this.destroy)).subscribe((value) => {
      const filter = {
        status: value.status,
        name: value.name.trim().toLowerCase(),
        role: value.role,
      } as any;

      this.userDataSource.filter = filter;
    });
  }

  ngOnInit(): void {
    this.isLoading = true;

    this.store.dispatch(new LicenseAction.GetLicenses());
    this.store.dispatch(new AuthAction.Set(this.authService.state));

    this.breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
      ])
      .subscribe((state: BreakpointState) => {
        if (
          state.breakpoints[Breakpoints.XSmall] ||
          state.breakpoints[Breakpoints.Small]
        ) {
          this.isSidenavVisible = false;
        } else {
          this.isSidenavVisible = true;
        }
      });

    this.activatedRoute.queryParams.subscribe((params: any) => {
      if (params.section) {
        switch (params.section) {
          case 'license':
            this.menuSubject.next(3);
            this.pageTitle = 'Organizations';
            break;
          case 'requests':
            this.menuSubject.next(4);
            this.pageTitle = 'Requests';
            break;
          case 'admin':
            if (this.isSuperuser) {
              this.menuSubject.next(5);
            } else {
              this.menuSubject.next(3);
            }

            this.pageTitle = 'Organizations';
            break;
          case 'registrations':
            this.menuSubject.next(6);
            this.pageTitle = 'Registrations';
            break;
          default:
            this.menuSubject.next(3);
            this.pageTitle = 'Organizations';
            break;
        }

        this.isLoading = false;
      } else {
        this.pageTitle = "License";
        this.menuSubject.next(3);
      }
      if (params.userid) {
        // this.userService.getUser(params.userid).subscribe((user: any) => {
        //   this.updateUserDetailsForm.patchValue(user);
        //   this.statusEdit.patchValue(user['is_active']);
        //   this.userStatus = user['is_active'] ? 'Active' : 'Inactive';
        // });
      }
    });

    this.menuSubject.subscribe((activeMenu: number) => {
      this.activeMenuItem = activeMenu;
    });
  }

  // getBranding() {
  //   this.isLoading = true;
  //   this.authService.getDynamicBranding().subscribe((branding: any) => {

  //     this.dynamicBrandingPallet.primaryColor = branding["primary_color"]
  //     this.dynamicBrandingPallet.secondaryColor = branding["secondary_color"]
  //     this.dynamicBrandingPallet.buttonColor = branding["button_color"]
  //     this.dynamicBrandingPallet.buttonFontColor = branding["button_font_color"]

  //     this.dynamicBrandingPallet.textColor = branding["font_color"]

  //     let objectURL = 'data:image/png;base64,' + branding["icon_bytes"];
  //     this.iconPreview1 = this.sanitizer.bypassSecurityTrustUrl(objectURL);
  //     let objectURL2 = 'data:image/png;base64,' + branding["image_bytes"];
  //     this.iconPreview2 = this.sanitizer.bypassSecurityTrustUrl(objectURL2);
  //     this.patchFileInput(objectURL, 2)
  //     this.patchFileInput(objectURL2, 1)
  //     this.isLoading = false;

  //   }, (err: any) => {
  //     this.isLoading = false;
  //   })
  // }

  // patchFileInput(imageData: any, val: number) {
  //   const blob = this.dataURItoBlob(imageData);
  //   if (val == 1) {
  //     const file = new File([blob], 'image2.jpeg', { type: 'image/jpeg' });
  //     this.image2 = file;
  //   } else {
  //     const file = new File([blob], 'image1.png', { type: 'image/png' });
  //     this.image1 = file;
  //   }
  // }

  // dataURItoBlob(dataURI: string): Blob {
  //   const byteString = atob(dataURI.split(',')[1]);
  //   const ab = new ArrayBuffer(byteString.length);
  //   const ia = new Uint8Array(ab);
  //   for (let i = 0; i < byteString.length; i++) {
  //     ia[i] = byteString.charCodeAt(i);
  //   }
  //   return new Blob([ab], { type: 'image/png' });
  // }

  // getUserData() {
  //   this.store.dispatch(UserAction.GetAllUsers);
  //   this.allUsers$.subscribe((value: any) => {
  //     this.isLoading = false;

  //     this.userDataSource = new MatTableDataSource(value);
  //     this.userDataSource.paginator = this.userPaginator;
  //     this.userDataSource.filterPredicate = ((data, filter) => {
  //       const a = filter.status !== null ? data.is_active == filter.status : data
  //       const b = !filter.name || data.name.toLowerCase().includes(filter.name) || data.email.toLowerCase().includes(filter.name);
  //       const c = !filter.role || data.role === filter.role;
  //       return a && b && c;
  //     }) as (data: any, filter: any) => boolean;
  //   }, _ => {
  //     this.isLoading = false;
  //     this.userDataSource = new MatTableDataSource<Object>([]);
  //   });
  // }

  // showUserCreateMenu(userEditContent?: any): void {
  //   const dialogRef = this.dialog.open(CreateUserComponent, {
  //     ariaLabel: 'Create New User',
  //     closeOnNavigation: true,
  //     minWidth: 450,
  //     data: userEditContent
  //   });
  //   dialogRef.afterClosed().subscribe((result: any) => {
  //     this.getUserData();
  //   });
  // }

  // deleteUsers(data: any) {
  //   const dialogRef = this.dialog.open(UserDeleteDialog, {
  //     data: data,
  //     minWidth: 450,
  //   });
  //   dialogRef.afterClosed().subscribe((result: any) => {
  //     this.getUserData();
  //   });
  // }

  // updateBranding() {
  //   const formData = new FormData();
  //   formData.append("organization_id", sessionStorage.getItem("organizationId")!);
  //   formData.append("primary_color", JSON.stringify(this.dynamicBrandingPallet.primaryColor));
  //   formData.append("secondary_color", JSON.stringify(this.dynamicBrandingPallet.secondaryColor));
  //   formData.append("button_color", JSON.stringify(this.dynamicBrandingPallet.buttonColor));
  //   formData.append("button_font_color", JSON.stringify(this.dynamicBrandingPallet.buttonFontColor));

  //   formData.append("font_color", JSON.stringify(this.dynamicBrandingPallet.textColor));
  //   formData.append("iconBytes", this.image1, this.image1.name);
  //   formData.append("imageBytes", this.image2, this.image2.name);

  //   this.authService.updateDynamicBranding(formData).subscribe(
  //     (response: any) => {

  //       this.alertService.success('Branding updated successfully');
  //       setTimeout(() => {
  //         window.location.reload();
  //       }, 3000)
  //     },
  //     (error: any) => {
  //       this.alertService.error('Something went wrong. Please try again.');
  //     }
  //   );
  // }

  // updateUserDetailsForm!: UntypedFormGroup;
  // userUpdateForm() {
  //   this.updateUserDetailsForm = new UntypedFormGroup({
  //     name: new UntypedFormControl('', [
  //       Validators.required,
  //       Validators.maxLength(255),
  //       Validators.pattern("[a-zA-Z][a-zA-Z '-]+"),
  //       Validators.minLength(5),
  //     ]),
  //     email: new UntypedFormControl('', [
  //       Validators.required,
  //       Validators.email,
  //       Validators.maxLength(255),
  //     ]),
  //     is_active: new UntypedFormControl(true),
  //     applications: new UntypedFormControl(0),
  //     workspaces: new UntypedFormControl(0),
  //     phone_number: new UntypedFormControl('', [
  //       Validators.required,
  //       Validators.maxLength(12),
  //       Validators.minLength(5),
  //     ]),
  //     role: new UntypedFormControl(''),
  //   });
  // }

  // inviteUser(data: any) {
  //   const payload = {
  //     email: data,
  //   };
  //   this.userService.inviteUser(payload).subscribe(
  //     (result: any) => {
  //       this.alertService.success('Invite message sent.')
  //     },
  //     (error: any) => {
  //       this.alertService.error('Something went wrong. Please try again.')
  //     }
  //   );
  // }

  // onCountryChange(input: any) {
  //   console.log(input)
  // }

  // onFileSelected(event: any, image: any) {
  //   const file: File = event.target.files[0];
  //   image = file;
  //   let fileSize = 2000000;
  //   if (image.size < fileSize) {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(image)
  //     reader.onload = (readerEvent) => {
  //       image == "image1" ? this.iconPreview1 = reader.result : this.iconPreview2 = reader.result
  //     };

  //   } else {
  //     this.invalidIconSize = true;
  //   }
  // }

  // editStatus(data: any) {
  //   const statusData = {
  //     userEmail: this.email,
  //     statusData: data,
  //   };
  //   const dialogRef = this.dialog.open(EditStatusDialog, {
  //     data: statusData,
  //     maxWidth: 450,
  //   });
  //   dialogRef.afterClosed().subscribe((result) => {
  //     this.getUserData();
  //     this.userService.getAllUsers().subscribe((user: any) => {
  //       this.updateUserDetailsForm.patchValue(user);
  //       this.statusEdit.patchValue(user['is_active']);
  //       this.userStatus = user['is_active'] ? 'Active' : 'Inactive';
  //     });
  //   });
  // }

  toggleHeader(value: boolean) {
    this.showHeader = value;
  }

  resetOrgState() {
    this.store.dispatch(new LicenseAction.ResetActiveLicenseIndex());
  }
}

@Component({
  selector: 'user-delete-dialog',
  standalone: true,
  template: `<div class="p-6 ">
    <h6 class="text-base mb-2">Delete User</h6>
    <p class="message">
      Do you really want to delete this user, this action cannot be undone.
    </p>

    <div class="flex items-center justify-end mt-3 gap-4">
      <button
        class="outline-none cursor-pointer border border-solid border-currentColor leading-9 px-[15px] box-border relative overflow-visible rounded min-w-16 m-0 text-center align-baseline whitespace-nowrap inline-block bg-buttonSecondary font-normal text-sm text-buttonSecondaryFontColor"
        (click)="onNoClick()"
      >
        Cancel
      </button>
      <button
        class="outline-none relative bg-buttonDangerColor text-btnFontColor text-sm rounded py-2 leading-none min-h-9 px-4"
        (click)="deleteUser()"
      >
        Confirm
      </button>
    </div>
  </div> `,
  styleUrls: ['./dashboard.component.scss'],
})
export class UserDeleteDialog {
  constructor(
    public dialogRef: MatDialogRef<UserDeleteDialog>,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private alertService: AlertService,
  ) { }
  //close
  onNoClick(): void {
    this.dialogRef.close();
  }

  deleteUser() {
    const payload = {
      email: this.data.email,
    };

    this.userService.deleteUsers(payload).subscribe(
      (response) => {
        this.alertService.success(response)
        // this.snackBar.open(response, 'OK', {
        //   duration: 3000,
        //   verticalPosition: this.snackBarVerticalPosition,
        //   panelClass: 'notif-success-snackbar',
        // });
        this.dialogRef.close();
      },
      (error) => {
        this.alertService.error('Something went wrong. Please try again.')
        // this.snackBar.open('Something went wrong', 'OK', {
        //   duration: 3000,
        //   verticalPosition: this.snackBarVerticalPosition,
        //   panelClass: 'notif-error-snackbar',
        // });
        this.dialogRef.close();
      }
    );
  }
}

@Component({
  selector: 'edit-status-dialog',
  standalone: true,
  imports: [
    CommonModule,
  ],
  template: `<div class="p-4">
    <h6 *ngIf="data.statusData.checked">Activate User</h6>
    <h6 *ngIf="!data.statusData.checked">Deactivate User</h6>
    <p class="message-sm" *ngIf="data.statusData.checked">
      Are you sure you wish to activate this user?
    </p>
    <p class="message-sm" *ngIf="!data.statusData.checked">
      Are you sure you wish to deactivate this user? The user will no longer be
      able to access the PiES portal.
    </p>
    <div class=" d-flex align-items-center justify-content-end mt-3">
      <button
        mat-stroked-button
        class="me-2 outline-none"
        (click)="onNoClick()"
      >
        Cancel
      </button>
      <button
        mat-flat-button
        color="warn"
        class=" outline-none"
        (click)="editUserStatus()"
      >
        Confirm
      </button>
    </div>
  </div> `,
  styleUrls: ['./dashboard.component.scss']
})
export class EditStatusDialog {
  currentUserEmail: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EditStatusDialog>,
    private userService: UserService,
    private alertService: AlertService,
  ) {
    this.currentUserEmail = data.userEmail;
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  editUserStatus() {
    if (this.data.statusData.checked) {
      this.userService.activateUser(this.currentUserEmail).subscribe(
        (result: any) => {
          if (result) {
            this.alertService.success(result);
            this.dialogRef.close(true);
          }
        },
        (error) => {
          if (error) {
            this.alertService.error('Something went wrong. Please try again.')
            // this.snackBar.open('Something went wrong', 'ok', {
            //   duration: 3000,
            //   verticalPosition: this.snackBarVerticalPosition,
            //   panelClass: 'notif-error-snackbar',
            // });
          }
        }
      );
    }
    if (this.data.statusData.checked == false) {
      this.userService
        .deactivateUser(this.currentUserEmail)
        .subscribe(
          (result: any) => {
            if (result) {
              this.alertService.success(result)
              this.dialogRef.close(true);
            }
          },
          (error) => {
            if (error) {
              this.alertService.error('Something went wrong. Please try again.');
            }
          }
        );
    }
  }
}

