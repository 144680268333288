import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { append, patch, updateItem } from "@ngxs/store/operators";
import { catchError, tap } from "rxjs";
import { RequestActions } from "../action/request.action";
import { Status } from "../model/enum";
import { RequestStateModel } from "../model/request.model";
import { AlertService } from "../service/alert.service";
import { AuthService } from "../service/auth.service";
import { RequestService } from "../service/request.service";

@State<RequestStateModel>({
    name: 'request',
    defaults: {
        requests: [],
        status: Status.connecting,
    },
})
@Injectable()
export class RequestState {

    constructor(
        private requestService: RequestService,
        private alertService: AlertService,
        private authService: AuthService,
    ) { }

    @Selector()
    static getStatus(state: RequestStateModel) {
        return state.status;
    }

    @Selector()
    static getRequests(state: RequestStateModel) {
        return state.requests;
    }

    @Action(RequestActions.CreateRequest)
    createRequest(ctx: StateContext<RequestStateModel>, action: RequestActions.CreateRequest) {
        return this.requestService.createRequest({
            type: action.type,
            description: action.description,
            entity: this.authService.state.entity,
            user: this.authService.state.id,
        }).pipe(
            tap(response => {
                if (response) {
                    ctx.setState(
                        patch({
                            requests: append([response]),
                        })
                    )
                }

                this.alertService.success("Created");
            }),
            catchError(error => {

                this.alertService.error("Something went wrong, please try again");

                return error
            }),
        );
    }

    @Action(RequestActions.UpdateRequest)
    updateRequest(ctx: StateContext<RequestStateModel>, action: RequestActions.UpdateRequest) {
        return this.requestService.updateRequest({
            id: action.requestId,
            type: action.type,
            description: action.description,
        }).pipe(
            tap(response => {
                if (response) {
                    ctx.setState(
                        patch({
                            requests: updateItem(
                                r => r.id === action.requestId,
                                patch({
                                    type: action.type,
                                    description: action.description,
                                }),
                            ),
                        }),
                    )

                    this.alertService.success("Updated");
                }
            }),
            catchError(error => {

                this.alertService.error("Something went wrong, please try again");

                return error
            }),
        );
    }

    @Action(RequestActions.GetRequests)
    getAllRequests(ctx: StateContext<RequestStateModel>, action: RequestActions.GetRequests) {
        return this.requestService.getAllRequests().pipe(
            tap(response => {
                if (response) {
                    ctx.patchState({
                        requests: response,
                    })
                }
            }),
        );
    }
}