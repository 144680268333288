import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { catchError, first, map, tap } from "rxjs";
import { BillingAction } from "../action/billing.action";
import { LicenseAction } from "../action/license.action";
import { BillingStateModel } from "../model/billing/billing.model";
import { Plan } from "../model/billing/plan.model";
import { Status } from "../model/enum";
import { AlertService } from "../service/alert.service";
import { BillingService } from "../service/billing.service";


@State<BillingStateModel>({
    name: 'billing',
    defaults: {
        status: Status.idle,
        plans: [],
        transactions: [],
        paymentMethods: [],
    },
})
@Injectable()
export class BillingState {

    constructor(
        private billingService: BillingService,
        private alertService: AlertService,
    ) { }

    @Selector()
    static getStatus(state: BillingStateModel) {
        return state.status;
    }

    @Selector()
    static getPlans(state: BillingStateModel) {
        return state.plans;
    }

    @Selector()
    static getSubscription(state: BillingStateModel) {
        return state.subscription;
    }

    @Selector()
    static getTransactions(state: BillingStateModel) {
        return state.transactions;
    }

    @Selector()
    static getCosts(state: BillingStateModel) {
        return state.costs;
    }

    @Selector()
    static getPaymentMethods(state: BillingStateModel) {
        return state.paymentMethods ?? [];
    }

    @Action(BillingAction.CreateCheckoutSession)
    createCheckoutSession(ctx: StateContext<BillingStateModel>, action: BillingAction.CreateCheckoutSession) {
        return this.billingService.checkout(action.payload).pipe(
            tap(response => {
                window.location.href = response.url;
            }),
            catchError(error => {
                this.alertService.error("Something went wrong with your request, please check all the details and try again.");

                return error;
            }),
        );
    }

    @Action(BillingAction.GetPlans)
    fetchPlans(ctx: StateContext<BillingStateModel>, action: BillingAction.GetPlans) {
        ctx.patchState({
            status: Status.loading,
            plans: [],
        });

        return this.billingService.getPlans(action.cycle).pipe(
            map(response => {
                var parsedPlans: Plan[] = [];

                for (let item of response) {

                    parsedPlans.push({
                        id: item.id,
                        name: item.name,
                        currency: item.currency,
                        description: item.description.replaceAll('\\n', '\n'),
                        highlight: item.highlight,
                        included: item.included,
                        pricing: JSON.parse(atob(item.pricing)),
                    });
                }

                return parsedPlans;
            }),
            tap(response => {
                ctx.patchState({
                    status: Status.active,
                    plans: response,
                });
            }),
            catchError(error => {
                ctx.patchState({
                    status: Status.error,
                    plans: [],
                });
                throw error;
            })
        );
    }

    @Action(BillingAction.GetTransactions)
    fetchTransactions(ctx: StateContext<BillingStateModel>, action: BillingAction.GetTransactions) {
        ctx.patchState({
            status: Status.loading,
            transactions: [],
        });

        return this.billingService.getTransactions(action.clientId).pipe(
            tap(response => {
                ctx.patchState({
                    status: Status.active,
                    transactions: response,
                });
            }),
            catchError(error => {
                ctx.patchState({
                    status: Status.error,
                    transactions: [],
                });

                throw error;
            })
        );
    }

    @Action(BillingAction.GetSubscription)
    fetchSubscriptionDetails(ctx: StateContext<BillingStateModel>, action: BillingAction.GetSubscription) {
        ctx.patchState({
            status: Status.loading,
            subscription: undefined,
        });

        return this.billingService.getSubscription(action.clientId).pipe(
            tap(resposne => {
                ctx.patchState({
                    status: Status.active,
                    subscription: resposne,
                });
            }),
            catchError(error => {
                ctx.patchState({
                    status: Status.error,
                    subscription: undefined,
                });

                throw error;
            })
        );
    }

    @Action(BillingAction.GetCosts)
    fetchCosts(ctx: StateContext<BillingStateModel>, action: BillingAction.GetCosts) {
        ctx.patchState({
            status: Status.loading,
            costs: undefined,
        });

        return this.billingService.getCosts(action.clientId).pipe(
            tap(resposne => {
                ctx.patchState({
                    status: Status.active,
                    costs: resposne,
                });
            }),
            catchError(error => {
                ctx.patchState({
                    status: Status.error,
                    costs: undefined,
                });

                throw error;
            })
        );
    }

    @Action(BillingAction.GetPaymenMethods)
    fetchPaymentMethods(ctx: StateContext<BillingStateModel>, action: BillingAction.GetPaymenMethods) {
        ctx.patchState({
            status: Status.loading,
            paymentMethods: [],
        });

        return this.billingService.getPaymentMethods(action.clientId, action.userId).pipe(
            tap(resposne => {
                ctx.patchState({
                    status: Status.active,
                    paymentMethods: resposne,
                });
            }),
            catchError(error => {
                ctx.patchState({
                    status: Status.error,
                    paymentMethods: [],
                });

                throw error;
            })
        );
    }

    @Action(BillingAction.CancelSubscription)
    cancelSubscription(ctx: StateContext<BillingStateModel>, action: BillingAction.CancelSubscription) {
        ctx.patchState({
            status: Status.loading,
        });

        return this.billingService.cancelSubscription(action.clientId).pipe(
            tap(_ => {
                ctx.dispatch(new LicenseAction.GetLicenses()).pipe(first()).subscribe(
                    _ => {
                        ctx.dispatch(new LicenseAction.SetActiveLicense(action.clientId));
                    }
                );

                ctx.patchState({
                    status: Status.cancelled,
                });
            }),
            catchError(error => {
                ctx.patchState({
                    status: Status.error,
                });

                throw error;
            })
        );
    }

    @Action(BillingAction.RestoreSubscription)
    restoreSubscription(ctx: StateContext<BillingStateModel>, action: BillingAction.RestoreSubscription) {
        ctx.patchState({
            status: Status.loading,
        });

        return this.billingService.restoreSubscription(action.clientId).pipe(
            tap(_ => {
                ctx.dispatch(new LicenseAction.GetLicenses()).pipe(first()).subscribe(
                    _ => {
                        ctx.dispatch(new LicenseAction.SetActiveLicense(action.clientId));
                    }
                );

                ctx.patchState({
                    status: Status.active,
                });
            }),
            catchError(error => {
                ctx.patchState({
                    status: Status.error,
                });

                throw error;
            })
        );
    }

    @Action(BillingAction.UpdateSubscription)
    updateSubscription(ctx: StateContext<BillingStateModel>, action: BillingAction.UpdateSubscription) {
        ctx.patchState({
            status: Status.loading,
        });

        return this.billingService.updateSubscription(action.clientId, action.units).pipe(
            tap(_ => {
                ctx.patchState({
                    status: Status.success,
                });
            }),
            catchError(error => {
                ctx.patchState({
                    status: Status.error,
                });

                throw error;
            })
        );
    }

    @Action(BillingAction.SwitchBillingCycle)
    switchBillingCycle(ctx: StateContext<BillingStateModel>, action: BillingAction.SwitchBillingCycle) {
        ctx.patchState({
            status: Status.loading,
        });

        return this.billingService.switchBillingCycle(action.clientId).pipe(
            tap(_ => {
                this.alertService.success('Success! Your changes will apply from the next billing cycle');

                ctx.patchState({
                    status: Status.success,
                });
            }),
            catchError(error => {
                ctx.patchState({
                    status: Status.error,
                });

                throw error;
            })
        );
    }
}