<mat-drawer-container class="bg-white flex flex-1 h-full" [autosize]="true">
    <mat-drawer #sidenav [mode]="$any(sidebarMode)"
        class="flex sidenav p-0 border-r justify-center items-center max-w-[50px]" opened>
        <a class="outline-none" routerLink="/dashboard" [queryParams]="{'section': 'license'}"
            (click)="pageTitle = 'License Dasboard'" matTooltip="Home" [matTooltipPosition]="'right'"
            matTooltipClass="menu-tooltip items-center">
            <div class="flex p-2.5 logo justify-center items-center">
                <img class="logo-icon-mini" src="assets/logo-mini.png">
            </div>
        </a>

        <div class="flex flex-col flex-1 mb-2">
            <mat-nav-list *ngIf="activePage === 'dashboard'" class="navlist overflow-y-auto overflow-x-hidden">
                <a mat-list-item routerLinkActive="active" [routerLink]="['/dashboard']"
                    [queryParams]="{'section': 'license'}" (click)="pageTitle='Dashboard'; resetLicenseState()"
                    matTooltip="Dashboard" [matTooltipPosition]="'right'" matTooltipClass="menu-tooltip"
                    [ngClass]="{'nav-btn-dark': isDarkTheme, 'nav-btn': !isDarkTheme}"
                    class="flex justify-center items-center relative">
                    <mat-icon inline>home</mat-icon>
                </a>
                <a mat-list-item routerLinkActive="active" [routerLink]="['/environments']"
                    [queryParams]="{'section': 'overview'}" (click)="pageTitle='Environments';"
                    matTooltip="Environments" [matTooltipPosition]="'right'" matTooltipClass="menu-tooltip"
                    [ngClass]="{'nav-btn-dark': isDarkTheme, 'nav-btn': !isDarkTheme}"
                    class="flex justify-center items-center relative">
                    <mat-icon inline>dns</mat-icon>
                </a>
                <a mat-list-item routerLinkActive="active" [routerLink]="['/billing']"
                    [queryParams]="{'section': 'overview'}" (click)="pageTitle='Billing';" matTooltip="Billing"
                    [matTooltipPosition]="'right'" matTooltipClass="menu-tooltip"
                    [ngClass]="{'nav-btn-dark': isDarkTheme, 'nav-btn': !isDarkTheme}"
                    class="flex justify-center items-center relative">
                    <mat-icon inline>paid</mat-icon>
                </a>
            </mat-nav-list>
        </div>
        <!-- <div mat-list-item [matTooltip]="isDarkTheme? 'Switch to light theme': 'Switch to dark theme'"
            class="px-1 mb-2 flex justify-center items-center" mat-icon color="white"
            [matTooltipPosition]="'right'" matTooltipClass="menu-tooltip">
            <button (click)="toggleDarkTheme(!isDarkTheme)" class="outline-none btn cursor-pointer"
                [ngClass]="{'nav-btn-dark': isDarkTheme, 'nav-btn': !isDarkTheme}" mat-icon-button>
                <mat-icon class="outline-none">
                    {{isDarkTheme?'light_mode': 'dark_mode'}}
                </mat-icon>
            </button>
            <div class="active-item"></div>
        </div> -->
        <div mat-list-item [matMenuTriggerFor]="userMenu" class="flex justify-center items-center my-3">
            <button mat-icon-button class="nav-btn" [ngClass]="{'nav-btn-dark': isDarkTheme, 'nav-btn': !isDarkTheme}">
                <mat-icon>account_circle</mat-icon>
            </button>
        </div>

        <mat-menu #userMenu="matMenu" xPosition="before" class="user-menu" yPosition="below">
            <div class="user-menu-title">
                <div class="user-details me-4">
                    <h6 class="mb-0">{{user.name | titlecase}} </h6>
                    <p class="user-email mb-0">{{user.email}}</p>
                </div>
                <div class="user-profile">
                    <!-- <h6>{{ user.name.split(' ')[0].substr(0,1) | uppercase }}{{ user.name.split(' ')[1].substr(0,1) |
                        uppercase }} </h6> -->
                </div>
            </div>
            <!-- <button mat-menu-item class="outline-none" routerLink="/user/profile"
                [queryParams]="{userid: user.email}">
                <span>Profile</span>
            </button> -->
            <a (click)="logout()" class="text-decoration-none" mat-menu-item class="outline-none">
                <span>Sign out</span>
            </a>
        </mat-menu>

        <mat-menu #usageMenu="matMenu" xPosition="after" class="quota-menu" yPosition="above">
            <div *ngIf="consumedQuota === undefined && totalQuota === undefined" class="quota-menu-title items-center">
                <div class="quota-details me-4">
                    <div class="mb-1">
                        <mat-icon class="me-2" color="warn">error</mat-icon>
                        <h6 class="mb-0">Unable to fetch application object quota</h6>
                    </div>
                    <p class="quota-subtext mb-0">Please try again later</p>
                </div>
            </div>
            <div *ngIf="consumedQuota != undefined && totalQuota != undefined" class="quota-menu-title items-center">
                <div class="quota-indicator flex-col me-2">
                    <h6 class="mb-1">{{round((consumedQuota/totalQuota) * 100)}}%</h6>
                    <mat-progress-bar mode="determinate" color="primary"
                        [value]="(consumedQuota/totalQuota) * 100"></mat-progress-bar>
                </div>
                <div class="quota-details me-4">
                    <h6 class="mb-1">Application objects consumed</h6>
                    <p class="quota-subtext mb-0">{{organizationName | titlecase}}</p>
                </div>
            </div>
            <div *ngIf="(consumedQuota/totalQuota) * 100 >= 90" class="flex items-center my-2 mx-4">
                <mat-icon color="warn" class="me-2">error</mat-icon>
                <small class="mb-0">Contact your administrator to increase the application objects quota.</small>
            </div>
            <div *ngIf="(consumedQuota/totalQuota) * 100 <= 90" class="flex items-center my-2 mx-4">
                <mat-icon color="primary" class="me-2">error</mat-icon>
                <small class="mb-0 flex-1">Application objects are calculated once per applciation for all the active
                    deployments.</small>
            </div>
        </mat-menu>
    </mat-drawer>

    <mat-drawer-content class="overflow-hidden flex flex-1 flex-col bg-white z-[10000]">
        <main class="h-full flex flex-1 flex-col items-stretch justify-center">
            <div class="max-w-full h-full">
                <ng-container *ngTemplateOutlet="routerOutlet"></ng-container>
            </div>
        </main>
    </mat-drawer-content>
</mat-drawer-container>

<ng-template #routerOutlet>
    <router-outlet></router-outlet>
</ng-template>