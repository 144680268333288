import { CommonModule } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { ActivatedRoute } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { debounceTime, distinctUntilChanged, first, Observable, Subject, switchMap, take, takeUntil, timer } from 'rxjs';
import { BillingAction } from '../../../../action/billing.action';
import { CheckoutPayload } from '../../../../model/billing/billing.model';
import { PlanPricing } from '../../../../model/billing/plan.model';
import { Status } from '../../../../model/enum';
import { AlertService } from '../../../../service/alert.service';
import { BillingService } from '../../../../service/billing.service';
import { LicenseService } from '../../../../service/license.service';
import { AuthState } from '../../../../state/auth.state';

@Component({
  selector: 'app-dialog-payment',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatIconModule,
    MatCheckboxModule,
    FormsModule,
    MatProgressSpinnerModule,
  ],
  templateUrl: './payment.dialog.html',
  styleUrl: './payment.dialog.scss'
})
export class PaymentDialogComponent implements OnInit, OnDestroy {

  private userName: string = '';
  private userEmail: string = '';

  private destroy: Subject<boolean> = new Subject();

  seats: number = 1;

  domainStatus: Status = Status.idle;
  orgStatus: Status = Status.idle;

  submitted: boolean = false;

  @Select(AuthState.getName) name$!: Observable<string>;
  @Select(AuthState.getEmail) email$!: Observable<string>;

  detailsForm = new FormGroup({
    name: new FormControl<string>('', [Validators.required, Validators.maxLength(255)]),
    email: new FormControl<string>('', [Validators.required, Validators.email, Validators.maxLength(255)]),
    organization_id: new FormControl<string>('', Validators.maxLength(25)),
    organization: new FormControl<string>('', [Validators.required, Validators.maxLength(255)]),
    domain: new FormControl<string>('', [Validators.required, Validators.maxLength(255)]),
    seats: new FormControl<number>(1, [Validators.required, Validators.min(1), Validators.max(1000)]),
    consent: new FormControl<boolean>(false, [Validators.required]),
  });

  Status = Status;
  licenseStatus?: string;

  private domainText$ = new Subject<string>();

  constructor(
    public dialogRef: MatDialogRef<PaymentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      plan: string,
      seats: number,
      edition: string,
      currency: string,
      cycle: string,
      pricing: PlanPricing[],
      totalPrice: number,
      organization_id: string;
      organization?: string;
      domain?: string;
      clientId: string,
      mode: 'check' | 'pay';
      custom: boolean;
    },
    private billingService: BillingService,
    private licenseService: LicenseService,
    private alertService: AlertService,
    private store: Store,
    private route: ActivatedRoute,
  ) { }

  get name() {
    return this.userName;
  }

  get email() {
    return this.userEmail;
  }

  get totalPrice(): number {
    if (this.seats === 0) {
      switch (this.data.cycle) {
        case 'month':
          return this.data.pricing[this.data.pricing.length - 1].price_month * this.seats;
        case 'year':
          return this.data.pricing[this.data.pricing.length - 1].price_year * this.seats;
      }
    } else if (this.seats > 30) {
      const baseSeats = 30;
      const additionalSeats = this.seats - 30;

      let basePricing: number = 0;
      let additionalPricing: number = 0;

      switch (this.data.cycle) {
        case 'month':
          basePricing = this.data.pricing[this.data.pricing.length - 2].price_month * baseSeats;
          additionalPricing = this.data.pricing[this.data.pricing.length - 1].price_month * additionalSeats;
          break;
        case 'year':
          basePricing = this.data.pricing[this.data.pricing.length - 2].price_year * baseSeats;
          additionalPricing = this.data.pricing[this.data.pricing.length - 1].price_year * additionalSeats;
          break;
      }

      return basePricing + additionalPricing;
    }

    for (let i = 0; i < this.data.pricing.length; i++) {

      const p = this.data.pricing[i];

      if (p.max_seats === null || this.seats === p.max_seats) {
        const price = this.data.cycle === 'month' ? p.price_month : p.price_year;
        return price * p.max_seats;
      }
    }

    return 0;
  }

  ngOnInit(): void {

    if (this.data.mode === 'check') {
      const interval = 3000;
      const clostTimer$ = new Subject<boolean>();

      let complete = false;

      timer(0, interval).pipe(
        switchMap(() => this.licenseService.getLicenseStatus(this.data.clientId)),
        takeUntil(clostTimer$),
        take(5),
      ).subscribe({
        next: response => {
          this.licenseStatus = response.status;

          if (this.licenseStatus !== 'pending') {
            complete = true;
            clostTimer$.next(true);
            clostTimer$.unsubscribe();
          }
        },
        error: _ => {
          this.licenseStatus = 'error';
        },
        complete: () => {
          if (!complete) {
            this.licenseStatus = 'error';
          }
        }
      });

      return;
    }

    this.name$.pipe(takeUntil(this.destroy)).subscribe(response => {
      this.userName = response;
    });

    this.email$.pipe(takeUntil(this.destroy)).subscribe(response => {
      this.userEmail = response;
    });

    this.domainText$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
    ).subscribe(searchText => {
      this.checkDomain(searchText);
    })

    if (!this.data.seats) {
      this.data.seats = 1;
    }

    this.seats = this.data.seats;

    if (this.data.organization) {
      const orgControl = this.detailsForm.get('organization');
      orgControl?.setValue(this.data.organization);

      const orgIdControl = this.detailsForm.get('organization_id');
      orgIdControl?.setValue(this.data.organization_id);
    }

    if (this.data.domain) {
      const domainControl = this.detailsForm.get('domain');

      domainControl?.setValue(this.data.domain);
      this.checkDomain(this.data.domain);
    }
  }

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }

  proceed() {

    this.submitted = true;

    this.detailsForm.get('name')?.setValue(
      this.store.selectSnapshot(AuthState.getName),
    );
    this.detailsForm.get('email')?.setValue(
      this.store.selectSnapshot(AuthState.getEmail),
    );
    this.detailsForm.get('seats')?.setValue(this.seats);

    if (this.detailsForm.valid) {
      const id = this.store.selectSnapshot(AuthState.getId);

      const formValue = this.detailsForm.value;
      let units = formValue.seats ?? 0;

      const payload: CheckoutPayload = {
        id: id,
        email: formValue.email ?? '',
        cycle: this.data.cycle,
        organization_id: this.detailsForm.get('organization_id')?.value ?? '',
        organization: this.detailsForm.get('organization')?.value ?? '',
        domain: this.detailsForm.get('domain')?.value ?? '',
        type: 'product',
        metadata: {},
        mode: 'subscription',
        client_id: this.data.clientId,
        units: units,
      };

      this.store.dispatch(new BillingAction.CreateCheckoutSession(payload));
    }
  }

  cancel() {
    this.dialogRef.close({
      checkout: false,
    });
  }

  success() {
    this.dialogRef.close({
      checkout: true,
    });
  }

  searchhDomain(event: Event) {
    const value = (event.target as HTMLInputElement).value.trim().toLowerCase();

    if (value) {
      this.domainText$.next(value);
    } else {
      this.domainStatus = Status.idle;
    }
  }

  checkDomain(value: string) {
    this.domainStatus = Status.loading;

    this.billingService.checkDomain(value).pipe(first()).subscribe(response => {
      this.domainStatus = response.count > 0 ? Status.inactive : Status.active;
    }, _ => {
      this.domainStatus = Status.error;
      this.alertService.error("Unable to check if organization domain exists, please try again.");
    });
  }

  addExtraSeats(event: Event) {
    let value = (<HTMLInputElement>event.target).value.trim();
    if (value == "") {
      value = "0";
    }

    const parsed = parseInt(value);

    this.seats = 30 + parsed;
  }
}
