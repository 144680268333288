import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ThemeService } from '../../service/theme.service';
import { filter } from 'rxjs';
import { CommonModule, TitleCasePipe } from '@angular/common';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [TitleCasePipe, CommonModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit, OnChanges {
  @Input()
  title: string = "";

  @Input()
  hasBreadcrumb: boolean = true;

  @Output() showConsole: EventEmitter<boolean> = new EventEmitter();

  breadcrumb: {
    label: string;
    url: string;
    queryParams: any;
  }[] = [];
  iconPreview: any;
  isEditors: boolean = false;

  public userEmail: string = "";
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private themeService: ThemeService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    // this.breadcrumb = this.generateBreadcrumb(this.route.root);
  }

  ngOnInit(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        // this.breadcrumb = this.generateBreadcrumb(this.route.root);
      });
    this.route.queryParams.subscribe((params) => {
      this.userEmail = params['email'];
    });
  }


  // generateBreadcrumb(
  //   route: ActivatedRoute,
  //   url: string = '',
  //   breadcrumbs: any[] = []
  // ) {
  //   const children: ActivatedRoute[] = route.children;
  //   let queryParams: {} = {};

  //   if (children.length === 0) {
  //     return breadcrumbs;
  //   }

  //   for (const child of children) {
  //     const routeUrl: string = child.snapshot.url
  //       .map((segment) => segment.path)
  //       .join('/');
  //     url += `/${routeUrl}`;

  //     const label = child.snapshot.data['breadcrumb'];
  //     let apiLabel;

  //     if (label) {


  //       breadcrumbs.push({
  //         label: label,
  //         url: url,
  //         queryParams: child.snapshot.queryParams
  //       });


  //     }

  //     return this.generateBreadcrumb(child, url, breadcrumbs);
  //   }
  // }
  setIconPreview(icon: any, setIcon: any) {
    if (icon === "" || icon === null || icon === undefined) {
      return this.themeService.appIcon
    }
    else if (icon !== "" && !setIcon) {
      let objectURL = 'data:image/jpeg;base64,' + icon;
      return objectURL;
    } else if (icon !== "" && setIcon) {
      let objectURL = 'data:image/jpeg;base64,' + icon;
      this.iconPreview = objectURL;
    }
  }



  allowBuild() {
    if (
      // this.title == 'Data Model Editor' ||
      this.title == 'Screen Editor' ||
      this.title == 'Event Editor' ||
      this.title == 'Function Editor' ||
      this.title == 'Modules' ||
      this.title == 'Resources' ||
      this.title == 'Download Executable' ||
      this.title == 'Deployment' ||
      this.title == 'Publish To Marketplace' ||
      this.title == 'Generate Code' ||
      this.title == 'Containerize' ||
      this.title == 'Version Control' ||
      this.title == 'Configuration' ||
      this.title == 'Application Details' ||
      this.title == 'Access Groups' ||
      this.title == 'Configuration' ||
      this.title == 'Generate Code' ||
      this.title == 'Backup and Restore'
    ) {
      return true;
    } else {
      return false;
    }
  }

  // build() {

  //   this.buildService.isBuildForDeployment = false;

  //   if (this.allowBuild()) {
  //     this.showConsole.emit(true);
  //   } else {
  //     this.showConsole.emit(false);
  //   }
  // }
}
