
export namespace RequestActions {
    export class CreateRequest {
        static readonly type = "[Request] Create Request";
        constructor(public type: string, public description: string) { }
    }

    export class UpdateRequest {
        static readonly type = "[Request] Update Request";
        constructor(public requestId: string, public type: string, public description: string) { }
    }

    export class GetRequests {
        static readonly type = "[Request] Get All Requests";
        constructor() { }
    }
}