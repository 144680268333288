<div class="p-5">
    <h6 class="text-base mb-3">{{this.data.mode | titlecase}} Request</h6>
    <p class="text-sm mb-3">We need a few details from you</p>
    <form [formGroup]="requestFormGroup" (ngSubmit)="submit()">
        <div class="mb-2">
            <label for="type" class="block mb-2 text-xs font-medium text-fontPrimary">
                Type
                <span class="text-btnDestructive">*</span>
            </label>
            <select formControlName="type" autocomplete="off" id="type" class="input outline-none">
                @for (type of types; track type.title) {
                <option [value]="type.value">{{type.title}}</option>
                }
            </select>
        </div>
        <div class="mb-2">
            <label for="description" class="block mb-2 text-xs font-medium text-fontPrimary">
                Description
                <span class="text-btnDestructive">*</span>
            </label>
            <textarea id="description" formControlName="description" class="input" type="text" maxlength="500" rows="10"></textarea>
        </div>
        <button type="submit" class="btn btn-primary w-full mb-2">Submit</button>
        <button type="button" class="btn btn-secondary w-full" (click)="cancel()">Cancel</button>
    </form>
</div>