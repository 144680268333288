@if (status === Status.loading) {
    <div class="p-5 flex items-center">
        <mat-spinner [diameter]="15" class="mr-2"></mat-spinner>
        <p class="text-sm">Please wait...</p>
    </div>
} @else if (status === Status.success) {
    <div class="p-5">
        <div class="flex items-center mb-3">
            <mat-icon class="mr-2 text-bgSuccess">check_circle</mat-icon>
            <h6 class="text-base">Success</h6>
        </div>
        <p class="text-sm mb-5">You're all set! Your changes were successful, and will apply from the next billing cycle.</p>
        <div class="w-full flex justify-end">
            <button class="btn btn-sm border text-btnPrimary" (click)="cancel()">Close</button>
        </div>
    </div>
} @else if (status === Status.error) {
    <div class="p-5">
        <div class="flex items-center mb-3">
            <mat-icon class="mr-2 text-buttonDangerColor">error</mat-icon>
            <h6 class="text-base">Error</h6>
        </div>
        <p class="text-sm mb-5">Something went wrong. Please try again in some time.</p>
        <div class="w-full flex justify-end">
            <button class="btn btn-sm border px-5 text-btnPrimary" (click)="cancel()">Close</button>
        </div>
    </div>
} @else {
    <div class="p-5">
        <h6 class="text-base mb-1">Change Billing Cycle</h6>
        <p class="text-sm mb-3">You are about the make changes to your billing cycle. Are you sure you wish to proceed?</p>
        <div class="ring-1 ring-borderColor rounded grid grid-cols-2 divide-x mb-5">
            <div class="p-3">
                <label class="mb-2">From</label>
                <h6 class="text-lg">{{this.data.cycle === 'month' ? 'Monthly' : 'Annual'}}</h6>
            </div>
            <div class="p-3">
                <label class="mb-2">To</label>
                <h6 class="text-lg">{{this.data.cycle === 'month' ? 'Annual' : 'Monthly'}}</h6>
            </div>
        </div>
        <div class="ring-1 ring-borderColor rounded p-3 flex items-center mb-5">
            <mat-icon class="text-bgWarning me-2 min-w-8">warning</mat-icon>
            <p class="text-sm mb-0">Any changes that you make will only take effect from the next billing date.</p>
        </div>
        <div class="flex flex-col w-full">
            <p class="text-sm mb-5">By continuing, you understand that your subscription cost will be adjusted from the next
                billing date, and allow us to charge you any amount that may be due from you after the changes are applied.
            </p>
            <button type="button" class="btn btn-primary px-3 mb-3" (click)="submit()">Submit</button>
            <button type="button" class="btn btn-secondary px-3 mr-2" (click)="cancel()">Cancel</button>
        </div>
    </div>
}