import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { BillingAction } from '../../../../action/billing.action';
import { Subscription, Transaction } from '../../../../model/billing/billing.model';
import { License } from '../../../../model/license.model';
import { BillingState } from '../../../../state/billing.state';
import { LicenseState } from '../../../../state/license.state';
import { RequestDialogComponent } from '../../../utils/dialog/dialog.component';

@Component({
  selector: 'app-overview',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatPaginatorModule,
    MatTableModule,
  ],
  templateUrl: './overview.component.html',
  styleUrl: './overview.component.scss'
})
export class OverviewComponent implements OnInit, AfterViewInit, OnDestroy {

  private clientId: string = '';
  private destroy$: Subject<boolean> = new Subject();

  @Select(BillingState.getTransactions) private transactions$!: Observable<Transaction[]>;
  @Select(BillingState.getSubscription) private subscription$!: Observable<Subscription | undefined>;
  @Select(BillingState.getCosts) private costs$!: Observable<{ 'subscription': number, 'export': number, 'environment': number } | undefined>;

  @Select(LicenseState.getActiveLicense) private license$!: Observable<License | undefined>;

  subscription?: Subscription;
  costs?: {
    'subscription': number;
    'export': number;
    'environment': number;
  }

  displayedColumns: string[] = ['id', 'type', 'amount', 'status', 'timestamp', 'actions'];
  transactionsDataSource!: MatTableDataSource<Transaction>;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(
    private dialog: MatDialog,
    private store: Store,
  ) { }

  ngOnInit(): void {

    this.license$.pipe(takeUntil(this.destroy$)).subscribe(
      response => {
        if (response) {
          this.clientId = response.client_id;
          this.getBillingOverview();
        }
      }
    );

    this.subscription$.pipe(takeUntil(this.destroy$)).subscribe(
      response => {
        this.subscription = response;
      },
    );

    this.costs$.pipe(takeUntil(this.destroy$)).subscribe(
      response => {
        this.costs = response;
      },
    );
  }

  ngAfterViewInit(): void {
    this.transactions$.pipe(takeUntil(this.destroy$)).subscribe(
      response => {
        if (response) {
          this.transactionsDataSource = new MatTableDataSource(response);
          this.transactionsDataSource.paginator = this.paginator;
        }
      },
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  getBillingOverview() {
    this.store.dispatch(new BillingAction.GetTransactions(this.clientId));
    this.store.dispatch(new BillingAction.GetSubscription(this.clientId));
    this.store.dispatch(new BillingAction.GetCosts(this.clientId));
  }

  raiseRequest(id: string) {
    this.dialog.open(RequestDialogComponent, {
      minWidth: 450,
      maxWidth: 650,
      data: {
        mode: 'create',
        type: 'support',
        id: '',
        description: `Please describe your problem in detail -\n\n\n\n\n\nThese details help us resolve your request faster, please do not delete the below section. \n\nTransaction- '${id}', \nLicense- '${this.clientId}'`,
      },
    });
  }
}
