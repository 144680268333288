import { CommonModule } from '@angular/common';
import { ANIMATION_MODULE_TYPE, Component, OnDestroy, OnInit, Directive, ElementRef, HostListener, Output, EventEmitter, Renderer2, } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Country, getData } from 'country-list';
import { Observable, Subject, takeUntil } from 'rxjs';
import { AuthAction } from '../../../action/auth.action';
import { Status } from '../../../model/enum';
import { AuthState } from '../../../state/auth.state';
import { MaterialModule } from '../../utils/material.module';
import intlTelInput from 'intl-tel-input'


@Directive({
  selector: '[appIntlTelInput]',
  standalone: true,
})
export class IntlTelInputDirective implements OnInit, OnDestroy {
  constructor(private el: ElementRef,private renderer:Renderer2) { }


  telInput: intlTelInput.Plugin | undefined;

  @Output('change') change: EventEmitter<any> = new EventEmitter();
  @Output('inputRef') inputRef: EventEmitter<any> = new EventEmitter();
  ngOnInit() {
    this.telInput = intlTelInput(this.el.nativeElement, {
      initialCountry: 'us',
      separateDialCode: true,
      utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/16.0.1/js/utils.js',
    });

    this.inputRef.emit(this.telInput);
  }

  ngOnDestroy(): void {
  }

  // @HostListener('blur') onBlur() {
  //   const input = this.el.nativeElement;
  //   if (input.value.trim()) {
  //     const validNumber = this.telInput?.isValidNumber();
  //     if (validNumber===true) {
  //       this.onError.emit(true);
  //     }else{
  //       this.onError.emit(false);
  //     }
  //   }
  // }

  @HostListener('blur') onChange() {
    this.change.emit(true);
  }
}

@Component({
  selector: 'app-register',
  standalone: true,
  imports: [
    RouterLink,
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    IntlTelInputDirective,
  ],
  providers: [
    { provide: ANIMATION_MODULE_TYPE, useValue: 'BrowserAnimations' },
  ],
  templateUrl: './register.component.html',
  styleUrl: './register.component.scss'
})
export class RegisterComponent implements OnInit, OnDestroy {

  Status = Status;
  status?: Status;
  countries: Country[] = [];

  registrationFormGroup: FormGroup = new FormGroup({
    name: new FormControl<string>('', [Validators.required, Validators.maxLength(255), Validators.minLength(3)]),
    email: new FormControl<string>('', [Validators.required, Validators.email, Validators.maxLength(255)]),
    phone: new FormControl<string>('', [Validators.maxLength(15), Validators.minLength(7), Validators.pattern(/^[-+]?\d+$/)]),
    country: new FormControl<string>('', [Validators.required, Validators.maxLength(255)]),
  });

  @Select(AuthState.getStatus) private status$!: Observable<Status>;
  private destroy: Subject<boolean> = new Subject();
  telInputRef: intlTelInput.Plugin | undefined;

  constructor(
    private store: Store,
  ) { }

  ngOnInit(): void {
    this.countries = getData();

    this.status$.pipe(takeUntil(this.destroy)).subscribe(status => {
      this.status = status;
    });

    this.store.dispatch(new AuthAction.SetStatus(Status.idle));
  }

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }

  register(): void {
    const payload = this.registrationFormGroup.value;
    if(this.telInputRef?.getNumber()!==""){
      payload['phone'] = this.telInputRef?.getNumber();
    }

    if (this.registrationFormGroup.valid) {
      this.store.dispatch(new AuthAction.Register(payload));
    }
  }

  onTelInputErr(hasError: boolean) {
    const errs = this.registrationFormGroup.get('phone')?.errors;
    if (this.telInputRef?.getValidationError() !== 0 && this.telInputRef?.getNumber()!=="") {
      if (((errs && !errs['numberInvalid']) || !errs)) {
        this.registrationFormGroup.get('phone')?.setErrors({ 'numberInvalid': true });
      }
    } else if(this.telInputRef?.getValidationError() === 0 && this.telInputRef?.getNumber()!=="") {
      if (errs && errs['numberInvalid']) {
        delete (errs['numberInvalid']);
        this.registrationFormGroup.get('phone')?.setErrors(errs);
      }
    }
  }
}



