import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { url } from '../../environments/url';
import { Request } from '../model/request.model';

@Injectable({
  providedIn: 'root'
})
export class RequestService {

  constructor(
    private http: HttpClient,
  ) { }

  createRequest(payload: {
    user: string;
    entity: string;
    type: string;
    description: string;
  }) {
    return this.http.post<Request>(
      environment.backend + url.request.create,
      payload,
    );
  }

  updateRequest(payload: {
    id: string,
    type: string;
    description: string;
  }) {
    return this.http.put<Request>(
      environment.backend + url.request.update,
      payload,
    );
  }

  getAllRequests() {
    return this.http.get<Request[]>(
      environment.backend + url.request.list,
    );
  }
}
