import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { RequestActions } from '../../../action/request.action';
import { RequestState } from '../../../state/request.state';
import { RequestDialogComponent } from '../../utils/dialog/dialog.component';
import { MaterialModule } from '../../utils/material.module';

@Component({
  selector: 'app-request',
  standalone: true,
  imports: [
    CommonModule,
    MaterialModule,
  ],
  templateUrl: './request.component.html',
  styleUrl: './request.component.scss'
})
export class RequestComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['type', 'description', 'status', 'created_at', 'actions'];
  requestsDataSource: Request[] = [];

  @Select(RequestState.getRequests) private requests$!: Observable<Request[]>;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  private destroy: Subject<boolean> = new Subject();

  constructor(
    private store: Store,
    private dialog: MatDialog,
  ) { }

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }

  ngOnInit(): void {

    this.store.dispatch(new RequestActions.GetRequests());

    this.requests$.pipe(takeUntil(this.destroy)).subscribe(response => {
      if (response) {
        this.requestsDataSource = response;
      }
    });
  }

  request() {
    this.dialog.open(RequestDialogComponent, {
      minWidth: 450,
      maxWidth: 650,
      data: {
        mode: 'create',
        type: 'support',
        id: '',
      },
    });
  }

  editRequest(id: string, type: string, description: string) {
    this.dialog.open(RequestDialogComponent, {
      minWidth: 450,
      maxWidth: 650,
      data: {
        mode: 'edit',
        type: type,
        description: description,
        id: id,
      },
    });
  }
}
