<p class="message-sm mb-5">View and manage all the raised support requests</p>
<button (click)="request()" class="btn btn-primary px-3 mb-5">New Request</button>

<section class="flex flex-col">
    <table mat-table [dataSource]="requestsDataSource"
        class="table-auto w-full border-borderColor border">

        <!-- Request Type -->
        <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef>Type</th>
            <td mat-cell *matCellDef="let row">
                <span class="badge">{{row.type}}</span>
            </td>
        </ng-container>

        <!-- Request Description -->
        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef>Description</th>
            <td mat-cell *matCellDef="let row">
                <p class="message-sm">{{row.description}}</p>
            </td>
        </ng-container>

        <!-- Request Status -->
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let row">
                <div class="flex items-center">
                    @if (row.status === 'pending') {
                    <mat-icon class="text-yellow-500 mr-2">info</mat-icon>
                    } @else if (row.status === 'complete') {
                    <mat-icon class="text-bgSuccess mr-2">check_circle</mat-icon>
                    } @else if (row.status === 'closed') {
                    <mat-icon class="text-buttonDangerColor mr-2">error</mat-icon>
                    }
                    <span class="message-sm">{{row.status | titlecase}}</span>
                </div>
            </td>
        </ng-container>

        <!-- Request Timestamp -->
        <ng-container matColumnDef="created_at">
            <th mat-header-cell *matHeaderCellDef>Created At</th>
            <td mat-cell *matCellDef="let row">
                <span class="message-sm">{{row.created_at | date:'medium'}}</span>
            </td>
        </ng-container>

        <!-- Actions -->
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let row">
                <mat-icon class="cursor-pointer text-primary" (click)="editRequest(row.id, row.type, row.description)" matTooltip="Edit Request">edit</mat-icon>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" [attr.colspan]="displayedColumns.length">
                No active requests found
            </td>
        </tr>
    </table>

    <mat-paginator class="p-4 text-base" [length]="requestsDataSource.length" [pageSize]="15"
        aria-label="Select page of requests"></mat-paginator>
</section>